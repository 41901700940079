import {useState, useMemo, useEffect, FormEventHandler} from 'react';
import {
    Option,
    OptionGroup,
    FieldProps,
    Tooltip,
    MenuGroupHeader,
    Dropdown,
    useId,
    Field,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {useGetCapacities} from '@/api/capacities';
import {Capacity} from '@/api/capacities';
import {useGetWorkspaces} from '@/api/workspaces';
import useClasses from '@/components/CreateCapacity.styles';
import {Workspace} from '@/api/workspaces';
import {WorkspaceCapacity} from '@/api/workspaces/workspaces.types';
import DisconnectCapacityDialog from '../ManageWorkspaces/DisconnectCapacityDialog';

interface WorkspaceSelectProps {
    value?: string;
    required?: boolean;
    onOptionSelect: (selectedWorkspace: Workspace) => void;
    fieldProps?: FieldProps;
    disabled: boolean;
}

export default function WorkspaceSelect(props: WorkspaceSelectProps) {
    const {value: propValue, fieldProps, required, onOptionSelect, disabled} = props;
    const classes = useClasses();
    // translation related functions by namespace
    const {t} = useTranslation('admin');
    // data fetching
    const {data, error, isLoading} = useGetCapacities();
    const capacityItems = data?.value || [];
    // when showOnlyAssignableCapacities is true, we only show capacities that are assignable to the workspace
    // we enable fetching of workspaces to get the assignable capacities
    const [workspace, setWorkspaceForCapacity] = useState<string>(propValue || '');
    useEffect(() => {
        setWorkspaceForCapacity(propValue || '');
    }, [propValue]);
    const {data: workspacesData, error: workspaceError} = useGetWorkspaces();
    const [tempSelectedWorkspace, setTempSelectedWorkspace] = useState<string | null>(null);
    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);
    const [attachedCapacity, setAttachedCapacity] = useState<WorkspaceCapacity>();

    // determine if capacity is being used
    // declare an array of available capacities

    const [availableWorkspaces, unavailableWorkspaces] = useMemo(() => {
        const available: Workspace[] = [];
        const unavailable: Workspace[] = [];
        if (workspacesData?.value) {
            for (const workspace of workspacesData.value) {
                const isUnavailable = capacityItems?.some(
                    (capacity: Capacity) => capacity.name === workspace?.capacity?.referenceName,
                );
                isUnavailable ? unavailable.push(workspace) : available.push(workspace);
            }
        }
        return [available, unavailable];
    }, [workspacesData, capacityItems]);

    const handleAvailableWorkspaceClick = (workspaceSelected: string) => {
        // update workspace
        const selectedWorkspace = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === workspaceSelected,
        );
        if (selectedWorkspace) {
            setWorkspaceForCapacity(selectedWorkspace.name);
            onOptionSelect(selectedWorkspace);
        }
        document.getElementById(workspaceSelectId)?.focus();
    };
    const handleInUseWorkspaceClick = (workspaceSelected: string) => {
        setTempSelectedWorkspace(workspaceSelected);
        // find the capacity attached to the workspace
        const tempAttachedCapacity = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === workspaceSelected,
        )?.capacity;
        if (tempAttachedCapacity) {
            setAttachedCapacity(tempAttachedCapacity);
            setDisconnectCapacityDialogOpen(true);
        }
    };
    // multi-workspaces
    const workspaceSelectId = useId('workspaceSelect');
    const handleDisconnectConfirm = () => {
        // update workspace
        if (tempSelectedWorkspace) {
            handleAvailableWorkspaceClick(tempSelectedWorkspace);
        }
        setDisconnectCapacityDialogOpen(false);
    };
    const renderDropdown = () => (
        <Field label={t('createCapacity.WorkspaceLabel')}>
            <Dropdown
                placeholder={t('createCapacity.SelectWorkspacePlaceholder')}
                className={classes.dropdownSelector}
                aria-required="true"
                aria-label={t('createCapacity.SelectWorkspacePlaceholder')}
                data-test-id="select-workspace-for-new-capacity"
                selectedOptions={[workspace]}
                value={workspace}
                id={workspaceSelectId}
            >
                <OptionGroup>
                    <MenuGroupHeader>{t('Workspaces.Form.Available')}</MenuGroupHeader>
                    {availableWorkspaces.map((workspace: Workspace, index: number) => {
                        return (
                            <Option
                                key={index}
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent default selection
                                    e.stopPropagation(); // Stop dropdown closure
                                    handleAvailableWorkspaceClick(workspace.name);
                                }}
                            >
                                {workspace.name}
                            </Option>
                        );
                    })}
                    <MenuGroupHeader>{t('Workspaces.Form.InUse')}</MenuGroupHeader>
                    {unavailableWorkspaces.map((workspace: Workspace, index: number) => {
                        return (
                            <Option
                                key={index}
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent default selection
                                    e.stopPropagation(); // Stop dropdown closure
                                    handleInUseWorkspaceClick(workspace.name);
                                }}
                            >
                                {workspace.name}
                            </Option>
                        );
                    })}
                </OptionGroup>
            </Dropdown>
            <DisconnectCapacityDialog
                title={t('ManageWorkspaces.DisconnectWorkspaceDialog.Title', {
                    0: tempSelectedWorkspace,
                })}
                descriptionShort={t('ManageWorkspaces.DisconnectWorkspaceDialog.DescriptionShort')}
                description={t('ManageWorkspaces.DisconnectWorkspaceDialog.Description', {
                    0: tempSelectedWorkspace,
                    1: t('ManageWorkspaces.DisconnectWorkspaceDialog.NewCapacityUnnamed'),
                    2: attachedCapacity?.referenceName,
                })}
                switchButtonLabel={t('ManageWorkspaces.DisconnectWorkspaceDialog.SwitchButton')}
                isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                newCapacityName={t('ManageWorkspaces.DisconnectWorkspaceDialog.NewCapacityUnnamed')}
                workspaceName={tempSelectedWorkspace || ''}
                onClose={() => {
                    setDisconnectCapacityDialogOpen(false);
                }}
                onConfirm={handleDisconnectConfirm}
            />
        </Field>
    );
    return (
        <>
            {props.disabled ? (
                <Tooltip
                    content={t('ManageWorkspaces.Errors.CapacityOwnerPermission')}
                    relationship="label"
                    withArrow={true}
                >
                    {renderDropdown()}
                </Tooltip>
            ) : (
                renderDropdown()
            )}
        </>
    );
}
