// Creating these skill types with only the fields needed when creating an Agent

import {SkillDescriptor} from '@/api/skills';
import React from 'react';

export interface SkillsetCreatorProps {
    onCloseCreator?: () => void;
}

export interface SkillsetEditorProps {
    onValueChange?: (value: any) => void;
    currentSkillsetYaml?: string;
    currentSkillsetName?: string;
    onChangeCurrentSkillset?: (value: string) => void;
}

export interface SkillsetDesignerProps {
    onValueChange?: (value: any) => void;
    currentSkillsetYaml?: string;
    currentSkillsetName?: string;
    onChangeCurrentSkillset?: (value: string) => void;
}

export interface AgentSettingProps {
    skillIndex: number;
    skill: Skill;
    prevFields: Skillset;
    handleFormChange: (
        field:
            | keyof SkillInput
            | keyof Skill
            | keyof Skillset
            | keyof Skill['settings']
            | keyof AgentSkillSettings
            | keyof KQLSkillSettings,
        value: any,
        skillIndex?: number,
        inputIndex?: number,
        childSkillIndex?: number,
    ) => void;
    setFormFields: (prevFields: React.SetStateAction<Skillset>) => void;
    allSkills: SkillDescriptor[];
}

export interface KqlSettingProps {
    skillIndex: number;
    skill: Skill;
    handleFormChange: (
        field:
            | keyof SkillInput
            | keyof Skill
            | keyof Skillset
            | keyof Skill['settings']
            | keyof AgentSkillSettings
            | keyof KQLSkillSettings,
        value: any,
        skillIndex?: number,
        inputIndex?: number,
        childSkillIndex?: number,
    ) => void;
}

export type SkillInput = {
    required: boolean;
    name: string;
    description: string;
};

export type SkillGroupSettings = {
    HistoryPassDownMode: string;
    IncludeSessionHistory: boolean;
};

export type SkillGroup = {
    format: SkillGroupFormat;
    settings: SkillGroupSettings;
    skills: Skill[];
};

export enum SkillGroupFormat {
    AGENT = 'AGENT',
    KQL = 'KQL',
}

export enum KqlTargetType {
    Kusto = 'Kusto',
    Defender = 'Defender',
}

export enum SkillInterface {
    Agent = 'Agent',
}

export type AgentSkillSettings = {
    instructions: string;
};

export type KQLSkillSettings = {
    target: KqlTargetType;
    cluster?: string;
    database?: string;
    template: string;
};

export type SkillSettings = AgentSkillSettings | KQLSkillSettings;

export type Skill = {
    format: SkillGroupFormat;
    name: string;
    displayName: string;
    description: string;
    inputs: SkillInput[];
    settings: SkillSettings;
    childSkills?: string[];
    interfaces?: SkillInterface[];
};

export type Skillset = {
    name: string;
    description: string;
    displayName: string;
    skills: Skill[];
};
