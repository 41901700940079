import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {TenantInfo} from './tenant.types';
import handleError from '@/api/handleError';

export default function usePatchTenantInfo() {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (body: Partial<TenantInfo>) => {
            // the patch will only respond with a 204 and no body
            const response = await customFetch<Response>(
                `/settings/datashare/tenantInfo`,
                {
                    method: 'PATCH',
                    body: body,
                },
                true,
            );
            if (response.status === 200 || response.status === 204) {
                return true;
            } else {
                handleError(response, 'Failed to patch tenant settings');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['settings', 'datashare']);
        },
    });
}
