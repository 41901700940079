import {Button, Spinner, Tooltip, mergeClasses} from '@fluentui/react-components';
import useClasses from './RoleAssignment.styles';
import {AddFilled} from '@fluentui/react-icons';
import RoleGroup from './rbac/RoleGroup';
import {useRef, useState, useEffect} from 'react';
import AddRoleMembers from './rbac/AddRoleMembers/AddRoleMembers';
import {RoleObjectIdData} from './rbac/rbac.types';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import {useGetRoleMembers} from '@/api/rbac';
import {PolicyWorkspace} from '@/api/rbac/rbac.types';
import {useTranslation} from 'react-i18next';
import {ErrorDisplay} from './ErrorDisplay';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import AddRoles from './rbac/AddRoles/AddRoles';
import {EVERYONE_IDENTIFIER} from '@/api/rbac/rbac.constants';

export default function RoleAssignment() {
    const [isAddRoleMembersOpen, setIsAddRoleMembersOpen] = useState<boolean>(false);
    const [isAddRolesOpen, setIsAddRolesOpen] = useState<boolean>(false);
    const [existingMembers, setExistingMembers] = useState<RoleObjectIdData>();
    const addButtonRef = useRef<HTMLButtonElement>(null);
    const [policyProcessed, setPolicyProcessed] = useState<boolean>(false);
    const [isGraphDataError, setGraphDataError] = useState<boolean>(false);
    const [isGraphDataLoading, setIsGraphDataLoading] = useState<boolean>(false);
    const [isAddRoleMemberError, setAddRoleMemberError] = useState<boolean>(false);
    const pageClasses = useClasses();

    const {workspaceName} = useWorkspaceState();

    const is1pRbacEnabled = useFeatureFlag(MedeinaFeatures.Enable1pRbac);

    const {
        data: dataShareResponse,
        isLoading: dataShareLoading,
        isFetched: dataShareFetched,
        isSuccess: dataShareIsSuccessful,
        isError: dataShareError,
        isError: dataShareIsError,
        isRefetching: dataShareRefetching,
    } = useGetRoleMembers(workspaceName || '', {enabled: !!workspaceName});

    const isEveryoneIncluded = existingMembers?.Contributor?.User?.includes(EVERYONE_IDENTIFIER);

    const addRoleMemberClick = () => {
        setIsAddRoleMembersOpen(true);
    };

    const addRolesClick = () => {
        setIsAddRolesOpen(!isAddRolesOpen);
    };

    const performActionsOnAddRoleMembersClose = () => {
        setIsAddRoleMembersOpen(false);
        addButtonRef.current?.focus();
    };

    const performActionsOnAddRolesClose = () => {
        setIsAddRolesOpen(false);
        const accordionHeader = document.querySelector(`#role-section-Owner`);

        const button = accordionHeader?.querySelector('button');

        if (button) {
            if (button) {
                (button as HTMLButtonElement).focus();
            }
        }
    };

    const performAddRoleMembersActions = (roleObjectIdData: RoleObjectIdData) => {
        setExistingMembers(roleObjectIdData);
        setPolicyProcessed(true);
    };

    const onGraphDataError = () => {
        setGraphDataError(true);
    };

    const [isRecommendedRolesDetected, setIsRecommendedRolesDetected] = useState<boolean>(false);

    const handleRecommendedRolesDetected = (hasRecommendedRoles: boolean) => {
        setIsRecommendedRolesDetected(hasRecommendedRoles);
    };

    const [referenceNameError, setReferenceNameError] = useState<boolean>(false);
    const [policyReferenceName, setPolicyReferenceName] = useState<string>('');

    useEffect(() => {
        if (dataShareIsSuccessful) {
            if (dataShareResponse.properties.entity.referenceName!!) {
                setPolicyReferenceName(dataShareResponse.properties.entity.referenceName);
            } else {
                setReferenceNameError(true);
            }
        }
    }, [dataShareFetched, dataShareIsSuccessful]);

    const {t} = useTranslation('admin');

    const isDataLoading =
        dataShareLoading || dataShareRefetching || isGraphDataLoading || !policyProcessed;
    const isDataError = isGraphDataError || dataShareError || referenceNameError;

    const isButtonDisabled = isDataLoading || isRecommendedRolesDetected;

    return (
        <div className={pageClasses.root}>
            <div className={pageClasses.titleSection}>
                <h1 className={pageClasses.title}>{t('RoleAssignment.PageHeading')}</h1>
                <div className={mergeClasses(pageClasses.paragraphContent)}>
                    {t('RoleAssignment.InfoContent')}
                </div>
                <div className={mergeClasses(pageClasses.addMemberButton)}>
                    <Button
                        appearance="primary"
                        onClick={addRoleMemberClick}
                        ref={addButtonRef}
                        aria-label={t('RoleAssignment.AriaLabels.AddMember')}
                        disabled={isDataLoading && !isAddRoleMemberError}
                    >
                        <AddFilled />
                        {t('RoleAssignment.AddMember')}
                    </Button>
                    {is1pRbacEnabled &&
                        (isRecommendedRolesDetected ? (
                            <Tooltip
                                withArrow
                                content={t('RoleAssignment.AddRoles.Tooltip.RecommendedRolesAdded')}
                                relationship="label"
                            >
                                <Button
                                    appearance="primary"
                                    onClick={addRolesClick}
                                    ref={addButtonRef}
                                    aria-label={t('RoleAssignment.AriaLabels.AddRoles')}
                                    disabled={isButtonDisabled}
                                >
                                    <AddFilled />
                                    {t('RoleAssignment.AddRolesButton')}
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                appearance="primary"
                                onClick={addRolesClick}
                                ref={addButtonRef}
                                aria-label={t('RoleAssignment.AriaLabels.AddRoles')}
                                disabled={isButtonDisabled}
                            >
                                <AddFilled />
                                {t('RoleAssignment.AddRolesButton')}
                            </Button>
                        ))}
                </div>
            </div>
            {isDataLoading && !isDataError && !isAddRoleMemberError && (
                <Spinner
                    size="extra-large"
                    aria-live="polite"
                    label={t('RoleAssignment.LoadingMembersLabel')}
                    labelPosition="below"
                    className={pageClasses.spinner}
                ></Spinner>
            )}

            {isDataError && <ErrorDisplay />}

            <div className={pageClasses.componentContainer}>
                <RoleGroup
                    werePoliciesSuccessfullyFetched={dataShareIsSuccessful}
                    workspacePolicy={dataShareResponse as PolicyWorkspace}
                    onPolicySuccessfullyProcessed={performAddRoleMembersActions}
                    dataRefetching={dataShareRefetching}
                    onGraphDataError={onGraphDataError}
                    onGraphDataLoaded={() => setIsGraphDataLoading(false)}
                    onGraphDataLoading={() => setIsGraphDataLoading(true)}
                    isAddRoleMemberError={isAddRoleMemberError}
                    isDataLoading={isDataLoading}
                    policyReferenceName={policyReferenceName}
                    onRecommendedRolesDetected={handleRecommendedRolesDetected}
                />
            </div>
            <AddRoleMembers
                isAddRoleMembersOpen={isAddRoleMembersOpen}
                onAddRoleMembersClose={performActionsOnAddRoleMembersClose}
                existingMembers={existingMembers as RoleObjectIdData}
                workspacePolicy={dataShareResponse as PolicyWorkspace}
                onAddRoleMembersError={() => setAddRoleMemberError(false)}
                onAddRoleMembersReset={() => setAddRoleMemberError(false)}
                isRecommendedRolesDetected={isRecommendedRolesDetected}
            />
            {is1pRbacEnabled && (
                <AddRoles
                    isAddRolesOpen={isAddRolesOpen}
                    onAddRolesClose={performActionsOnAddRolesClose}
                    existingMembers={existingMembers as RoleObjectIdData}
                    workspacePolicy={dataShareResponse as PolicyWorkspace}
                />
            )}
        </div>
    );
}
