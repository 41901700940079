import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...shorthands.gap(tokens.spacingVerticalXXXL),
    },
    field: {},
    label: {
        display: 'block',
    },
    disabledLabel: {
        color: tokens.colorNeutralForegroundDisabled,
        '& label': {
            color: tokens.colorNeutralForegroundDisabled,
        },
    },
    warningLabel: {
        display: 'block',
        color: tokens.colorStatusDangerForeground3,
    },
    hidden: {
        display: 'none',
    },
    firstHeader: {
        marginTop: tokens.spacingVerticalSNudge,
    },
    switch: {
        marginTop: tokens.spacingVerticalSNudge,
        '& label': {
            paddingLeft: tokens.spacingHorizontalNone,
            paddingRight: tokens.spacingHorizontalNone,
        },
    },
    dropdownSelector: {
        // minWidth: '65%',
    },
    fullWidthDropdown: {
        minWidth: '80%',
    },
    dropdownWrapper: {
        marginTop: tokens.spacingVerticalM,
        '@media (max-width: 479px)': {
            '& .fui-Dropdown': {
                minWidth: '-webkit-fill-available',
            },
        },
    },
    editorToolbar: {
        rowGap: tokens.spacingVerticalMNudge,
        columnGap: tokens.spacingHorizontalS,
        padding: tokens.spacingHorizontalS,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: tokens.spacingVerticalM,
    },
    editorContent: {
        // marginTop: tokens.spacingVerticalM,
        // marginBottom: tokens.spacingVerticalM,
        // marginRight: tokens.spacingHorizontalS,
        // marginLeft: tokens.spacingHorizontalS,
    },
    paragraphContent: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.spacingVerticalL,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        color: tokens.colorNeutralForeground2,
        paddingBottom: tokens.spacingVerticalXXL,
    },
    linkContent: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.spacingVerticalL,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
    },
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
    },
});
