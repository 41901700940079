import {Button, Link} from '@fluentui/react-components';
import MedeinaFeatures from '@/util/features';
import useClasses from './DataAndPrivacy.styles';
import {ExternalLinkIcon, WindowNewIcon} from '../ui/icons';
import MedeinaVariables from '@/util/variables';
import {useGetUserInfo} from '@/api/app';
import {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import React from 'react';
import {CookieConsentContext} from '../ui/CookieConsentProvider/CookieConsentProvider';
import {useFeatureFlag} from '@/api/user';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import {getLanguage} from '@/util/locale';

interface DataPrivacyModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DataAndPrivacy({setOpen}: DataPrivacyModalProps) {
    const isCookieBannerEnabled = MedeinaFeatures.CookieBannerExperience;
    const classes = useClasses();
    const {t} = useTranslation();
    const [isAdmin, setIsAdmin] = useState(false);
    const siteConsent = useContext(CookieConsentContext);

    const {data: userInfo, refetch} = useGetUserInfo();
    const isFidelisEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisGA);

    const locale = useMemo(() => getLanguage(), []);

    const {geoName} = useWorkspaceState();

    const handleManageCookiesClick = () => {
        setOpen(false);
        siteConsent?.manageConsent();
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (userInfo?.isAdmin !== undefined) {
            setIsAdmin(userInfo.isAdmin);
        }
    }, [userInfo]);

    return (
        <div>
            <div className={classes.field}>
                <h3 className={classes.firstHeader}>{t('privacyHeader')}</h3>
                <Link href={MedeinaVariables.PrivacyStatementUri} target="_blank">
                    {t('privacyStatementLink')}
                </Link>
            </div>
            {MedeinaVariables.TermsAndConditionsUri && (
                <div className={classes.field}>
                    <h3>{t('termsAndConditionsText')}</h3>
                    <Link href={MedeinaVariables.TermsAndConditionsUri} target="_blank">
                        {t('termsAndConditionsText')}
                    </Link>
                </div>
            )}
            {locale == 'fr' && (
                <div className={classes.field}>
                    <h3>{t('Accessibility')}</h3>
                    <Link href={MedeinaVariables.FRa11yComplianceUri} target="_blank">
                        {t('AccessibilityStatement')} <ExternalLinkIcon />
                    </Link>
                </div>
            )}
            {locale == 'it' && (
                <div className={classes.field}>
                    <h3>{t('Accessibility')}</h3>
                    <Link href={MedeinaVariables.ITa11yComplianceUri} target="_blank">
                        {t('AccessibilityStatement')} <ExternalLinkIcon />
                    </Link>
                </div>
            )}
            {isFidelisEnabled && geoName && (
                <div className={classes.field}>
                    <h3>{t('Location')}</h3>
                    {!!geoName && (
                        <p className={classes.geoData}>
                            {t('dataStorageDescription')} {geoName}
                        </p>
                    )}
                    {!geoName && <p className={classes.geoData}>{t('LocationUnavailable')}</p>}
                </div>
            )}
            {isCookieBannerEnabled && siteConsent?.isConsentRequired && (
                <div className={classes.field}>
                    <h3>{t('cookiesHeader')}</h3>
                    <Button icon={<WindowNewIcon />} onClick={handleManageCookiesClick}>
                        {t('manageCookiesButton')}
                    </Button>
                </div>
            )}
        </div>
    );
}
