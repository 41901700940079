import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
export const memberHoverFocus = 'memberHoverFocus';

export default makeStyles({
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
    },
    paragraphContent: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        color: tokens.colorNeutralForeground2,
        paddingBottom: tokens.spacingVerticalXL,
        ...shorthands.padding(
            tokens.spacingVerticalSNudge,
            tokens.spacingHorizontalM,
            tokens.spacingVerticalXL,
        ),
    },
    roleSection: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        paddingTop: tokens.spacingVerticalXXXL,
    },
    roleMemberPanel: {
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.margin(tokens.spacingVerticalSNudge, tokens.spacingHorizontalM),
        [`&:hover .${memberHoverFocus}, &:focus-within .${memberHoverFocus}`]: {
            opacity: 1,
        },
    },
    roleMemberPersona: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.spacingVerticalL,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        color: tokens.colorNeutralForeground2,
        '& > span.fui-Persona__secondaryText': {
            marginTop: '1px',
        },
    },
    roleMemberActions: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.spacingVerticalL,
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground2,
    },
    errorTag: {
        color: tokens.colorPaletteCranberryBorderActive,
        marginBottom: tokens.spacingVerticalL,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: '14px',
        '& > span:first-child': {
            position: 'relative',
            top: '2px',
            paddingRight: '3px',
        },
    },
    noContributor: {
        marginLeft: '14px',
    },
    actionButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        columnGap: tokens.spacingHorizontalM,
    },
    closeButton: {
        position: 'absolute',
        right: `-15px`,
    },
    dialogContentSection: {
        marginBottom: tokens.spacingVerticalS,
        marginTop: tokens.spacingVerticalS,
    },
    icon: {
        transform: 'scale(1.5)',
        top: tokens.spacingVerticalXS,
    },
});
