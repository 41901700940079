import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import useClasses from './AddRolesConfirmation.styles';
import {DismissIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';

interface AddRoleMembersConfirmationProps {
    isConfirmDialogOpen: boolean;
    onConfirmDialogClose?: () => void;
}

export default function AddRolesConfirmation(props: AddRoleMembersConfirmationProps) {
    const classes = useClasses();

    const closeConfirmation = () => {
        props.onConfirmDialogClose?.();
    };

    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');

    useEffect(() => {}, [props.isConfirmDialogOpen]);

    return (
        <Dialog open={props.isConfirmDialogOpen}>
            <DialogSurface>
                <DialogTrigger disableButtonEnhancement>
                    <Button
                        data-test-id="add-member-confirmation-close-button"
                        className={classes.closeButton}
                        appearance="transparent"
                        aria-label={t('RoleAssignment.AddRoles.AriaLabels.CloseConfirmationDialog')}
                        onClick={() => closeConfirmation()}
                    >
                        <DismissIcon className={classes.icon} />
                    </Button>
                </DialogTrigger>
                <DialogBody>
                    <DialogTitle>
                        {t('RoleAssignment.AddRoles.Confirmation.RolesAdded')}
                    </DialogTitle>
                    <DialogContent className={classes.dialogContentSection}>
                        {t('RoleAssignment.AddRoles.Confirmation.TheseRoles')}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-test-id="add-member-confirmation-done-button"
                            appearance="primary"
                            onClick={closeConfirmation}
                        >
                            {commonLabel('ButtonLabels.Ok')}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
