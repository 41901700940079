import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    contentSection: {
        marginBottom: tokens.spacingVerticalXS,
    },
    titleContainer: {
        display: 'flex',
    },
});
