import React, {useMemo, useEffect, useState, useCallback} from 'react';
import {IDropdownOption} from '@fluentui/react';
import {useClasses} from './PromptBarOrchestratorSelector.styles';
import {SkillDescriptor, useGetAllSkills} from '@/api/skills';
import {useLocation} from 'react-router-dom';
import {useGetSession, useUpdateSession} from '@/api/sessions';
import {
    Menu,
    MenuButton,
    MenuButtonProps,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components';
import {AgentIcon, DefaultOrchestratorIcon, DownIcon} from '../icons';

interface PromptBarOrchestratorSelectorProps {
    onAgentChange: (newAgent: string) => void;
    setCurrentAgent: (newAgent: string) => void;
}

interface AgentOption extends IDropdownOption {
    key: string;
    text: string;
}

let initialAgent: AgentOption = {key: 'DefaultAgent', text: 'Security Copilot Interactive Agent'};

const PromptBarOrchestratorSelector: React.FC<PromptBarOrchestratorSelectorProps> = ({
    onAgentChange,
    setCurrentAgent,
}) => {
    const classes = useClasses();
    const {data: allSkills, isLoading} = useGetAllSkills();
    const [selectedAgent, setSelectedAgent] = useState<AgentOption>(initialAgent);
    const updateSession = useUpdateSession();
    const location = useLocation();

    const agentSkills = useMemo(() => {
        return (
            allSkills?.value.filter((skill: SkillDescriptor) =>
                skill.interfaces?.includes('Agent'),
            ) || []
        );
    }, [allSkills]);

    const sessionId = location.pathname.split('/sessions/')[1];
    const {data: session} = useGetSession({sessionId});

    const dropdownOptions: AgentOption[] = useMemo(() => {
        let agents: AgentOption[] = [];
        agentSkills.map((skill) =>
            agents.push({
                key: skill.name,
                text: skill.displayName || skill.name,
            }),
        );
        return agents;
    }, [agentSkills]);

    useEffect(() => {
        if (session && session.defaultAgent) {
            const defaultAgentOption = dropdownOptions.find(
                (option) => option.key === session.defaultAgent,
            );
            if (defaultAgentOption) {
                setSelectedAgent(defaultAgentOption);
            }
        }
    }, [dropdownOptions, session]);

    useEffect(() => {
        setCurrentAgent(selectedAgent.key);
    }, [selectedAgent, setCurrentAgent]);

    const handleSelectionChange = useCallback(
        (option: AgentOption) => {
            if (option) {
                const selectedSkillName = option.key as string;
                const selectedSkill = agentSkills.find((skill) => skill.name === selectedSkillName);
                if (selectedSkill) {
                    setSelectedAgent(option);
                    onAgentChange(selectedSkillName);

                    // Check if the URL contains '/sessions/'
                    if (location.pathname.includes('/sessions/') && session) {
                        // Update the session with the new agent
                        updateSession.mutate({...session, defaultAgent: selectedSkillName});
                    }
                }
            }
        },
        [agentSkills, location.pathname, onAgentChange, session, updateSession],
    );

    return (
        <Menu positioning="below-end">
            <MenuTrigger disableButtonEnhancement>
                {(triggerProps: MenuButtonProps) => (
                    <Tooltip content={selectedAgent ? selectedAgent.text : ''} relationship="label">
                        <MenuButton
                            size="medium"
                            appearance="subtle"
                            icon={
                                selectedAgent && selectedAgent.key !== 'DefaultAgent' ? (
                                    <AgentIcon />
                                ) : (
                                    <DefaultOrchestratorIcon />
                                )
                            }
                            menuIcon={<DownIcon />}
                            className={classes.menuButton}
                            disabled={isLoading}
                            id="orchestrator-dropdown"
                            {...triggerProps}
                        >
                            {' '}
                        </MenuButton>
                    </Tooltip>
                )}
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {dropdownOptions.map((agentOption) => (
                        <MenuItem
                            onClick={() => handleSelectionChange(agentOption)}
                            key={agentOption.key}
                        >
                            {agentOption.text}
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export default PromptBarOrchestratorSelector;
