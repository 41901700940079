import {useFeatureFlag} from '@/api/user';
import {useTranslation} from 'react-i18next';
import useClasses from './UsageDashboard.styles';
import MedeinaFeatures from '@/util/features';
import {NumericDisplayEmphasis, SmallTitle} from '@/components/ui/Text';
import {
    BASE_UNITS_PER_HOUR,
    ButtonLabels,
    UNIT_PLURAL_TEXT,
    UNIT_SINGLE_TEXT,
    WORKSPACE_USAGE,
} from './UsageDashboard.constants';
import {
    Button,
    Dialog,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ChangeCapacity from '../ChangeCapacityDialog/ChangeCapacity';
import {useGetWorkspaces, useUpdateWorkspace, Workspace} from '@/api/workspaces';
import {Capacity, useGetCapacities} from '@/api/capacities';
import {WorkspaceCapacity} from '@/api/workspaces/workspaces.types';
import {WorkspaceForm} from '../../workspaces';
import DisconnectCapacityDialog from '../../workspaces/ManageWorkspaces/DisconnectCapacityDialog';
import {CapacityWarningMessageBar} from '@/components/sections/workspaces/ManageWorkspaces/CapacityWarningMessageBar';
import {AddIcon} from '@/components/ui/icons';

/**
 * Props for the CapacityAllocationSection component.
 *
 * @interface CapacityAllocationSectionProps
 *
 * @property {boolean} showChangeUnitsComponents - Flag to show or hide the components for changing units.
 * @property {boolean} disableChangeCapacity - Flag to enable or disable the capacity change functionality.
 * @property {string} workspaceDisplayName - The display name of the workspace.
 * @property {number} assignedCapacityUnits - The number of capacity units assigned.
 */
interface CapacityAllocationSectionProps {
    showChangeUnitsComponents: boolean;
    disableChangeCapacity: boolean;
    workspaceDisplayName: string;
    assignedCapacityUnits: number;
    selectedCapacity: string;
    setWorkspaceDisplayName: (workspace: string) => void;
}

export default function CapacityAllocationSection(props: CapacityAllocationSectionProps) {
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    const {t: tAdmin} = useTranslation('admin');
    const {t: tCommon} = useTranslation('common');

    const classes = useClasses();
    const {data: data, isSuccess: isGetCapacitiesSuccess} = useGetCapacities({});
    const {data: workspacesData} = useGetWorkspaces();

    // Used to open and close the change capacity dialog
    const changeCapacityButtonRef = useRef<HTMLButtonElement>(null);
    // manage workspaces button ref
    const manageWorkspacesButtonRef = useRef<HTMLButtonElement>(null);
    const [isChangeCapacityDialogOpen, setChangeCapacityDialogOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const getCapacityUnitsText = () => {
        if (!props.assignedCapacityUnits) {
            return '';
        }
        const unitText =
            props.assignedCapacityUnits === 1 ? tAdmin(UNIT_SINGLE_TEXT) : tAdmin(UNIT_PLURAL_TEXT);
        return `${props.assignedCapacityUnits} ${unitText}`;
    };

    const performActionsOnCapacityDialogClosure = () => {
        setChangeCapacityDialogOpen(false);
        changeCapacityButtonRef.current?.focus();
    };

    // multi-workspaces
    const {availableWorkspaces, unavailableWorkspaces} = useMemo(() => {
        let available: Workspace[] = [];
        let unavailable: Workspace[] = [];
        if (workspacesData?.value) {
            for (const workspace of workspacesData.value) {
                if (
                    data?.value.find(
                        (capacity: Capacity) =>
                            capacity.name === workspace?.capacity?.referenceName,
                    )
                ) {
                    unavailable.push(workspace);
                } else {
                    available.push(workspace);
                }
            }
        }
        return {availableWorkspaces: available, unavailableWorkspaces: unavailable};
    }, [workspacesData, data?.value]);
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
    const [tempSelectedWorkspace, setTempSelectedWorkspace] = useState<string | null>(null);
    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);
    const [attachedCapacity, setAttachedCapacity] = useState<WorkspaceCapacity>();
    const {mutate: updateWorkspace} = useUpdateWorkspace();
    const handleDisconnectConfirm = () => {
        // update workspace
        const selectedWorkspace = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === tempSelectedWorkspace,
        );
        handleAvailableWorkspaceClick(selectedWorkspace?.name || '');
        setDisconnectCapacityDialogOpen(false);
    };
    const handleInUseWorkspaceClick = (workspaceSelected: string) => {
        setTempSelectedWorkspace(workspaceSelected);
        // find the capacity attached to the workspace
        const tempAttachedCapacity = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === workspaceSelected,
        )?.capacity;
        if (tempAttachedCapacity) {
            setAttachedCapacity(tempAttachedCapacity);
            setDisconnectCapacityDialogOpen(true);
        }
    };
    const handleAvailableWorkspaceClick = (workspaceSelected: string) => {
        // update workspace
        const selectedWorkspace = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === workspaceSelected,
        );
        if (selectedWorkspace) {
            updateWorkspace(
                {
                    ...selectedWorkspace,
                    name: selectedWorkspace.name,
                    capacity: {type: 'capacity', referenceName: props.selectedCapacity},
                },
                {
                    onSuccess: () => {
                        console.log('Workspace updated');
                        props.setWorkspaceDisplayName(selectedWorkspace.name);
                    },
                    onError: (error) => {
                        console.error('Error updating workspace', error);
                    },
                },
            );
        }
    };

    return (
        <>
            <div className={classes.metaData}>
                {props.showChangeUnitsComponents && (
                    <>
                        <div>
                            <SmallTitle data-testid="base-units-element">
                                {tAdmin(BASE_UNITS_PER_HOUR)}
                            </SmallTitle>
                        </div>
                        <div
                            className={classes.capacityValueContainer}
                            data-testid="capacity-units-display"
                        >
                            <NumericDisplayEmphasis>
                                {getCapacityUnitsText()}
                            </NumericDisplayEmphasis>
                        </div>
                    </>
                )}
                <div data-testid="show-change-units-section">
                    {props.showChangeUnitsComponents ? (
                        <Button
                            appearance="secondary"
                            onClick={() => setChangeCapacityDialogOpen(true)}
                            ref={changeCapacityButtonRef}
                            disabled={props.disableChangeCapacity}
                        >
                            {tCommon(ButtonLabels.CHANGE_CAPACITY)}
                        </Button>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
            <div>
                {isWorkspacesTestingEnabled && (
                    <>
                        <div>
                            <SmallTitle>{tAdmin(WORKSPACE_USAGE)}</SmallTitle>
                        </div>
                        <div className={classes.capacityValueContainer}>
                            {props.workspaceDisplayName ? (
                                <NumericDisplayEmphasis>
                                    {props.workspaceDisplayName}
                                </NumericDisplayEmphasis>
                            ) : (
                                <div className={classes.workspaceSelect}>
                                    <Menu>
                                        <MenuTrigger disableButtonEnhancement>
                                            <MenuButton
                                                data-testid="workspace-menu-button"
                                                className={classes.errorMenuButton}
                                            >
                                                {tAdmin(
                                                    'ManageWorkspaces.MenuButtons.NoWorkspaceSelected',
                                                )}
                                            </MenuButton>
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                <MenuGroup>
                                                    <MenuGroupHeader>
                                                        {tAdmin('Workspaces.Form.Available')}
                                                    </MenuGroupHeader>
                                                    {availableWorkspaces.map(
                                                        (workspace: Workspace, index: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    icon={
                                                                        <div
                                                                            className={
                                                                                classes.fillerIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() =>
                                                                        handleAvailableWorkspaceClick(
                                                                            workspace.name,
                                                                        )
                                                                    }
                                                                >
                                                                    {workspace.name}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                                    <MenuGroupHeader>
                                                        {tAdmin('Workspaces.Form.InUse')}
                                                    </MenuGroupHeader>
                                                    {unavailableWorkspaces.map(
                                                        (workspace: Workspace, index: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    icon={
                                                                        <div
                                                                            className={
                                                                                classes.fillerIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() =>
                                                                        handleInUseWorkspaceClick(
                                                                            workspace.name,
                                                                        )
                                                                    }
                                                                >
                                                                    {workspace.name}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                                </MenuGroup>
                                                <MenuDivider />
                                                <MenuGroup>
                                                    <MenuItem
                                                        icon={<AddIcon />}
                                                        data-testid="new-workspace-menu-item"
                                                        onClick={() => setWorkspaceDialogOpen(true)}
                                                    >
                                                        {tAdmin(
                                                            'ManageWorkspaces.MenuButtons.NewWorkspace',
                                                        )}
                                                    </MenuItem>
                                                </MenuGroup>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>
                                </div>
                            )}
                        </div>
                        <Button
                            appearance="secondary"
                            onClick={() => navigate('/manage-workspaces')}
                            ref={manageWorkspacesButtonRef}
                            disabled={props.disableChangeCapacity}
                        >
                            {tAdmin('ManageWorkspaces.MenuButtons.ManageWorkspaces')}
                        </Button>
                        <Dialog
                            open={workspaceDialogOpen}
                            onOpenChange={(event, data) => setWorkspaceDialogOpen(data.open)}
                        >
                            <WorkspaceForm
                                mode="create"
                                onClose={() => setWorkspaceDialogOpen(false)}
                            />
                        </Dialog>
                        <DisconnectCapacityDialog
                            title={tAdmin('ManageWorkspaces.DisconnectWorkspaceDialog.Title', {
                                0: tempSelectedWorkspace,
                            })}
                            descriptionShort={tAdmin(
                                'ManageWorkspaces.DisconnectWorkspaceDialog.DescriptionShort',
                            )}
                            description={tAdmin(
                                'ManageWorkspaces.DisconnectWorkspaceDialog.Description',
                                {
                                    0: tempSelectedWorkspace,
                                    1: props.selectedCapacity,
                                    2: attachedCapacity?.referenceName,
                                },
                            )}
                            switchButtonLabel={tAdmin(
                                'ManageWorkspaces.DisconnectWorkspaceDialog.SwitchButton',
                            )}
                            isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                            newCapacityName={props.selectedCapacity || ''}
                            workspaceName={tempSelectedWorkspace || ''}
                            onClose={() => {
                                setDisconnectCapacityDialogOpen(false);
                            }}
                            onConfirm={handleDisconnectConfirm}
                        />
                    </>
                )}
            </div>
            {props.showChangeUnitsComponents && (
                <ChangeCapacity
                    isChangeCapacityDialogOpen={isChangeCapacityDialogOpen}
                    onChangeCapacityDialogClose={performActionsOnCapacityDialogClosure}
                />
            )}
        </>
    );
}
