import {PromptSuggestionDescriptor} from '@/api/prompts/suggestedPrompts.types';
import {Button, Input, ToolbarDivider, Tooltip} from '@fluentui/react-components';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DismissIcon, SendIcon} from '../icons';
import useClasses from './PromptBarSuggestedPromptForm.styles';
import AcrylicBase from '../AcrylicBase';
import {useTranslation} from 'react-i18next';

type PromptBarSuggestedPromptFormProps = {
    suggestedPrompt: PromptSuggestionDescriptor;
    onCancel?: () => void;
    onSuggestedPromptChange?: (value: PromptSuggestionDescriptor) => void;
    onSubmit?: () => void;
};

export default function PromptBarSuggestedPromptForm({
    suggestedPrompt,
    onCancel,
    onSuggestedPromptChange,
    onSubmit,
}: PromptBarSuggestedPromptFormProps) {
    const classes = useClasses();
    const contentRef = useRef<HTMLDivElement>(null);
    const firstInputRef = useRef<HTMLInputElement>(null);
    const {t} = useTranslation('promptbar');
    const containerRef = useRef<HTMLDivElement>(null);

    const [editableContent, setEditableContent] = useState(suggestedPrompt?.prompt);

    useEffect(() => {
        setEditableContent(suggestedPrompt?.prompt);
    }, [suggestedPrompt]);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [suggestedPrompt]);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
            const newContent = editableContent.split(/(<[^>]+>)/g);
            const newValue = event.target.value.trim() === '' ? ' ' : event.target.value;
            newContent[index] = `<${newValue}>`;
            const joinedContent = newContent.join('');
            setEditableContent(joinedContent);
            const updatedSuggestedPrompt = {...suggestedPrompt, prompt: joinedContent};
            onSuggestedPromptChange?.(updatedSuggestedPrompt);
        },
        [editableContent],
    );

    const renderEditablePrompt = () => {
        let firstInputFound = false;
        const parts = editableContent.split(/(<[^>]+>)/g);
        return parts.map((part, index) => {
            if (part.startsWith('<') && part.endsWith('>')) {
                const inputRef = firstInputFound ? null : firstInputRef;
                firstInputFound = true;
                return (
                    <Input
                        key={index}
                        className={classes.editable}
                        onChange={(event) => handleInputChange(event, index)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSubmit?.();
                            }
                        }}
                        placeholder={part.slice(1, -1).toUpperCase()}
                        ref={inputRef}
                        autoFocus
                    />
                );
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    // On first load we need to check if anything has already been focused, if it has that means theres and input and autofocus took over, if there's not we want to throw focus to the submit button
    useEffect(() => {
        if (containerRef.current) {
            // check if there's already an element within the container that has focus
            const focusedElement = containerRef.current.querySelector(':focus');
            if (!focusedElement) {
                // if there's no element focused, focus the submit button
                const submitButton = containerRef.current.querySelector<HTMLDivElement>(
                    '[data-testid="promptbar-submit-skill-button"]',
                );
                if (submitButton) {
                    submitButton.focus();
                }
            }
        }
    }, []);

    return (
        <div>
            <AcrylicBase shadow="small" className={classes.root}>
                <div className={classes.promptBar} ref={containerRef}>
                    <div className={classes.textContainer} ref={contentRef}>
                        {renderEditablePrompt()}
                    </div>
                    <div className={classes.icons}>
                        <Tooltip
                            content={t('tooltips.removePrompt')}
                            relationship="label"
                            positioning="above"
                        >
                            <Button
                                appearance="transparent"
                                shape="rounded"
                                icon={<DismissIcon />}
                                onClick={onCancel}
                                data-testid="promptbar-remove-skill-button"
                            />
                        </Tooltip>
                        <ToolbarDivider className={classes.toolbarDiv} />
                        <Tooltip
                            content={t('tooltips.submitPrompt')}
                            relationship="label"
                            positioning="above"
                        >
                            <Button
                                appearance="transparent"
                                className={classes.submit}
                                icon={<SendIcon />}
                                onClick={onSubmit}
                                data-testid="promptbar-submit-skill-button"
                            />
                        </Tooltip>
                    </div>
                </div>
            </AcrylicBase>
        </div>
    );
}
