import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-word',
        ...shorthands.margin(tokens.spacingVerticalNone, 'auto'),
        ...shorthands.padding(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalNone,
        ),
        width: '85%',
        minWidth: '280px',
        height: '90%',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: tokens.spacingVerticalNone,
        marginBottom: tokens.spacingVerticalXL,
    },
    titleContainerColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: tokens.spacingVerticalNone,
        marginBottom: tokens.spacingVerticalXL,
    },
    titleText: {
        fontSize: tokens.fontSizeHero800,
        fontWeight: tokens.fontWeightSemibold,
    },
    titleTextContainer: {
        ...shorthands.margin(
            tokens.spacingVerticalXS,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
        ),
    },
    infoContainer: {
        width: 'min(100%,500px)',
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalXXL,
            tokens.spacingHorizontalNone,
        ),
    },
    capacityHeader: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
    capacityValue: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: '28px',
    },
    capacityValueContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        lineHeight: tokens.lineHeightBase500,
        paddingBottom: tokens.spacingVerticalMNudge,
    },
    actionBtns: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...shorthands.margin(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalXXL,
        ),
    },
    actionBtnsV2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...shorthands.margin(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalXXL,
        ),
        marginTop: 'auto',
        marginLeft: 'auto',
    },
    apiStatusMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    apiStatusEmpty: {
        height: 'auto',
    },
    apiStatusWithContent: {
        height: '100%',
    },
    loadingContainer: {
        marginTop: tokens.lineHeightHero900,
    },
    drawerRoot: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        rowGap: tokens.spacingVerticalL,
        width: '500px',
    },
    drawerHeader: {
        marginLeft: tokens.spacingHorizontalM,
    },
    drawerBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: tokens.spacingVerticalXXL,
        paddingBottom: tokens.spacingVerticalXXL,
        backgroundColor: tokens.colorTransparentBackground,
        backgroundImage: 'none',
    },
    drawerFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftButtons: {
        marginLeft: tokens.spacingHorizontalM,
    },
    rightButtons: {
        display: 'flex',
        columnGap: tokens.spacingVerticalMNudge,
        marginRight: tokens.spacingHorizontalXXXL,
    },
    dateFilterDropdown: {
        marginRight: tokens.spacingHorizontalM,
    },
    leftControlsElement: {
        display: 'flex',
        alignItems: 'center',
    },
    gridClasses: {
        height: '100%',
    },
    exportButtonContainer: {
        marginRight: tokens.spacingHorizontalM,
    },
    visuallyHidden: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        ...shorthands.margin('-1'),
        ...shorthands.padding(0),
        ...shorthands.overflow('hidden'),
        clip: 'rect(0, 0, 0, 0)',
        ...shorthands.border(0),
    },
    metaDataContainer: {
        display: 'flex',
    },
    metaData: {
        paddingRight: tokens.spacingHorizontalXXL,
    },
    FilterButtonRow: {
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalXXL,
    },
    addCapacityButton: {
        outline: '1px dashed',
    },
    rightAlign: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    divider: {
        paddingRight: tokens.spacingVerticalS,
    },
    errorMenuButton: {
        border: '1px solid ' + tokens.colorPaletteDarkOrangeBorder1,
        backgroundColor: tokens.colorPaletteDarkOrangeBackground1,
        '&:hover': {
            backgroundColor: tokens.colorPaletteDarkOrangeBorder1,
        },
    },
    fillerIcon: {
        width: '16px',
        display: 'inline-block',
    },
    workspaceSelect: {
        marginTop: tokens.spacingVerticalS,
    },
});
