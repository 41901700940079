import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserPreferences} from './user.types';
export default function useUpdateCurrentWorkspace() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const updateCurrentWorkspace = async (newCurrentWorkspace: string) => {
        return await customFetch<UserPreferences>(`/userPreferences/currentWorkspace`, {
            method: 'PUT',
            body: JSON.stringify({currentWorkspace: newCurrentWorkspace}),
            headersFromOptions: {
                'Content-Type': 'application/json',
            },
        });
    };
    return useMutation({
        mutationFn: updateCurrentWorkspace,
        onSettled: (updatedPreferences, error, variables, context) => {
            if (!error) {
                queryClient.invalidateQueries(['userPreferences']);
            }
        },
    });
}
