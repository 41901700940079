import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    icon: {
        '& > svg.fui-Icon-regular': {
            height: '42px',
            width: '42px',
            '@media (forced-colors: active)': {
                backgroundColor: 'Canvas',
            },
        },
        marginBottom: tokens.spacingVerticalXXL,
    },
    description: {
        marginBottom: tokens.spacingVerticalL,
    },
});
