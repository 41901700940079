import React, {useState, ReactNode, useCallback, useEffect} from 'react';
import Navigation from '@/components/Navigation';
import {CustomFluentProvider} from '@/components/theme';
import useClasses, {useStaticStyles} from './App.styles';
import NavigationContext from './Navigation/Context';
import {NavActionsInput, ToggableNavActions} from './Navigation/Navigation.types';
import {useLocation, useNavigate} from 'react-router-dom';
import {DebugLevel, useAppState, useGetUserInfo} from '@/api/app';
import SidePanelContext from './ui/SidePanel/SidePanelContext';
import {defaultToggableActions} from './Navigation/Context';
import {mergeClasses} from '@fluentui/react-components';
import {Overlay, Controls, TourPopovers, TourPopoverSurface} from '@/components/ui/Tour';
import Background from './Background';
import {Provider as LayoutContextProvider} from '@/components/ui/Layout/Context';
import SidePanel from './SidePanel';
import ResizeBar from './ui/Layout/ResizeBar';
import Content from './Content';
import TourProviderWithContext from './sections/tours/TourProviderWithContext';
import MedeinaFeatures from '@/util/features';
import PrototypeWarningBanner from './sections/home/NewHome/PrototypeWarningBanner';
import {useFeatureFlag} from '@/api/user';
import {AssistanceManager} from './ui/PromptBar/PortalAssistance';
import SwitchCapacity from './SwitchCapacity';
import {useWorkspaceState} from './workspaces/workspaceStateProvider';
import {useLocale} from '@/util/locale';
import useNavigationBanner from './Navigation/useNavigationBanner';
import ExpiredCapacity from './ExpiredCapacity';
import useGetEapExpired from '@/api/app/useGetEapExpired';
import {useGetWorkspaces} from '@/api/workspaces';
import {ManageWorkspaces} from './sections/workspaces';

export default function App({children}: {children: React.ReactNode}) {
    useStaticStyles();
    const classes = useClasses();
    const [navActions, setNavActions] = useState<NavActionsInput>(undefined);
    const [toggableActions, setToggableActions] = useState(defaultToggableActions);
    const [paneContent, setPaneContent] = useState<ReactNode | null>(null);
    const {layout, setDebugLevel} = useAppState();
    const {sidePanel} = layout || {sidePanel: {open: false, width: 0}};
    const {pathname} = useLocation();
    const isVerboseLoggingEnabled = useFeatureFlag(MedeinaFeatures.EnableVerboseLogging);
    useLocale();
    const {isVisible: isNavigationBannerVisible} = useNavigationBanner();

    const setNextActions = useCallback(
        (nextActions: Partial<ToggableNavActions>) => {
            setToggableActions({...toggableActions, ...nextActions});
        },
        [toggableActions],
    );

    // Set the debug level to summary if the user does not have the EnableVerboseLogs feature flag
    useEffect(() => {
        if (!isVerboseLoggingEnabled) {
            setDebugLevel(DebugLevel.Summary);
        }
    }, []);

    const {
        data: expiryDateData,
        isFetched: expiryDateFetched,
        isRefetching: expiryDateRefetching,
        isSuccess: expiryDateSuccess,
        isError: expiryDateError,
    } = useGetEapExpired();

    // multi-workspaces
    const {data: workspaces} = useGetWorkspaces();
    const areWorkspacesRemoved = !workspaces || workspaces.value.length === 0;
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    const isEAPTenant = !!expiryDateData?.expiryDate;

    const firstRun = pathname === '/start';

    // State to handle switch capacity dialog open/close action
    const [isSwitchCapacityDialogOpen, setIsSwitchCapacityDialogOpen] = useState<boolean>(false);
    const {isWorkspaceDetached, areCapacitiesRemoved, isProviderLoaded} = useWorkspaceState();

    useEffect(() => {
        // Feature flagged
        if (expiryDateFetched && expiryDateSuccess && !!expiryDateData?.expiryDate) {
            try {
                const expiryDateValue = new Date(expiryDateData.expiryDate);
                const expiryDateIso = expiryDateValue.toISOString();
                const expiryDate = new Date(expiryDateIso);
                const currentDate = new Date();
                setIsExpired(currentDate > expiryDate);
            } catch {
                setIsExpired(false);
            }
        } else {
            setIsExpired(false);
        }
    }, [expiryDateData, expiryDateFetched, expiryDateRefetching]);

    const [isExpired, setIsExpired] = useState<boolean>(true);
    // Fetching user auth data
    const {data: userInfo, isFetched: isUserInfoFetched} = useGetUserInfo();

    // Action to trigger switch capacity dialog if workspace has no capacity linked and user is admin.
    useEffect(() => {
        if (isProviderLoaded && userInfo?.isAdmin) {
            if (isWorkspaceDetached || areCapacitiesRemoved) {
                setIsSwitchCapacityDialogOpen(true);
            }
        }
    }, [isWorkspaceDetached, isUserInfoFetched, areCapacitiesRemoved, userInfo, isProviderLoaded]);

    const navigate = useNavigate();

    useEffect(() => {
        if (isWorkspacesTestingEnabled && userInfo?.isAdmin && areWorkspacesRemoved) {
            navigate('/manage-workspaces?openCreateWorkspace=true');
        }
    }, [isWorkspacesTestingEnabled, navigate]);

    return (
        <CustomFluentProvider className={classes.root}>
            <LayoutContextProvider>
                <TourProviderWithContext pathname={pathname}>
                    <span data-testid="tour-controls" className={classes.controls}>
                        <Controls />
                    </span>
                    <NavigationContext.Provider
                        value={{
                            navActions,
                            setNavActions,
                            toggableActions,
                            setToggableActions: setNextActions,
                        }}
                    >
                        <SidePanelContext.Provider
                            value={{children: paneContent, setChildren: setPaneContent}}
                        >
                            <Background />
                            <nav className={classes.navigation}>
                                <Navigation data-testid="nav" />
                            </nav>
                            {!isExpired && (
                                <>
                                    <div
                                        className={mergeClasses(
                                            classes.mainWrapper,

                                            firstRun && classes.firstRunMainWrapper,
                                            isNavigationBannerVisible && classes.untrustedWrapper,
                                            isNavigationBannerVisible &&
                                                classes.untrustedMainWrapper,
                                        )}
                                        data-testid="main-wrapper"
                                    >
                                        {MedeinaFeatures.PrototypeRoutingExperience && (
                                            <PrototypeWarningBanner />
                                        )}
                                        <Content
                                            isFirstRun={firstRun}
                                            hasPaneContent={Boolean(paneContent)}
                                        >
                                            {children}
                                        </Content>
                                        {MedeinaFeatures.HelpResourcesExperience && (
                                            <div className={classes.helpMenu}>
                                                <AssistanceManager />
                                            </div>
                                        )}
                                        {sidePanel?.open && paneContent && (
                                            <>
                                                <ResizeBar />
                                                <SidePanel data-testid="sidepanel">
                                                    {paneContent}
                                                </SidePanel>
                                            </>
                                        )}
                                    </div>
                                    <Overlay />
                                    <TourPopovers surface={TourPopoverSurface.default} />
                                </>
                            )}
                        </SidePanelContext.Provider>
                    </NavigationContext.Provider>
                </TourProviderWithContext>
                {isProviderLoaded && expiryDateFetched && isEAPTenant && isExpired && (
                    <ExpiredCapacity
                        onSwitchOrCreateCapacityDialog={() => {
                            setIsSwitchCapacityDialogOpen(true);
                        }}
                    />
                )}

                <SwitchCapacity
                    isSwitchCapacityOpen={isSwitchCapacityDialogOpen}
                    onSwitchCapacityDialogClose={() => {
                        setIsSwitchCapacityDialogOpen(false);
                    }}
                />
            </LayoutContextProvider>
        </CustomFluentProvider>
    );
}
