import useClasses from './PromptbookPromptList.styles';
import {PromptbookPromptListProps} from './Promptbook.types';
import {Button, mergeClasses} from '@fluentui/react-components';
import PromptbookPrompt from './PromptbookPrompt';
import {PromptbookPrompts} from '@/api/promptbooks';
import {useState} from 'react';
import {AddIcon} from '@/components/ui/icons';
import {PromptType} from '@/api/prompts';
import {useTranslation} from 'react-i18next';
import {randomUUID} from '@azure/core-util';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export default function PromptbookPromptList(props: PromptbookPromptListProps) {
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const classes = useClasses();
    const {t} = useTranslation('promptbooks');
    const [promptDefns, setPromptDefns] = useState<PromptbookPrompts[]>(props.promptDefns ?? []);
    const [totalItems, setTotalItems] = useState<number>(promptDefns.length);

    const onPromptEdited = (index: number, promptDefn: PromptbookPrompts) => {
        // Update the state for prompts list here

        // If new prompts are added to the list.
        if (index > promptDefns.length) {
            promptDefns.splice(index, 0, promptDefn);
            setPromptDefns([...promptDefns]);
        } else {
            // Existing prompt is edited.
            promptDefns[index] = promptDefn;
            setPromptDefns([...promptDefns]);
        }

        props.onPromptDataChange?.(promptDefns);
    };

    const onPromptDeleted = (index: number) => {
        if (isDirectSkillInvocationEnabled) {
            const newPromptDefns = promptDefns.filter((_, i) => i !== index);
            setPromptDefns(newPromptDefns);
            props.onPromptDataChange?.(newPromptDefns);
        } else {
            promptDefns.splice(index, 1);
            setPromptDefns([...promptDefns]);
            props.onPromptDataChange?.(promptDefns);
        }
        setTotalItems(promptDefns.length);
    };

    const onPromptMovedUp = (index: number) => {
        if (index <= 0) {
            return;
        }
        const newPromptDefns = [...promptDefns];
        [[newPromptDefns[index], newPromptDefns[index - 1]]] = [
            [newPromptDefns[index - 1], newPromptDefns[index]],
        ];
        setPromptDefns([...newPromptDefns]);
        props.onPromptDataChange?.(newPromptDefns);
    };

    const onPromptMovedDown = (index: number) => {
        if (index >= promptDefns.length) {
            return;
        }
        const newPromptDefns = [...promptDefns];
        [[newPromptDefns[index + 1], newPromptDefns[index]]] = [
            [newPromptDefns[index], newPromptDefns[index + 1]],
        ];
        setPromptDefns([...newPromptDefns]);
        props.onPromptDataChange?.(newPromptDefns);
    };

    const handleAddPrompt = (event: {preventDefault: () => void; stopPropagation: () => void}) => {
        event.preventDefault();
        event.stopPropagation();

        // Add new prompts to the prompts array
        if (promptDefns) {
            setPromptDefns((currentPromptDefn) => {
                const newPromptDefns = [
                    ...currentPromptDefn,
                    {
                        ...(isDirectSkillInvocationEnabled && {
                            promptbookPromptId: randomUUID(),
                            editMode: true,
                        }),
                        promptType: PromptType.Prompt,
                    } as PromptbookPrompts,
                ];

                props.onPromptDataChange?.(newPromptDefns);

                return newPromptDefns;
            });
            setTotalItems(promptDefns.length + 1);
        }
    };

    return (
        <div className={mergeClasses(classes.flexContainerColumn, classes.promptList)} tabIndex={0}>
            {promptDefns &&
                promptDefns.map((promptDefn, index) => {
                    const props = {
                        promptDefn,
                        totalItems,
                        index,
                        onPromptEdited,
                        onPromptDeleted,
                        onPromptMovedDown,
                        onPromptMovedUp,
                    };
                    return (
                        <PromptbookPrompt
                            key={
                                isDirectSkillInvocationEnabled
                                    ? promptDefn.promptbookPromptId
                                    : index
                            }
                            {...props}
                        />
                    );
                })}
            <div>
                <Button
                    data-testid="add-prompt-button"
                    icon={<AddIcon />}
                    onClick={handleAddPrompt}
                    aria-label={t('AddAPrompt')}
                >
                    {t('AddPrompt')}
                </Button>
            </div>
        </div>
    );
}
