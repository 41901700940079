import {
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Link,
    Toast,
    ToastTitle,
    useToastController,
    useId,
    Toaster,
    useFocusFinders,
} from '@fluentui/react-components';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {WorkspaceFormProps} from './WorkspaceForm.types';
import useClasses from './WorkspaceForm.styles';
import CapacitySelectField from './CapacitySelect';
import StorageLocation from './StorageLocationField';
import WorkspaceName from './WorkspaceNameField';
import ModelImprovement from './ModelImprovementField';
import ProductImprovement from './ProductImprovementField';
import TermsConditions from './TermsConditionsField';
import {useTranslation} from 'react-i18next';
import {
    useGetWorkspaceByName,
    useGetWorkspaces,
    usePutWorkspace,
    Workspace,
} from '@/api/workspaces';
import {FormErrorMessageBar} from './FormErrorMessageBar';
import {UserProfile} from '@/api/user';
import RoleMembersSelect from './RoleMembersSelect';
import {useUpdateWorkspacePolicyById} from '@/api/rbac';
import {usePolicyManager} from '@/components/admin/rbac';
import {MemberType, PolicyWorkspace, RbacErrorResponse} from '@/api/rbac/rbac.types';
import {MemberListItemProps, RoleObjectIdData, RoleType} from '@/components/admin/rbac/rbac.types';
import useCheckPermission from '@/api/utils/useCheckArmPermissions';
import {DeleteIcon} from '@/components/ui/icons';
import {useMsalUserId} from '@/util/msal';
import {useUpdateWorkspace} from '@/api/workspaces';
import CreateCapacity from '@/components/CreateCapacity';
function CreateEdit(props: WorkspaceFormProps) {
    const {
        mode,
        onCancel,
        onClose,
        onDelete,
        workspace,
        hideCapacitySelectField: hideCapacitySelectField,
        onWorkspaceCreated,
    } = props;
    const classes = useClasses();
    const showDelete = mode === 'edit' && onDelete;
    const {t} = useTranslation();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const {mutate: updateWorkspace} = useUpdateWorkspace();

    // field state values
    const [workspaceName, setWorkspaceName] = useState(workspace?.name || '');
    const [dataStorageLocation, setDataStorageLocation] = useState('');
    const [capacity, setCapacity] = useState('');
    const [modelImprovement, setModelImprovement] = useState(true);
    const [productImprovement, setProductImprovement] = useState(true);
    const [termsConditions, setTermsConditions] = useState(false);
    const {data: workspaceData} = useGetWorkspaceByName(workspaceName, {enabled: mode === 'edit'});
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(false);

    const {mutate: createWorkspace, isLoading, isError, isSuccess} = usePutWorkspace();
    const handleCapacityCreated = (newCapacityName: string) => {
        setCapacity(newCapacityName);
        console.log('new capacity created: ', newCapacityName);
    };

    useEffect(() => {
        // if we are in edit mode, we should populate the fields with the workspace data
        if ((mode === 'edit' || mode === 'duplicate') && workspaceData) {
            console.log('prefilling data', workspaceData);
            // prefill the fields with the workspace data
            setCapacity(workspaceData.capacity?.referenceName || '');
            setDataStorageLocation(workspaceData?.dataStorageLocation || '');
            setModelImprovement(
                workspaceData?.workspaceOptInConfig?.isAllowModelImprovement === 'true'
                    ? true
                    : false,
            );
            setProductImprovement(
                workspaceData?.workspaceOptInConfig?.isAllowProductImprovement === 'true'
                    ? true
                    : false,
            );
            // setTermsConditions(workspaceData.termsConditions);
        }
    }, [mode, workspaceData]);

    // adding role members logic
    const {updateExistingPolicy} = usePolicyManager({
        workspacePolicy: {} as PolicyWorkspace,
        workspaceName: workspaceName as string,
    });
    const {mutateAsync: updateWorkspacePolicy} = useUpdateWorkspacePolicyById();
    const getUpdateRoleObjectIds = (
        existingMembers: RoleObjectIdData,
        addedMembers: MemberListItemProps[],
    ): RoleObjectIdData => {
        const existingMembersData: RoleObjectIdData = {...existingMembers};

        //Arranged role data
        for (const member of addedMembers) {
            const roleData =
                existingMembersData[member.role] || ({} as Record<MemberType, string[]>);

            const roleObjectIdData = roleData[member.type] || ([] as string[]);

            if (!roleObjectIdData.includes(member.memberId)) {
                roleObjectIdData.push(member.memberId);
            }
        }

        // Need to invoke API Mutation
        return existingMembersData;
    };

    const addRoleMembers = async (owners: UserProfile[], contributors: UserProfile[]) => {
        const mapUserProfileToMemberListItem = (
            user: UserProfile,
            role: string,
        ): MemberListItemProps => ({
            key: user.id,
            id: user.id,
            memberId: user.id,
            name: user.displayName,
            email: user.mail,
            type: MemberType.User,
            role: role as RoleType,
        });

        const ownersMapped = owners.map((owner) => mapUserProfileToMemberListItem(owner, 'Owner'));
        const contributorsMapped = contributors.map((contributor) =>
            mapUserProfileToMemberListItem(contributor, 'Contributor'),
        );

        const updatedMemberList = getUpdateRoleObjectIds({}, [
            ...ownersMapped,
            ...contributorsMapped,
        ]);
        const updatedPolicy = updateExistingPolicy(updatedMemberList, workspaceName as string);

        if (!!updatedPolicy) {
            try {
                // API call
                await updateWorkspacePolicy({
                    workspaceName: workspaceName as string,
                    policy: updatedPolicy,
                });
                // Handle success (e.g., show confirmation dialog)
            } catch (error) {
                const errorData = error as RbacErrorResponse;
                // Handle error (e.g., show error message)
            }
        }
    };
    const handleUpdate = useCallback(() => {
        console.log('Edit workspace');
        updateWorkspace(
            {
                name: workspaceName,
                capacity: {type: 'capacity', referenceName: capacity},
                dataStorageLocation,
                workspaceOptInConfig: {
                    isAllowModelImprovement: modelImprovement.toString(),
                    isAllowProductImprovement: productImprovement.toString(),
                },
            },
            {
                onSuccess: () => {
                    console.log('Workspace updated');
                    addRoleMembers(owners, contributors);
                    onClose?.();
                    setIsMessageBarVisible(false);
                },
                onError: (error) => {
                    console.error('Error updating workspace', error);
                    setIsMessageBarVisible(true);
                },
            },
        );
    }, [
        capacity,
        dataStorageLocation,
        modelImprovement,
        onClose,
        productImprovement,
        updateWorkspace,
    ]);

    const {data: workspacesData, error: workspaceError} = useGetWorkspaces();
    const handleCreate = useCallback(() => {
        console.log('Create workspace');
        // check if capacity selected is already in use
        const attachedWorkspace = workspacesData?.value.find(
            (workspace: Workspace) => workspace.capacity?.referenceName === capacity,
        );
        if (attachedWorkspace) {
            updateWorkspace(
                {
                    ...attachedWorkspace,
                    name: attachedWorkspace?.name || '',
                    capacity: null,
                },
                {
                    onSuccess: (data) => {
                        console.log(
                            'Successfully disconnected capacity ' +
                                capacity +
                                ' from ' +
                                attachedWorkspace?.name +
                                ' workspace',
                        );
                    },
                    onError: () => {
                        console.log(
                            'Error disconnecting capacity ' +
                                capacity +
                                ' from ' +
                                attachedWorkspace?.name +
                                ' workspace',
                        );
                    },
                },
            );
        }
        let workspace: Workspace;
        if (hideCapacitySelectField) {
            workspace = {
                name: workspaceName,
                // no capacity yet, will be assigned in create capacity form
                dataStorageLocation,
                workspaceOptInConfig: {
                    isAllowModelImprovement: modelImprovement.toString(),
                    isAllowProductImprovement: productImprovement.toString(),
                },
            };
        } else {
            workspace = {
                name: workspaceName,
                capacity: {type: 'capacity', referenceName: capacity},
                dataStorageLocation,
                workspaceOptInConfig: {
                    isAllowModelImprovement: modelImprovement.toString(),
                    isAllowProductImprovement: productImprovement.toString(),
                },
            };
        }
        // TODO: add check if capacity selected is already in use
        console.log({workspace});

        createWorkspace(workspace, {
            onSuccess: () => {
                console.log('Workspace created');
                console.log('workspace: ' + JSON.stringify(workspace));
                addRoleMembers(owners, contributors);
                notify();
                onClose?.();
                setIsMessageBarVisible(false);
            },
            onError: (error) => {
                console.error('Error creating workspace', error);
                setIsMessageBarVisible(true);
            },
        });
    }, [
        capacity,
        createWorkspace,
        dataStorageLocation,
        modelImprovement,
        onClose,
        productImprovement,
        workspaceName,
    ]);

    const handleFormSubmit = useCallback(
        (ev: React.FormEvent) => {
            // prevent the form from firing a submit event
            onWorkspaceCreated?.(workspaceName);
            console.log('workspace name passed back to create capacity: ', workspaceName);
            console.log('clicked');
            ev.preventDefault();
            console.log(ev);
            console.log('MODE: ' + mode);
            // add any additional validation checks here if needed before sending out any requests to the server
            // if we are in create mode, we should create the workspace
            if (mode === 'create') {
                console.log('CREATE WORKSPACE');
                handleCreate();
            } else if (mode === 'edit') {
                // if we are in edit mode, we should update the workspace
                console.log('EDIT workspace');
                handleUpdate();
            } else if (mode === 'duplicate') {
                // if we are in duplicate mode, we should duplicate the workspace
                console.log('Duplicate workspace');
                handleCreate();
            }
        },
        [handleCreate, mode],
    );

    const primaryButtonLabel =
        mode === 'create'
            ? tCommon('ButtonLabels.Create')
            : mode === 'edit'
            ? tCommon('ButtonLabels.Save')
            : tCommon('ButtonLabels.Duplicate');

    const [owners, setOwners] = useState<UserProfile[]>([]);
    const receiveOwnersFromPeoplePicker = (data: UserProfile[]) => {
        setOwners(data);
    };
    const [contributors, setContributors] = useState<UserProfile[]>([]);
    const receiveContributorsFromPeoplePicker = (data: UserProfile[]) => {
        setContributors(data);
    };
    // get the principal id
    const principalId = useMsalUserId();
    const [subscriptionId, setSubscriptionId] = useState<string>('');
    const [scopeResource, setScopeResource] = useState<string>('');
    const {hasPermission, error, loading} = useCheckPermission({
        subscriptionId: subscriptionId, // id from capacity
        scope: scopeResource, // entire path
        principalId: principalId || '',
        requiredRoles: ['Contributor', 'Owner'],
    });
    // create capacity
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);

    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);
    const notify = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{tAdmin('Workspaces.Form.WorkspaceCreated')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'success'},
        );
    };
    const newCapacityButtonRef = useRef<HTMLDivElement>(null);

    // Determine if the create button should be disabled
    const isCreateButtonDisabled =
        !workspaceName || !capacity || !termsConditions || !dataStorageLocation;

    return (
        <>
            <form>
                {isMessageBarVisible && <FormErrorMessageBar messageTitle={mode.toString()} />}
                <DialogContent className={classes.dialogContent}>
                    <WorkspaceName
                        value={workspaceName}
                        required={mode !== 'edit'}
                        preventDuplicate
                        disabled={mode === 'edit'}
                        onChange={(value) => setWorkspaceName(value)}
                    />
                    {!hideCapacitySelectField && (
                        <div ref={newCapacityButtonRef}>
                            <CapacitySelectField
                                value={capacity}
                                onOptionSelect={(capacity) => {
                                    setCapacity(capacity.name);
                                    console.log(capacity.name);
                                }}
                                required={true}
                                disabled={!hasPermission && mode === 'edit'}
                            />
                            <Link
                                className={classes.newCapacityLinkStyle}
                                onClick={() => {
                                    setIsCreateCapacityDialogOpen(true);
                                }}
                                disabled={!hasPermission && mode === 'edit'}
                            >
                                {tAdmin('Workspaces.Form.CreateNewCapacityLink')}
                            </Link>
                        </div>
                    )}
                    <StorageLocation
                        required={true}
                        value={dataStorageLocation}
                        onOptionSelect={(selectedLocation) =>
                            setDataStorageLocation(selectedLocation)
                        }
                    />
                    <RoleMembersSelect
                        className={classes.peoplepicker}
                        sendDataToParent={receiveOwnersFromPeoplePicker}
                        isOwnersSelect={true}
                    />
                    <RoleMembersSelect
                        className={classes.peoplepicker}
                        sendDataToParent={receiveContributorsFromPeoplePicker}
                        isOwnersSelect={false}
                    />

                    <ProductImprovement
                        value={productImprovement}
                        onChange={(checked) => setProductImprovement(checked)}
                    />
                    <ModelImprovement
                        value={modelImprovement}
                        onChange={(checked) => setModelImprovement(checked)}
                    />
                    <TermsConditions
                        value={termsConditions}
                        onChange={(checked) => setTermsConditions(checked)}
                        required
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    {showDelete && (
                        <Button
                            icon={<DeleteIcon />}
                            appearance="secondary"
                            onClick={onDelete}
                            className={classes.leftActions}
                        >
                            {tCommon('ButtonLabels.Delete')}
                        </Button>
                    )}
                    <div className={classes.rightActions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">{t('ButtonLabels.Cancel')}</Button>
                        </DialogTrigger>
                        <Button
                            appearance="primary"
                            onClick={handleFormSubmit}
                            disabled={isCreateButtonDisabled}
                        >
                            {primaryButtonLabel}
                        </Button>
                    </div>
                </DialogActions>
            </form>
            <CreateCapacity
                isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                onCreateCapacityDialogClose={() => {
                    setIsCreateCapacityDialogOpen(false);
                    //newCapacityButtonRef.current?.focus();
                }}
                hideWorkspaceSelectField={false}
                onCapacityCreated={handleCapacityCreated}
            />
            <Toaster toasterId={toasterId} />
        </>
    );
}

export default CreateEdit;
