import {useMemo, useEffect, useState, useRef} from 'react';
import {
    useTableSelection,
    useTableFeatures,
    Caption1,
    Subtitle2,
    mergeClasses,
} from '@fluentui/react-components';
import {Alert} from '@fluentui/react-components/unstable';
import {useGetPrompts, useCreatePrompt, useGetGroupedPrompts, GroupedPrompt} from '@/api/prompts';
import {SourceType, useGetSession} from '@/api/sessions';
import {PromptBar, ScreenName} from '@/components/ui';
import useClasses from './SessionPrompts.styles';
import {SessionProps} from './Session.types';
import {useCreateEvaluation} from '@/api/evaluations';
import {DeleteMode} from './SessionPrompts.types';
import MedeinaVariables from '@/util/variables';
import DailyTips from '@/components/ui/TipsCard/DailyTips';
import {useFeatureFlag, useUserState} from '@/api/user';
import useScrollStyles from '@/components/ui/util/MedeinaScrollbar.styles';
import PromptSuggestions from '@/components/ui/PromptSuggestions/PromptSuggestions';
import MedeinaFeatures from '@/util/features';
import {useLayoutRef, LayoutArea} from '@/components/ui/Layout';
import {useLayout} from '@/components/ui/Layout';
import {useApplyPromptbook} from '@/api/promptbooks';
import {useTourable} from '@/components/ui/Tour';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import {useErrorMessages, useErrorMessagesWorkspaces} from '@/api/errors';
import PromptWrapper from '../prompts/PromptWrapper';
import {useTranslation} from 'react-i18next';
import {ErrorMessage} from '@/api/errors.types';
import SessionToolbar from './SessionToolbar';
import useSessionViewMode from './useSessionViewMode';
import {DeletePromptDialog} from '@/components/sections/prompts';
import useCreatePromptAndEvaluation from '@/api/combined/useCreatePromptAndEvaluation';
import {useGetUserInfo} from '@/api/app';

export * from './Session.types';

//This is used for prompts associated with the session. The operations are Select, delete, rerun, pin and create promptbook
export default function SessionPrompts({sessionId, isReadOnly}: SessionProps) {
    // Setup app layout management
    const {sidePanel, contentArea} = useLayout();
    const {t} = useTranslation('session');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    const {getErrorMessage} = useErrorMessages();
    const {getErrorMessageWorkspaces} = useErrorMessagesWorkspaces();
    const {toggleOpen} = sidePanel || {toggleOpen: () => {}};
    const promptListTourRef = useTourable({id: 'promptList'});

    const mainAreaRef = useLayoutRef({area: LayoutArea.contentArea});
    // Instantiate styles
    const classes = useClasses();
    const scrollClasses = useScrollStyles();

    // Retrieve session data
    const [deleteMode, setDeleteMode] = useState<DeleteMode>(false);
    const [errMessage, setErrMessage] = useState<ErrorMessage | undefined>(undefined);
    const {data: session} = useGetSession({sessionId});
    const {data: prompts, refetch, isLoading: isGetPromptsLoading} = useGetPrompts({sessionId});
    const groupedPrompts: GroupedPrompt[] = useGetGroupedPrompts(prompts?.value);
    const {sm: isSmallScreen} = useViewportSize();

    const {data: userInfo, isSuccess: isUserInfoSuccess, error: isUserInfoError} = useGetUserInfo();
    const [useCombinedController, setUseCombinedController] = useState<boolean>(false);

    // Set mutation handlers
    const {mutate: createPrompt, isError, reset} = useCreatePrompt();
    const {
        mutate: createPromptAndEvaluation,
        isError: createPromptAndEvaluationError,
        reset: resetPromptAndEvaluation,
    } = useCreatePromptAndEvaluation();

    const {
        mutate: applyPromptbook,
        isLoading: promptbookLoading,
        isSuccess: promptbookApplySuccess,
        isError: applyPromptbookError,
    } = useApplyPromptbook();
    const {
        mutate: createEvaluation,
        isError: createEvalError,
        error: evalError,
    } = useCreateEvaluation();

    // Check if daily tip needs to be shown.
    // Show dailyTip only if it's a **new investigation** started by a new/inactive user.
    const {showDailyTip, toggleDailyTip} = useUserState();
    // Save in local state to not hamper UI.
    const [showTip] = useState(showDailyTip);
    // Reset to false after showing once
    useEffect(() => {
        toggleDailyTip(false);
    }, []);

    useEffect(() => {
        if (userInfo && userInfo.featureFlags?.includes('EnableCombinedController')) {
            setUseCombinedController(true);
        }
    }, [userInfo]);

    useEffect(() => {
        if (createEvalError) {
            if (isWorkspacesTestingEnabled) {
                var message = getErrorMessageWorkspaces(
                    (evalError as any).response.status as number,
                );
                setErrMessage(message);
            } else {
                var message = getErrorMessage((evalError as any).response.status as number);
                setErrMessage(message);
            }
        }
    }, [createEvalError]);

    // Prompt workflows.
    const {selection} = useTableFeatures(
        {columns: [], items: prompts?.value ?? [], getRowId: (prompt) => prompt.promptId},
        [
            useTableSelection({
                selectionMode: 'multiselect',
                defaultSelectedItems: new Set<string>([]),
            }),
        ],
    );

    // Cache the selected rows.
    const promptIds = useMemo<string[]>(
        () => Array.from(selection.selectedRows) as string[],
        [selection.selectedRows],
    );

    // Set suggestions as default text on prompt input bar
    const [promptValue, setPromptValue] = useState<string>('');
    const setPromptInputValue = (text: string) => {
        setPromptValue(text);
    };
    const latestPrompt = prompts?.value[prompts.value.length - 1];
    // When the sidepanel is open we use a dynamic width to determine the width of the main content area
    const dynamicStyles = sidePanel.open ? {style: {width: `${100 - sidePanel.width}%`}} : {};

    // get a total count of prompts
    const promptCount = prompts?.value?.length ?? 0;
    const prevPromptCountRef = useRef<number>(promptCount);

    // when promptCount increases, scroll to the bottom of the prompt list
    useEffect(() => {
        if (promptCount > prevPromptCountRef.current) {
            contentArea.scroll?.toBottom?.();
        }
        prevPromptCountRef.current = promptCount;
        // only trigger update when promptCount changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promptCount, isGetPromptsLoading]);

    const {isFullWidth: contentModeFullWidth} = useSessionViewMode();

    return (
        <>
            <div className={classes.root} data-testid="investigation-root" {...dynamicStyles}>
                {(!isSmallScreen || selection.someRowsSelected) && (
                    <SessionToolbar
                        selection={selection}
                        isReadOnly={isReadOnly}
                        sessionId={sessionId}
                        onSetDeleteMode={setDeleteMode}
                    />
                )}
                <div
                    ref={mainAreaRef}
                    data-testid="investigation-scrollable-container"
                    className={mergeClasses(
                        classes.scrollableContainer,
                        scrollClasses.colorNeutralBackground2,
                    )}
                >
                    <div className={classes.promptsContainer} ref={promptListTourRef}>
                        {showDailyTip && <DailyTips />}
                        {groupedPrompts?.map((groupedPrompt) => (
                            <PromptWrapper
                                key={groupedPrompt.prompts[0].promptId}
                                groupedPromptType={groupedPrompt.type}
                                sessionId={sessionId}
                                id={
                                    groupedPrompt.prompts[0].promptbookExecutionId
                                        ? groupedPrompt.prompts[0].promptbookExecutionId
                                        : groupedPrompt.prompts[0].promptId
                                }
                                selection={selection}
                                isReadOnly={isReadOnly}
                                errorMessage={errMessage}
                            />
                        ))}
                        {MedeinaFeatures.PromptSuggestions && latestPrompt?.latestEvaluationId && (
                            <PromptSuggestions
                                sessionId={sessionId}
                                promptId={latestPrompt.promptId}
                                evaluationId={latestPrompt.latestEvaluationId}
                                setPromptInputValue={setPromptInputValue}
                                onSubmit={(prompt) => {
                                    if (!prompt.promptbookId) {
                                        createPrompt(
                                            {
                                                sessionId,
                                                source: SourceType.Immersive,
                                                ...prompt,
                                            },
                                            {
                                                onSuccess: (prompt) => {
                                                    createEvaluation(
                                                        {
                                                            sessionId,
                                                            promptId: prompt.promptId,
                                                        },
                                                        {
                                                            onSuccess: () => {
                                                                refetch();
                                                                contentArea.scroll?.toBottom?.(); // scrolling to latest prompt at bottom of screen.
                                                            },
                                                        },
                                                    );
                                                },
                                            },
                                        );
                                    }
                                }}
                            />
                        )}
                        <div className={classes.interactionsAnchor}>&nbsp;</div>
                    </div>
                </div>
                <div className={classes.promptBarSection} data-testid="promptbar-area">
                    {!isReadOnly &&
                        ((prompts?.value?.length ?? 0) < MedeinaVariables.MaxPromptsPerSession ? (
                            <div
                                className={
                                    contentModeFullWidth
                                        ? classes.promptSectionWrapperFullWidth
                                        : ''
                                }
                                data-testid="promptbar-sectionWrapper"
                            >
                                <PromptBar
                                    key={`${sessionId},${prompts?.value?.length ?? 0}`}
                                    defaultSkillsets={session?.skillsets}
                                    enableSkillsetConnector
                                    enableSupportAssistance
                                    screenName={ScreenName.Session}
                                    onSubmit={(prompt) => {
                                        if (!prompt.promptbookId) {
                                            if (useCombinedController) {
                                                createPromptAndEvaluation(
                                                    {
                                                        sessionId,
                                                        source: SourceType.Immersive,
                                                        ...prompt,
                                                    },
                                                    {
                                                        onSuccess: () => {
                                                            refetch();
                                                            contentArea.scroll?.toBottom?.(); // scrolling to latest prompt at bottom of screen.
                                                        },
                                                    },
                                                );
                                            } else {
                                                createPrompt(
                                                    {
                                                        sessionId,
                                                        source: SourceType.Immersive,
                                                        ...prompt,
                                                    },
                                                    {
                                                        onSuccess: (prompt) => {
                                                            createEvaluation(
                                                                {
                                                                    sessionId,
                                                                    promptId: prompt.promptId,
                                                                },
                                                                {
                                                                    onSuccess: () => {
                                                                        refetch();
                                                                        contentArea.scroll?.toBottom?.(); // scrolling to latest prompt at bottom of screen.
                                                                    },
                                                                },
                                                            );
                                                        },
                                                    },
                                                );
                                            }
                                        } else {
                                            // if it is promptbook
                                            // creating the body for the apply promptbook request
                                            const promptbookBody = {
                                                content: prompt.content,
                                                promptbookId: prompt.promptbookId,
                                                inputs: prompt.inputs,
                                                promptbookPrompts: prompt.promptbookPrompts,
                                                skillInputParameters: prompt.skillInputParameters,
                                            };
                                            applyPromptbook(
                                                {
                                                    sessionId: sessionId,
                                                    promptbookId: promptbookBody?.promptbookId,
                                                    inputs: promptbookBody?.inputs,
                                                    overriddenPrompts:
                                                        promptbookBody?.promptbookPrompts,
                                                    source: SourceType.Immersive,
                                                    skillInputParameters:
                                                        promptbookBody?.skillInputParameters,
                                                },
                                                {
                                                    onSuccess: () => {
                                                        refetch();
                                                        setPromptInputValue('');
                                                    },
                                                },
                                            );
                                        }
                                    }}
                                    defaultValue={promptValue}
                                    fluid={contentModeFullWidth}
                                />
                                {isError && (
                                    <Alert
                                        className={classes.alert}
                                        intent="error"
                                        action={{
                                            children: t('DismissText'),
                                            onClick: reset,
                                        }}
                                    >
                                        {t('PromptBarError')}
                                    </Alert>
                                )}
                            </div>
                        ) : (
                            <Subtitle2 className={classes.aiCaption}>
                                {t('MaxPromptsReached')}
                                <br />
                                {t('PleaseDeleteOrEditPrompt')}
                            </Subtitle2>
                        ))}
                    {!isSmallScreen && (
                        <Caption1 className={classes.aiCaption}>
                            {t('AIGeneratedContentWarning')}
                        </Caption1>
                    )}
                </div>
                {promptbookLoading && (
                    <ToastNotification
                        intent="progress"
                        message={t('StartingPromptbook')}
                        sessionId={''}
                        timeout={10000}
                    ></ToastNotification>
                )}
                {promptbookApplySuccess && (
                    <ToastNotification
                        intent="success"
                        message={t('PromptbookApplySuccess')}
                        sessionId={''}
                        timeout={10000}
                    ></ToastNotification>
                )}
                {applyPromptbookError && (
                    <ToastNotification
                        intent="error"
                        message={t('ErrorRunningPromptbook')}
                        sessionId={''}
                        timeout={10000}
                    ></ToastNotification>
                )}
                <DeletePromptDialog
                    {...{sessionId, promptIds}}
                    open={deleteMode === 'Rerun'}
                    onClose={() => {
                        setDeleteMode(false);
                    }}
                    onSuccess={() => {
                        // useTableSelection doesn't update selection automatically when `items` changes, so manually deselect removed prompts.
                        // @ts-ignore
                        promptIds.forEach((promptId) =>
                            // @ts-ignore
                            selection.deselectRow(null, promptId),
                        );
                    }}
                />
            </div>
        </>
    );
}
