import React, {useEffect, useMemo, useState} from 'react';
import {
    Menu,
    MenuButton,
    MenuItemCheckbox,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useClasses from './ExploreHomeLibrary.styles';
import {FilterButton, FilterButtonRow} from '@/components/ui/Buttons/FilterButton';
import ExplorePromptbooksLibrary from './ExplorePromptbooksLibrary';
import {
    Toolbar,
    Input,
    Button,
    ToolbarDivider,
    mergeClasses,
    Tooltip,
} from '@fluentui/react-components';
import {AnimatePresence, motion} from 'framer-motion';
import {DismissIcon, SearchIcon} from '@/components/ui/icons';
import ExplorePromptLibrary from './ExplorePromptLibrary';
import useGetPersona from '@/api/prompts/useGetPersona';
import useGetPersonaBasedSkillsets from '@/api/skills/useGetPersonaBasedSkillsets';
import {useGetSkillsets} from '@/api/skills';
import {PromptSuggestionDescriptor} from '@/api/prompts/suggestedPrompts.types';
import {TextH1} from '@/components/ui/Text';

const enum AvailableComponents {
    Prompts = 'prompts',
    Promptbooks = 'promptbooks',
}

interface ExploreHomeLibraryProps {
    handleSuggestedPromptClick: (prompt: PromptSuggestionDescriptor) => void;
}

function ExploreHomeLibrary({handleSuggestedPromptClick}: ExploreHomeLibraryProps) {
    const {t} = useTranslation('common');

    const classes = useClasses();

    const {data: personaValues, isLoading} = useGetPersona();
    const {data: skillsets, isLoading: skillsetLoading} = useGetSkillsets();
    const {data: personaBasedSkillsets, isLoading: personaBasedSkillsetLoading} =
        useGetPersonaBasedSkillsets();

    const [selectedComponent, setSelectedComponent] = useState(AvailableComponents.Prompts);
    const [expandedSearchBar, setExpandedSearchBar] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [selectedPersonaItems, setSelectedPersonaItems] = useState<Record<string, string[]>>({});
    const [selectedPluginItems, setSelectedPluginItems] = useState<Record<string, string[]>>({});
    const [selectedPlugins, setSelectedPlugins] = useState<string[]>();
    const [selectedPersona, setSelectedPersona] = useState<string[]>();
    const [selectedPersonaDisplayNames, setSelectedPersonaDisplayNames] = useState<string[]>();
    const [filterablePromptPlugins, setFilterablePromptPlugins] = useState<string[]>([]);
    const [filterablePromptBookPlugins, setFilterablePromptBookPlugins] = useState<string[]>([]);
    const [availablePlugins, setAvailablePlugins] = useState<string[]>([]);

    const handleCheckedValueChange = (
        e: any,
        {name, checkedItems}: {name: string; checkedItems: string[]},
    ) => {
        if (checkedItems.length > 0) {
            setSelectedPersonaItems({
                ...selectedPersonaItems,
                [name]: [checkedItems[0]], // Only keep the first item
            });
        } else {
            const {[name]: _, ...rest} = selectedPersonaItems; // Remove the key if no items are checked
            setSelectedPersonaItems(rest);
        }
    };

    const handleCheckedPluginChange = (
        e: any,
        {name, checkedItems}: {name: string; checkedItems: string[]},
    ) => {
        if (checkedItems.length > 0) {
            setSelectedPluginItems({
                ...selectedPluginItems,
                [name]: checkedItems,
            });
        } else {
            const {[name]: _, ...rest} = selectedPluginItems;
            setSelectedPluginItems(rest);
        }
    };

    useEffect(() => {
        const selectedPersona = Object.values(selectedPersonaItems).flat();
        const selectedPersonaNames = Object.keys(selectedPersonaItems).flat();
        setSelectedPersona(selectedPersona);
        setSelectedPersonaDisplayNames(selectedPersonaNames);
    }, [selectedPersonaItems]);

    useEffect(() => {
        const selectedPlugins = Object.values(selectedPluginItems).flat();
        setSelectedPlugins(selectedPlugins);
    }, [selectedPluginItems]);

    const personaTooltipContent = useMemo(() => {
        return (
            t('homeLibrary.roleFilter') +
            (Object.keys(selectedPersonaItems).length
                ? ' : ' + Object.getOwnPropertyNames(selectedPersonaItems).flat().join(', ')
                : '')
        );
    }, [selectedPersonaItems, t]);

    const pluginTooltipContent = useMemo(() => {
        return (
            t('homeLibrary.pluginFilter') +
            (Object.keys(selectedPluginItems).length > 0
                ? ' : ' + Object.values(selectedPluginItems).flat().join(', ')
                : '')
        );
    }, [selectedPluginItems, t]);

    useEffect(() => {
        if (personaBasedSkillsets) {
            setFilterablePromptPlugins(personaBasedSkillsets);
        }
    }, [personaBasedSkillsets]);

    useEffect(() => {
        if (filterablePromptPlugins || filterablePromptBookPlugins) {
            const uniquePlugins = Array.from(
                new Set([...filterablePromptPlugins, ...filterablePromptBookPlugins]),
            );
            setAvailablePlugins(uniquePlugins);
        }
    }, [filterablePromptPlugins, filterablePromptBookPlugins]);

    const showPrompts = () => setSelectedComponent(AvailableComponents.Prompts);
    const showPromptbooks = () => setSelectedComponent(AvailableComponents.Promptbooks);

    const handleSetExpandedSearchBar = () => setExpandedSearchBar(!expandedSearchBar);
    const handleSearchTextChange = (value: string) => setSearchInput(value);

    const handleAvailablePBPlugins = (plugins: string[]) => {
        setFilterablePromptBookPlugins(plugins);
    };

    const memoizedFilteredSkillsets = useMemo(() => {
        return (
            skillsets?.value
                ?.filter((item) => availablePlugins.includes(item.name))
                .map((item) => ({
                    name: item.name,
                    displayName: item.displayName ?? item.name,
                })) || []
        );
    }, [skillsets, availablePlugins]);

    const memoizedAvailablePluginsNotInSkillsets = useMemo(() => {
        return availablePlugins
            .filter((plugin) => !skillsets?.value?.some((skillset) => skillset.name === plugin))
            .map((plugin) => ({
                name: plugin,
                displayName: plugin,
            }));
    }, [availablePlugins, skillsets]);

    const sortedCombinedList = useMemo(() => {
        return [...memoizedFilteredSkillsets, ...memoizedAvailablePluginsNotInSkillsets].sort(
            (a, b) => a.displayName.localeCompare(b.displayName),
        );
    }, [memoizedFilteredSkillsets, memoizedAvailablePluginsNotInSkillsets]);

    return (
        <div>
            <div className={classes.title} data-testid="explore-copilot-title">
                <TextH1>{t('homeLibrary.getStartedLibraryTitle')}</TextH1>
            </div>

            <div className={classes.stickyActions}>
                <Toolbar className={classes.toolbar} size="small">
                    <AnimatePresence mode="wait" key="searchbar">
                        {!expandedSearchBar && (
                            <Tooltip
                                content={
                                    selectedComponent === AvailableComponents.Prompts
                                        ? t('homeLibrary.searchPrompts')
                                        : t('homeLibrary.searchPromptbooks')
                                }
                                relationship="label"
                            >
                                <Button
                                    onClick={handleSetExpandedSearchBar}
                                    className={classes.btnSearchStart}
                                    icon={<SearchIcon />}
                                    shape="circular"
                                    data-testid="Search"
                                />
                            </Tooltip>
                        )}
                        {expandedSearchBar && (
                            <motion.div
                                key="searchBar"
                                initial={{opacity: 0.5, width: 0}}
                                animate={{opacity: 1, width: 324}}
                                transition={{duration: 0.3, ease: 'backOut'}}
                                exit={{
                                    opacity: 0,
                                    width: 20,
                                    transition: {duration: 0.3, ease: 'backOut'},
                                }}
                            >
                                <Input
                                    className={classes.searchbar}
                                    id="search"
                                    type="text"
                                    autoFocus={true}
                                    placeholder={
                                        selectedComponent === AvailableComponents.Prompts
                                            ? t('homeLibrary.searchPrompts')
                                            : t('homeLibrary.searchPromptbooks')
                                    }
                                    onChange={(event: any, textbox: any) => {
                                        handleSearchTextChange(textbox.value);
                                    }}
                                    contentBefore={<SearchIcon />}
                                    appearance="underline"
                                    contentAfter={
                                        <Tooltip content={t('ClearSearch')} relationship="label">
                                            <Button
                                                onClick={() => {
                                                    handleSearchTextChange('');
                                                    handleSetExpandedSearchBar();
                                                }}
                                                className={classes.subButton}
                                                size="small"
                                                shape="circular"
                                                appearance="outline"
                                                icon={<DismissIcon />}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <FilterButtonRow>
                        <div className={classes.filterSection}>
                            <ToolbarDivider className={classes.customDivider} />
                            <FilterButton
                                size="medium"
                                shape="circular"
                                className={mergeClasses(
                                    classes.filterButton,
                                    selectedComponent == AvailableComponents.Prompts
                                        ? classes.filterChecked
                                        : false,
                                )}
                                onClick={showPrompts}
                                checked={selectedComponent == AvailableComponents.Prompts}
                            >
                                {t('homeLibrary.promptsButton')}
                            </FilterButton>

                            <Tooltip
                                content={t('homeLibrary.promptbooksButtonLabel')}
                                relationship="label"
                            >
                                <FilterButton
                                    size="medium"
                                    shape="circular"
                                    className={mergeClasses(
                                        classes.filterButton,
                                        selectedComponent == AvailableComponents.Promptbooks
                                            ? classes.filterChecked
                                            : false,
                                    )}
                                    onClick={showPromptbooks}
                                    checked={selectedComponent == AvailableComponents.Promptbooks}
                                >
                                    {t('homeLibrary.promptbooksButton')}
                                </FilterButton>
                            </Tooltip>
                        </div>
                        <div className={classes.filterSection}>
                            <ToolbarDivider className={classes.customDivider} />
                            <Menu>
                                <MenuTrigger>
                                    <Tooltip content={personaTooltipContent} relationship="label">
                                        <MenuButton
                                            className={mergeClasses(
                                                classes.menuButton,
                                                Object.keys(selectedPersonaItems).length > 0
                                                    ? classes.filterChecked
                                                    : false,
                                            )}
                                            size="medium"
                                            shape="circular"
                                        >
                                            {personaTooltipContent}
                                        </MenuButton>
                                    </Tooltip>
                                </MenuTrigger>

                                <MenuPopover className={classes.scrollableFilterContainer}>
                                    <MenuList
                                        checkedValues={selectedPersonaItems}
                                        onCheckedValueChange={handleCheckedValueChange}
                                    >
                                        {personaValues?.personaList.map((item, index) => (
                                            <MenuItemCheckbox
                                                name={item.name}
                                                value={item.id}
                                                key={item.id}
                                                className={classes.menuItemCheckbox}
                                            >
                                                {item.name}
                                            </MenuItemCheckbox>
                                        ))}
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Tooltip content={pluginTooltipContent} relationship="label">
                                        <MenuButton
                                            className={mergeClasses(
                                                classes.menuButton,
                                                Object.keys(selectedPluginItems).length > 0
                                                    ? classes.filterChecked
                                                    : false,
                                            )}
                                            size="medium"
                                            shape="circular"
                                        >
                                            {pluginTooltipContent}
                                        </MenuButton>
                                    </Tooltip>
                                </MenuTrigger>

                                <MenuPopover
                                    className={mergeClasses(classes.scrollableFilterContainer)}
                                >
                                    <MenuList
                                        checkedValues={selectedPluginItems}
                                        onCheckedValueChange={handleCheckedPluginChange}
                                    >
                                        {sortedCombinedList.map((item, index) => (
                                            <MenuItemCheckbox
                                                name={item.name}
                                                value={item.name}
                                                key={item.name}
                                                content={item.displayName}
                                                className={classes.menuItemCheckbox}
                                            />
                                        ))}
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </div>
                    </FilterButtonRow>
                </Toolbar>
            </div>

            <div className={classes.libraryContainer}>
                {selectedComponent === AvailableComponents.Prompts ? (
                    <ExplorePromptLibrary
                        className="promptLibraryContainer"
                        filter=""
                        persona={selectedPersona}
                        plugins={selectedPlugins}
                        search={searchInput}
                        handleSuggestedPromptClick={handleSuggestedPromptClick}
                    />
                ) : (
                    <ExplorePromptbooksLibrary
                        className="promptbookLibraryContainer"
                        filter=""
                        search={searchInput}
                        plugins={selectedPlugins}
                        persona={selectedPersonaDisplayNames}
                        handleAvailablePlugins={handleAvailablePBPlugins}
                    />
                )}
            </div>
        </div>
    );
}

export default ExploreHomeLibrary;
