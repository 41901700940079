import {NavigationBarHeight} from '@/components/App.styles';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.margin(0, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${NavigationBarHeight}px - ${tokens.spacingVerticalXS})`,
        justifyContent: 'flex-end',
        width: '700px',
    },
    agentRoot: {
        ...shorthands.margin(0, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${NavigationBarHeight}px - ${tokens.spacingVerticalXS})`,
        justifyContent: 'flex-end',
        alignContent: 'center',
        width: '1000px',
    },
    page: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    aiCaption: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        ...shorthands.padding(tokens.spacingHorizontalL),
        color: tokens.colorNeutralForeground2,
        maxWidth: '700px',
    },
    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
    },
    skillFormWrapper: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
    },
    skillsetEditor: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '70vh',
        // maxWidth: '50vw',
        marginBottom: '10vh',
    },
});
