import {useEffect, useState} from 'react';
import useClasses from './ManageWorkspaces.styles';
import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridCellFocusMode,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    TableColumnDefinition,
    TableColumnId,
    TableRowId,
    createTableColumn,
    TableColumnSizingOptions,
    mergeClasses,
    Dialog,
} from '@fluentui/react-components';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {DataGridSortIcon, AddIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {useGetUserInfo} from '@/api/app';
import {useLocation} from 'react-router-dom';

import {CapacityWarningMessageBar} from './CapacityWarningMessageBar';
import {WorkspaceWarningMessageBar} from './WorkspaceWarningMessageBar';
import WorkspaceForm from '@/components/sections/workspaces/form/WorkspaceForm';
import {WorkspaceColumn} from './TableColumns/WorkspaceColumn';
import {CapacityColumn} from './TableColumns/CapacityColumn';
import {LastUpdatedColumn} from './TableColumns/LastUpdatedColumn';
import {CreatedColumn} from './TableColumns/CreatedColumn';
import MembersColumn from './TableColumns/MembersColumn';
import {Capacity, useGetCapacities} from '@/api/capacities';

const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
    switch (columnId) {
        case 'name':
        case 'capacity':
        case 'lastupdated':
        case 'created':
        case 'owners':
        case 'contributors':
        default:
            return 'cell';
    }
};

export default function ManageWorkspaces() {
    const classes = useClasses();
    const {data: user} = useGetUserInfo();
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
    const {t} = useTranslation('admin');
    const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>(undefined));
    const onSelectionChange: DataGridProps['onSelectionChange'] = (e: any, data: any) => {
        setSelectedRows(data.selectedItems);
    };
    const {data: workspacesData, isFetched: isWorkspacesDataFetched} = useGetWorkspaces();
    const {data} = useGetCapacities();
    const capacityItems = data?.value || [];
    let availableCapacities: Capacity[] = [];
    let availableWorkspaces: Workspace[] = [];
    const [disconnectedWorkspaces, setDisconnectedWorkspaces] = useState<Workspace[]>([]);
    const [disconnectedCapacities, setDisconnectedCapacities] = useState<Capacity[]>([]);

    useEffect(() => {
        if (workspacesData?.value) {
            availableWorkspaces = workspacesData.value.filter((workspace) => !workspace.capacity);
            setDisconnectedWorkspaces(availableWorkspaces);
        }
    }, [workspacesData]);

    useEffect(() => {
        if (workspacesData?.value) {
            availableCapacities = capacityItems.filter(
                (capacity) =>
                    !workspacesData.value.find(
                        (workspace) => workspace.capacity?.referenceName === capacity.name,
                    ),
            );
            setDisconnectedCapacities(availableCapacities);
        }
    }, [workspacesData, capacityItems]);

    const [ownedWorkspaces, setOwnedWorkspaces] = useState<Workspace[]>([]);
    const handleOnOwnerDataProcessed = (isUserOwner: boolean, workspace: Workspace) => {
        if (isUserOwner) {
            setOwnedWorkspaces((prev) => [...prev, workspace]);
        }
    };

    const columnSizingOptions: TableColumnSizingOptions = {
        name: {idealWidth: 287},
        capacity: {idealWidth: 208, minWidth: 150},
        lastupdated: {idealWidth: 200, minWidth: 150},
        created: {idealWidth: 200, minWidth: 150},
        owners: {idealWidth: 200},
        contributors: {idealWidth: 300},
    };

    const columns: TableColumnDefinition<Workspace>[] = [
        createTableColumn<Workspace>({
            columnId: 'name',
            renderHeaderCell: () => {
                return (
                    <DataGridHeaderCell sortIcon={<DataGridSortIcon />}>
                        {t('ManageWorkspaces.TableHeaders.Name')}
                    </DataGridHeaderCell>
                );
            },
            renderCell: (workspace) => {
                return <WorkspaceColumn workspace={workspace} />;
            },
        }),
        createTableColumn<Workspace>({
            columnId: 'capacity',
            renderHeaderCell: () => {
                return (
                    <DataGridHeaderCell>
                        {t('ManageWorkspaces.TableHeaders.Capacity')}
                    </DataGridHeaderCell>
                );
            },
            renderCell: (workspace) => {
                return (
                    <CapacityColumn
                        workspace={workspace}
                        isWorkspacesDataFetched={isWorkspacesDataFetched}
                        workspaces={workspacesData?.value ?? []}
                        disconnectedWorkspaces={disconnectedWorkspaces}
                        setDisconnectedWorkspaces={setDisconnectedWorkspaces}
                        disconnectedCapacities={disconnectedCapacities}
                        setDisconnectedCapacities={setDisconnectedCapacities}
                    />
                );
            },
        }),
        createTableColumn<Workspace>({
            columnId: 'lastupdated',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.LastUpdated'),
            renderCell: (workspace) => <LastUpdatedColumn workspace={workspace} />,
        }),
        createTableColumn<Workspace>({
            columnId: 'created',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Created'),
            renderCell: (workspace) => <CreatedColumn workspace={workspace} />,
        }),
        createTableColumn<Workspace>({
            columnId: 'owners',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Owners'),
            renderCell: (workspace) => (
                <MembersColumn
                    workspace={workspace}
                    getOwnerMembers={true}
                    onOwnerDataProcessed={handleOnOwnerDataProcessed}
                />
            ),
        }),
        createTableColumn<Workspace>({
            columnId: 'contributors',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Contributors'),
            renderCell: (workspace) => (
                <MembersColumn
                    workspace={workspace}
                    getOwnerMembers={false}
                    onOwnerDataProcessed={handleOnOwnerDataProcessed}
                />
            ),
        }),
    ];

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('openCreateWorkspace') === 'true') {
            setWorkspaceDialogOpen(true);
        }
    }, [location.search]);

    return (
        <>
            {user?.isAdmin && (
                <div className={classes.root} data-testid="workspace-list-page">
                    <h1 className={classes.title}>{t('ManageWorkspaces.PageHeading')}</h1>
                    <div className={classes.newWorkspaceButton}>
                        <Button
                            icon={<AddIcon />}
                            appearance="secondary"
                            data-testid="new-workspace-button"
                            className={classes.newWorkspaceButton}
                            onClick={() => setWorkspaceDialogOpen(true)}
                        >
                            {t('ManageWorkspaces.NewWorkspaceButton')}
                        </Button>
                    </div>
                    <div className={classes.infoContainer}>{t('ManageWorkspaces.InfoContent')}</div>

                    {disconnectedWorkspaces.map((workspace) => (
                        <WorkspaceWarningMessageBar
                            key={workspace.name}
                            workspaceName={workspace.name}
                        />
                    ))}
                    {disconnectedCapacities.map((capacity) => (
                        <CapacityWarningMessageBar
                            key={capacity.name}
                            capacityName={capacity.name}
                        />
                    ))}

                    <DataGrid
                        items={workspacesData?.value ?? []}
                        columns={columns}
                        columnSizingOptions={columnSizingOptions}
                        resizableColumns
                        selectionAppearance="neutral"
                        onSelectionChange={onSelectionChange}
                        selectedItems={selectedRows}
                        getRowId={(workspace: {workspaceId: any}) => workspace.workspaceId}
                        className={classes.gridCell}
                        data-testid="workspace-library-grid"
                    >
                        <DataGridHeader>
                            <DataGridRow>
                                {({renderHeaderCell}: any) => (
                                    <DataGridHeaderCell
                                        className={mergeClasses(classes.headerCell, classes.row)}
                                    >
                                        {renderHeaderCell()}
                                    </DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<Workspace>>
                            {({item, rowId}: any) => (
                                <DataGridRow<Workspace> key={rowId}>
                                    {({renderCell, columnId}: any) => (
                                        <DataGridCell
                                            className={classes.row}
                                            focusMode={getCellFocusMode(columnId)}
                                        >
                                            {renderCell(item)}
                                        </DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                    <Dialog
                        open={workspaceDialogOpen}
                        onOpenChange={(event, data) => setWorkspaceDialogOpen(data.open)}
                    >
                        <WorkspaceForm
                            mode="create"
                            onClose={() => setWorkspaceDialogOpen(false)}
                        />
                    </Dialog>
                </div>
            )}
        </>
    );
}
