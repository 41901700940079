import {Button, Link, Switch} from '@fluentui/react-components';
import MedeinaFeatures from '@/util/features';
import useClasses from './WorkspaceSwitchingPage.styles';
import {Spinner, useFocusFinders} from '@fluentui/react-components';
import {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useGetTenantInfo} from '@/api/tenant';
import React from 'react';

interface WorkspaceSwitchingPageProps {
    workspaceName: string;
}

export default function WorkspaceSwitchingPage({workspaceName}: WorkspaceSwitchingPageProps) {
    const classes = useClasses();
    const {t} = useTranslation('common');
    console.log('WorkspaceSwitchingPage');

    return (
        <div className={classes.root}>
            <Spinner
                labelPosition="below"
                size="extra-large"
                label={t('WorkspaceSwitchingLabel', {0: workspaceName})}
                tabIndex={0}
            />
        </div>
    );
}
