import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    dialogSurface: {
        ...shorthands.padding(tokens.spacingHorizontalXXL),

        '@media (max-width: 479px)': {
            ...shorthands.padding(tokens.spacingHorizontalL),
        },
    },
    tabbedDialogBody: {
        display: 'grid',
        gridTemplateColumns: 'auto 3fr',
        height: `calc(100% - ${tokens.spacingHorizontalXL})`,
        ...shorthands.overflow('hidden'),
        overflowY: 'auto',
        // ...shorthands.margin(tokens.spacingVerticalSNudge),
        '@media (max-width: 479px)': {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
    },
    tabbedDialogBodyHorizontal: {
        display: 'grid',
        height: `calc(100% - ${tokens.spacingHorizontalXL})`,
        ...shorthands.overflow('hidden'),
        '@media (max-width: 479px)': {
            display: 'grid',
        },
    },
    tabbedContentHorizontal: {
        minHeight: '29px',
        ...shorthands.overflow('auto'),
        boxSizing: 'border-box',
        // this should be in the last 3 columns of the grid
        gridColumnStart: 1,
        gridColumnEnd: 4,
        gridRowStart: 2,
        // Include small padding to prevent the outline of input elements from being cut off
        ...shorthands.padding(tokens.spacingHorizontalXXS),
    },
    dialogBody: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        height: `calc(100% - ${tokens.spacingHorizontalXL})`,
        // ...shorthands.margin(tokens.spacingVerticalSNudge),

        '@media (max-width: 479px)': {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
    },
    tabList: {
        overflowY: 'auto', // Enables vertical scrolling
        maxHeight: '-webkit-fill-available', // Sets the max height to 100% of its parent
        boxSizing: 'border-box',
        ...shorthands.padding(tokens.spacingHorizontalXXS),
        gridArea: '1 / 1 / 1 / 2',
    },
    tabListHorizontal: {
        overflowX: 'auto', // Enables vertical scrolling
        maxWidth: '-webkit-fill-available', // Sets the max height to 100% of its parent
        boxSizing: 'border-box',
        ...shorthands.padding(tokens.spacingHorizontalXXS),
        gridArea: '1 / 1 / 2 / 3',
        justifySelf: 'center',
    },
    multiviewContent: {},
    tabbedContent: {
        minHeight: '29px',
        ...shorthands.overflow('auto'),
        boxSizing: 'border-box',
        // this should be in the last 3 columns of the grid
        gridColumnStart: 2,
        gridColumnEnd: 4,
        gridRowStart: 1,
        gridRowEnd: 2,
        // Include small padding to prevent the outline of input elements from being cut off
        ...shorthands.padding(tokens.spacingHorizontalXXS),
    },
});
