import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        zIndex: 1000, // Ensure it is on top of other elements
    },
});
