'use client';

import React, {useEffect, useState} from 'react';
import useClasses from './SkillsetDesigner.styles';
import {
    Dropdown,
    Option,
    useId,
    Button,
    Field,
    Input,
    Checkbox,
    OptionOnSelectData,
} from '@fluentui/react-components';
import {
    SkillsetContentType,
    SkillsetDescriptor,
    SkillsetFormat,
    useGetAllSkills,
    useGetSkillsets,
    useGetSkillsetCode,
    useUpdateSkillset,
    useCreateSkillset,
    SettingsScope,
    SkillsetCatalogScope,
    SkillsetCategory,
} from '@/api/skills';
import {
    Skill,
    SkillInput,
    SkillGroupFormat,
    Skillset,
    AgentSkillSettings,
    KqlTargetType,
    KQLSkillSettings,
    SkillsetDesignerProps,
    SkillSettings,
    SkillInterface,
} from './SkillsetCreator.types';
import {AddIcon, CheckmarkIcon, DeleteIcon} from '../icons';
import * as yaml from 'js-yaml';
import AgentFormSettings from './AgentFormSettings';
import KqlFormSettings from './KqlFormSettings';
import {usePatchSettings} from '@/api/settings';
import {MedeinaUrlParamFeatures} from '@/util/features';
import {useFeatureFlag} from '@/api/user';

const SkillsetDesigner = ({
    onValueChange,
    currentSkillsetYaml,
    currentSkillsetName,
    onChangeCurrentSkillset,
}: SkillsetDesignerProps) => {
    const classes = useClasses();
    const createSkillset = useCreateSkillset();
    const updateSkillset = useUpdateSkillset();
    const saveButtonId = useId('saveButton');
    const {
        data: skillsets,
        status: skillsetsLoadStatus,
        refetch: refetchSkillsets,
    } = useGetSkillsets();
    const [saveButtonContent, setSaveButtonContent] = useState<string>('Save');
    const [activeSkillset, setActiveSkillset] = useState<SkillsetDescriptor | null>(null);
    const [isCreatingSkillset, setIsCreatingSkillset] = useState<boolean>(false);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const {data: skillsetText, refetch: refetchSkillset} = useGetSkillsetCode({
        skillset: activeSkillset?.name ?? '',
    });
    const patchSettings = usePatchSettings();
    const [activeError, setActiveError] = useState<string | null>(null);
    const {data: allSkills, refetch: refetchAllSkills} = useGetAllSkills();
    const defaultSkillset = {
        name: '',
        description: '',
        displayName: '',
        skills: [
            {
                format: SkillGroupFormat.AGENT,
                name: '',
                displayName: '',
                description: '',
                inputs: [
                    {
                        required: true,
                        name: 'Input',
                        description: '',
                    },
                ],
                settings: {
                    instructions: '',
                },
                childSkills: [],
            },
        ],
    };

    const convertYamlSkillset = (yamlString: string): Skillset => {
        const parsedData = yaml.load(yamlString) as any;

        const skillset: Skillset = {
            name: parsedData?.Descriptor?.Name,
            displayName: parsedData?.Descriptor?.DisplayName,
            description: parsedData?.Descriptor?.Description,
            skills: [],
        };

        parsedData?.SkillGroups?.forEach((group: any) => {
            group?.Skills?.forEach((skill: any) => {
                let settings: SkillSettings;
                if (group.Format === SkillGroupFormat.KQL) {
                    settings = {
                        target: skill.Settings.Target,
                        cluster: skill.Settings.Cluster,
                        database: skill.Settings.Database,
                        template: skill.Settings.Template,
                    } as KQLSkillSettings;
                } else if (group.Format === SkillGroupFormat.AGENT) {
                    settings = {
                        instructions: skill.Settings.Instructions,
                    } as AgentSkillSettings;
                } else {
                    settings = skill.Settings;
                }
                const newSkill: Skill = {
                    name: skill.Name,
                    displayName: skill.Name,
                    description: skill.Description,
                    inputs: skill.Inputs.map((input: any) => ({
                        required: input.Required,
                        name: input.Name,
                        description: input.Description,
                    })),
                    settings: settings,
                    childSkills: skill.ChildSkills,
                    format: group.Format,
                };
                skillset.skills.push(newSkill);
            });
        });
        return skillset;
    };

    const [formFields, setFormFields] = useState<Skillset>(
        currentSkillsetYaml ? convertYamlSkillset(currentSkillsetYaml) : defaultSkillset,
    );

    const newSkillset = () => {
        setIsCreatingSkillset(true);
        setFormFields(defaultSkillset);
        setActiveSkillset({
            name: 'New Skillset',
            description: '',
            descriptionDisplay: '',
            category: SkillsetCategory.Other,
            displayName: 'New Skillset',
            icon: null,
            catalogScope: SkillsetCatalogScope.User,
            enabled: true,
        });
        onChangeCurrentSkillset?.('New Skillset');
        onValueChange?.('');
    };

    useEffect(() => {
        if (
            !activeSkillset ||
            !skillsetText ||
            typeof skillsetText !== 'string' ||
            isCreatingSkillset
        ) {
            return;
        }
        setFormFields(convertYamlSkillset(skillsetText ?? ''));
        onValueChange?.(skillsetText);
    }, [skillsetText]);

    useEffect(() => {
        if (currentSkillsetYaml) {
            setFormFields(convertYamlSkillset(currentSkillsetYaml));
        } else {
            setFormFields(defaultSkillset);
        }
    }, [currentSkillsetYaml]);

    useEffect(() => {
        if (unsavedChanges) onValueChange?.(fetchYamlSkillset());
    }, [formFields]);

    const getEnabledSkillsets = (): string[] | undefined => {
        return skillsets?.value
            ?.filter((s) => s.enabled && s.category.toString() !== 'Hidden')
            ?.map((s) => s.name);
    };

    const isAgentEnableOnSaveEnabled = useFeatureFlag(MedeinaUrlParamFeatures.AgentEnableOnSave);
    const addEnabledSkillset = async (skillsetToEnable: string) => {
        if (!isAgentEnableOnSaveEnabled) {
            return;
        }
        const enabledSkillsets = getEnabledSkillsets();
        if (!enabledSkillsets) {
            return;
        }
        if (enabledSkillsets.indexOf(skillsetToEnable) !== -1) {
            return;
        }
        enabledSkillsets.push(skillsetToEnable);
        await patchSettings.mutateAsync({enabledSkillsets, scope: SettingsScope.User});
    };

    const handleSkillsetSelect = (_event: React.SyntheticEvent, data: OptionOnSelectData) => {
        setIsCreatingSkillset(false);
        setActiveSkillset(skillsets?.value?.find((n) => n.name == data.optionValue) || null);
        onChangeCurrentSkillset?.(data.optionValue ?? '');
    };

    const handleFormChange = (
        field:
            | keyof SkillInput
            | keyof Skill
            | keyof Skillset
            | keyof Skill['settings']
            | keyof AgentSkillSettings
            | keyof KQLSkillSettings,
        value: any,
        skillIndex?: number,
        inputIndex?: number,
        childSkillIndex?: number,
    ) => {
        setUnsavedChanges(true);
        setFormFields((prevFields) => {
            const newFields = {...prevFields};
            if (skillIndex !== undefined && inputIndex !== undefined) {
                (newFields.skills[skillIndex].inputs[inputIndex] as any)[field] = value;
            } else if (skillIndex !== undefined && childSkillIndex !== undefined) {
                const childSkills = newFields.skills[skillIndex].childSkills || [];
                childSkills[childSkillIndex!] = value;
                newFields.skills[skillIndex].childSkills = childSkills;
            } else if (skillIndex !== undefined) {
                if (field === 'format') {
                    newFields.skills[skillIndex].format = value;
                    // Reset settings based on the new format
                    newFields.skills[skillIndex].settings = getDefaultSettingsForFormat(value);
                } else if (
                    newFields.skills[skillIndex] &&
                    newFields.skills[skillIndex].settings &&
                    field in newFields.skills[skillIndex].settings
                ) {
                    (newFields.skills[skillIndex].settings as any)[field] = value;
                } else {
                    (newFields.skills[skillIndex] as any)[field] = value;
                }
            } else {
                if (field === 'name') {
                    newFields.displayName = value;
                }
                (newFields as any)[field] = value;
            }
            return newFields;
        });
    };

    const getDefaultSettingsForFormat = (format: SkillGroupFormat) => {
        switch (format) {
            case SkillGroupFormat.AGENT:
                return {
                    instructions: '',
                } as AgentSkillSettings;
            case SkillGroupFormat.KQL:
                return {
                    target: KqlTargetType.Kusto,
                    cluster: '',
                    database: '',
                    template: '',
                } as KQLSkillSettings;
        }
    };

    const fetchYamlSkillset = () => {
        const yamlData = {
            Descriptor: {
                Name: formFields.name,
                Description: formFields.description,
                DisplayName: formFields.name,
            },
            SkillGroups: Object.values(SkillGroupFormat)
                .map((format) => {
                    const skills = formFields.skills
                        .filter((skill) => skill.format === format)
                        .map((skill) => {
                            let settings: any;
                            if (format === SkillGroupFormat.KQL) {
                                settings = {
                                    Target: (skill.settings as KQLSkillSettings).target,
                                    Cluster: (skill.settings as KQLSkillSettings).cluster,
                                    Database: (skill.settings as KQLSkillSettings).database,
                                    Template: (skill.settings as KQLSkillSettings).template,
                                };
                            } else if (format === SkillGroupFormat.AGENT) {
                                settings = {
                                    Instructions: (skill.settings as AgentSkillSettings)
                                        .instructions,
                                };
                            } else {
                                settings = skill.settings;
                            }
                            return {
                                Name: skill.name,
                                DisplayName: skill.name,
                                Description: skill.description,
                                Interfaces:
                                    format === SkillGroupFormat.AGENT ? [SkillInterface.Agent] : [],
                                Inputs: skill.inputs.map((input) => ({
                                    Required: input.required,
                                    Name: input.name,
                                    Description: input.description,
                                })),
                                Settings: settings,
                                ChildSkills: skill.childSkills,
                            };
                        });
                    return skills.length > 0
                        ? {
                              Format: format,
                              Settings: {HistoryPassDownMode: 'None', IncludeSessionHistory: false},
                              Skills: skills,
                          }
                        : null;
                })
                .filter((group) => group !== null),
        };

        const yamlString = yaml.dump(yamlData);
        return yamlString;
    };

    const handleFormSubmit = async () => {
        const yamlString = fetchYamlSkillset();
        const skillsetName = formFields?.name;
        setSaveButtonContent('Saving...');

        const skillsetAlreadyExists =
            skillsets?.value && skillsets.value.findIndex((s) => s.name === skillsetName) !== -1;

        const upsertPromise = skillsetAlreadyExists
            ? updateSkillset.mutateAsync({
                  body: yamlString,
                  name: skillsetName,
                  scope: 'User',
                  format: SkillsetFormat.SkillsetYaml,
                  contentType: SkillsetContentType.Yaml,
                  contentUrl: '',
              })
            : createSkillset.mutateAsync({
                  body: yamlString,
                  scope: 'User',
                  format: SkillsetFormat.SkillsetYaml,
                  contentType: SkillsetContentType.Yaml,
                  contentUrl: '',
              });
        await upsertPromise
            .then(
                (success) => {
                    setUnsavedChanges(false);
                    setActiveSkillset(success.descriptor);
                    if (isCreatingSkillset) {
                        setIsCreatingSkillset(false);
                        return refetchSkillsets().then(() => addEnabledSkillset(skillsetName));
                    }
                },
                (failure) => {
                    setUnsavedChanges(true);
                    setActiveError(failure.toString());
                },
            )
            .finally(() => {
                setSaveButtonContent('Save');
            });
    };

    return (
        <>
            <div className={classes.editorToolbar}>
                <Dropdown
                    value={activeSkillset?.name ?? currentSkillsetName ?? ''}
                    selectedOptions={activeSkillset?.name ? [activeSkillset.name] : []}
                    onOptionSelect={handleSkillsetSelect}
                    placeholder={
                        skillsetsLoadStatus === 'loading'
                            ? 'Loading skillsets...'
                            : 'Select skillset'
                    }
                >
                    {skillsets &&
                        skillsets.value
                            .filter(
                                (skillset) => skillset.catalogScope == SkillsetCatalogScope.User,
                            )
                            .map((skillset) => (
                                <Option key={skillset.name} value={skillset.name}>
                                    {skillset.displayName ?? ''}
                                </Option>
                            ))}
                </Dropdown>
                <Button icon={<AddIcon />} size="medium" onClick={newSkillset}>
                    New
                </Button>
            </div>
            <form className={classes.form}>
                <Field id="skillsetName" label="Skillset Name" required={true}>
                    <Input
                        type="text"
                        value={formFields.name}
                        onChange={(e) => handleFormChange('name', e.target.value)}
                        placeholder="Skillset Name"
                    />
                </Field>
                <Field id="description" label="Description" required={true}>
                    <Input
                        type="text"
                        value={formFields.description}
                        onChange={(e) => handleFormChange('description', e.target.value)}
                        placeholder="Description"
                    />
                </Field>
                {formFields.skills.map((skill, skillIndex) => (
                    <div key={skillIndex}>
                        <div className={classes.row}>
                            <h2>Skill {skillIndex + 1}</h2>
                            <Button
                                className={classes.removeButton}
                                icon={<DeleteIcon />}
                                onClick={() => {
                                    setFormFields((prevFields) => {
                                        const newSkills = [...prevFields.skills];
                                        newSkills.splice(skillIndex, 1);
                                        return {...prevFields, skills: newSkills};
                                    });
                                }}
                            ></Button>
                        </div>
                        <Field id={`format-${skillIndex}`} label="Format" required={true}>
                            <Dropdown
                                value={skill.format}
                                onOptionSelect={(_e, data) =>
                                    handleFormChange('format', data.optionValue, skillIndex)
                                }
                            >
                                {Object.values(SkillGroupFormat).map((format) => (
                                    <Option key={format} value={format}>
                                        {format}
                                    </Option>
                                ))}
                            </Dropdown>
                        </Field>
                        <Field id={`skillName-${skillIndex}`} label="Skill Name" required={true}>
                            <Input
                                type="text"
                                value={skill.name}
                                onChange={(e) =>
                                    handleFormChange('name', e.target.value, skillIndex)
                                }
                                placeholder="Skill Name"
                            />
                        </Field>
                        <Field
                            id={`skillDescription-${skillIndex}`}
                            label="Skill Description"
                            required={true}
                        >
                            <Input
                                type="text"
                                value={skill.description}
                                onChange={(e) =>
                                    handleFormChange('description', e.target.value, skillIndex)
                                }
                                placeholder="Skill Description"
                            />
                        </Field>
                        <h3>Skill inputs</h3>
                        {skill.inputs.map((input, inputIndex) => (
                            <div key={inputIndex}>
                                <div className={classes.row}>
                                    <h4>Input {inputIndex + 1}</h4>
                                    {inputIndex > 0 && (
                                        <Button
                                            className={classes.removeButton}
                                            icon={<DeleteIcon />}
                                            onClick={() => {
                                                setFormFields((prevFields) => {
                                                    const newInputs = [
                                                        ...prevFields.skills[skillIndex].inputs,
                                                    ];
                                                    newInputs.splice(inputIndex, 1);
                                                    const newSkills = [...prevFields.skills];
                                                    newSkills[skillIndex].inputs = newInputs;
                                                    return {...prevFields, skills: newSkills};
                                                });
                                            }}
                                        ></Button>
                                    )}
                                </div>
                                <Field
                                    id={`inputName-${skillIndex}-${inputIndex}`}
                                    label="Input Name"
                                    required={true}
                                >
                                    <Input
                                        type="text"
                                        value={input.name}
                                        onChange={(e) =>
                                            handleFormChange(
                                                'name',
                                                e.target.value,
                                                skillIndex,
                                                inputIndex,
                                            )
                                        }
                                        disabled={input.name === 'Input'}
                                        placeholder="Input Name"
                                    />
                                </Field>
                                <Field
                                    id={`inputDescription-${skillIndex}-${inputIndex}`}
                                    label="Input Description"
                                    required={true}
                                >
                                    <Input
                                        type="text"
                                        value={input.description}
                                        onChange={(e) =>
                                            handleFormChange(
                                                'description',
                                                e.target.value,
                                                skillIndex,
                                                inputIndex,
                                            )
                                        }
                                        placeholder="Input Description"
                                    />
                                </Field>
                                <Field
                                    id={`inputRequired-${skillIndex}-${inputIndex}`}
                                    required={true}
                                >
                                    <Checkbox
                                        labelPosition="before"
                                        label="Required"
                                        checked={input.required}
                                        onChange={(e) =>
                                            handleFormChange(
                                                'required',
                                                e.target.checked,
                                                skillIndex,
                                                inputIndex,
                                            )
                                        }
                                    />
                                </Field>
                            </div>
                        ))}
                        <Button
                            className={classes.addButton}
                            icon={<AddIcon />}
                            onClick={() => {
                                setFormFields((prevFields) => {
                                    const newInputs = [
                                        ...prevFields.skills[skillIndex].inputs,
                                        {
                                            required: true,
                                            name: '',
                                            description: '',
                                        },
                                    ];
                                    const newSkills = [...prevFields.skills];
                                    newSkills[skillIndex].inputs = newInputs;
                                    return {...prevFields, skills: newSkills};
                                });
                            }}
                        >
                            Skill Input
                        </Button>
                        <h3>Skill settings</h3>
                        {skill.format === SkillGroupFormat.AGENT && (
                            <AgentFormSettings
                                skillIndex={skillIndex}
                                skill={skill}
                                prevFields={formFields}
                                handleFormChange={handleFormChange}
                                setFormFields={setFormFields}
                                allSkills={allSkills?.value || []}
                            />
                        )}
                        {skill.format === SkillGroupFormat.KQL && (
                            <KqlFormSettings
                                skillIndex={skillIndex}
                                skill={skill}
                                handleFormChange={handleFormChange}
                            />
                        )}
                    </div>
                ))}
                <Button
                    className={classes.addButton}
                    icon={<AddIcon />}
                    onClick={() => {
                        setFormFields((prevFields) => ({
                            ...prevFields,
                            skills: [
                                ...prevFields.skills,
                                {
                                    format: SkillGroupFormat.AGENT,
                                    name: '',
                                    displayName: '',
                                    description: '',
                                    inputs: [
                                        {
                                            required: true,
                                            name: 'Input',
                                            description: '',
                                        },
                                    ],
                                    settings: {
                                        instructions: '',
                                        childSkills: [],
                                    },
                                },
                            ],
                        }));
                    }}
                >
                    Skill
                </Button>
            </form>
            <Button
                id={saveButtonId}
                icon={<CheckmarkIcon />}
                onClick={handleFormSubmit}
                disabled={!unsavedChanges}
            >
                {saveButtonContent}
            </Button>
        </>
    );
};

export default SkillsetDesigner;
