import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    flexContainerRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexGrow: {
        display: 'flex',
        flexGrow: 1,
    },
    promptbookInputActionsContainer: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    toolBarButton: {
        ...shorthands.padding(tokens.spacingVerticalXXS),
        height: tokens.lineHeightBase500,
    },
    toolBarButtonSkill: {
        marginTop: tokens.spacingVerticalM,
        visibility: 'hidden',
        ...shorthands.padding(tokens.spacingVerticalXXS),
        height: tokens.lineHeightBase500,
    },
    hoverParentPromptInput: {
        [`&:hover *, &:focus-within *`]: {
            visibility: 'visible',
        },
    },
    promptbookInput: {
        visibility: 'hidden',
        ...shorthands.padding(tokens.spacingVerticalXXS),
        height: tokens.lineHeightBase500,
        alignItems: 'center',
        alignSelf: 'center',
    },
    moreOptionsButton: {
        visibility: 'hidden',
    },
    promptLabel: {
        ...shorthands.padding(
            tokens.strokeWidthThin,
            tokens.spacingVerticalM,
            tokens.strokeWidthThicker,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: 'auto',
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.margin(tokens.spacingHorizontalXS, 0),
        fontSize: tokens.fontSizeBase100,
        minHeight: tokens.lineHeightBase600,
    },
    promptTextarea: {
        minHeight: tokens.lineHeightBase600,
    },
    promptNumber: {
        ...shorthands.padding(tokens.spacingHorizontalXS),
        alignSelf: 'center',
    },
    promptContent: {
        ...shorthands.padding(tokens.spacingHorizontalXS),
        flexGrow: 1,
    },
    container: {
        display: 'flex',
    },
    verticalLine: {
        ...shorthands.borderLeft(tokens.borderRadiusSmall, 'solid'),
        height: tokens.lineHeightBase500,
        ...shorthands.margin(0, tokens.spacingHorizontalXS),
        borderLeftColor: tokens.colorNeutralStroke1,
    },
    directSkillsMenu: {
        position: 'absolute',
        bottom: '100%',
        right: '0%',
        zIndex: '1',
    },
    skillFormWrapper: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(
            tokens.spacingVerticalL,
            tokens.spacingHorizontalXL,
            tokens.spacingVerticalL,
            tokens.spacingHorizontalS,
        ),
    },
    promptNumberSkill: {
        ...shorthands.padding(tokens.spacingHorizontalXS),
        paddingTop: tokens.spacingVerticalL,
    },
    toolBarContainer: {
        position: 'absolute',
        right: '2%',
    },
});
