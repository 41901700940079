import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserState} from '../user/user.types';

export default function useGetUserInfo(
    useQueryOptions?: Pick<UseQueryOptions<UserState>, 'enabled' | 'onSettled'>,
) {
    const {customFetch} = useFetch();
    return useQuery({
        ...useQueryOptions,
        queryKey: ['auth', 'getUserInfo'],
        queryFn: async () => {
            return await customFetch<UserState>(`/auth/userInfo`, {method: 'GET'});
        },
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchInterval: 300_000,
    });
}
