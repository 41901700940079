import {Divider} from '@fluentui/react-components';
import {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {DownIcon, SkillIcon, UpIcon} from '@/components/ui/icons';
import Textarea from '@/components/ui/Textarea';
import useClasses from './DirectSkillForm.styles';
import {DirectSkillFormProps} from './DirectSkillForm.types';
import {SkillInputDescriptor} from '@/api/skills';
import {useTranslation} from 'react-i18next';

export * from './DirectSkillForm.types';

export default function DirectSkillForm({
    skillInputs,
    skillInputDescriptors,
    skillName,
    editMode,
    onChange,
    onRequiredStatusChange,
}: DirectSkillFormProps) {
    const classes = useClasses();
    const inputRefs = useRef<{[key: string]: HTMLTextAreaElement | null}>({});
    const [hasOptional, setHasOptional] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isRequiredFilled, setIsRequiredFilled] = useState(true);
    const [tempInputs, setTempInputs] = useState(skillInputs);
    const {t} = useTranslation('promptbooks');

    // Reset parent state initially, otherwise it could include stale or empty data.
    useEffect(() => {
        onChange?.(skillInputs);
    }, []);

    // Set `hasOptional` to true when optional fields are detected
    useEffect(() => {
        const hasOptionalField = skillInputDescriptors.some((input) => !input.required);
        setHasOptional(hasOptionalField);
    }, [skillInputDescriptors]); // Only rerun when inputs change

    useEffect(() => {
        validateRequiredFields(); // Validate initially and on updates
    }, [tempInputs]);

    // Update state based on form changes.
    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLTextAreaElement>, input: SkillInputDescriptor) => {
            const nextValues = {...tempInputs, [input.name]: ev.target.value};
            setTempInputs(nextValues);
            onChange?.(nextValues);
        },
        [onChange],
    );

    const handleClick = () => {
        setIsVisible(!isVisible);
    };

    const validateRequiredFields = () => {
        const allRequiredFilled = skillInputDescriptors.every(
            (input) =>
                !input.required || (tempInputs[input.name] && tempInputs[input.name].trim() !== ''),
        );
        setIsRequiredFilled(allRequiredFilled);
        onRequiredStatusChange?.(allRequiredFilled);
    };

    return (
        <div className={classes.root}>
            <div className={classes.skill}>
                <SkillIcon className={classes.skillIcon} />
                {skillName}
            </div>
            <Divider appearance="subtle" />
            <div data-testid={`skill-form`}>
                {editMode ? (
                    skillInputDescriptors.map(
                        (input) =>
                            input.required && (
                                <div key={`${input.name} div`} className={classes.inputs}>
                                    <label key={`${input.name} label`} htmlFor={input.name}>{`${
                                        input.placeholderValue ?? input.description ?? input.name
                                    }*`}</label>
                                    <Textarea
                                        id={input.name}
                                        key={input.name}
                                        data-testid={`direct-skill-form-input-${input.name}`}
                                        ref={(el) => (inputRefs.current[input.name] = el)}
                                        value={tempInputs[input.name] ?? ''}
                                        size="large"
                                        appearance="outline"
                                        autosize
                                        onChange={(ev) => {
                                            handleChange(ev, input);
                                            validateRequiredFields();
                                        }}
                                        required={input.required}
                                    />
                                </div>
                            ),
                    )
                ) : (
                    //100 character limit for displaying input value
                    <>
                        {Object.entries(skillInputs ?? {}).map(([name, value]) => {
                            const trimmedValue =
                                value.length > 100 ? `${value.substring(0, 100)}...` : value;
                            return ` ${name}: ${trimmedValue}`;
                        })}
                    </>
                )}
                {hasOptional && editMode && (
                    <button
                        onClick={handleClick}
                        className={classes.optionalFieldsButton}
                        data-testid={`optional-fields`}
                        aria-label={t('OpenOptionalInputFields')}
                        type="button"
                    >
                        {t('OptionalFields')}
                        {isVisible ? (
                            <UpIcon className={classes.largeIcon} />
                        ) : (
                            <DownIcon className={classes.largeIcon} />
                        )}
                    </button>
                )}
                {isVisible &&
                    skillInputDescriptors.map(
                        (input) =>
                            !input.required && (
                                <div key={`optional-${input.name} div`} className={classes.inputs}>
                                    <label
                                        key={`optional-${input.name} label`}
                                        htmlFor={input.name}
                                    >{`${
                                        input.placeholderValue ?? input.description ?? input.name
                                    }`}</label>
                                    <Textarea
                                        id={input.name}
                                        key={`optional-${input.name}`}
                                        ref={(el) => (inputRefs.current[input.name] = el)}
                                        value={tempInputs[input.name] ?? ''}
                                        size="large"
                                        appearance="outline"
                                        autosize
                                        onChange={(ev) => handleChange(ev, input)}
                                        required={input.required}
                                    />
                                </div>
                            ),
                    )}
            </div>
        </div>
    );
}
