import {useEffect, useState} from 'react';
import useClasses from './NewHome.styles';
import {SkillDescriptor} from '@/api/skills';
import React from 'react';
import {useLayout} from '@/components/ui/Layout';
import {BackgroundAnimation, BackgroundImage} from '@/components/ui/Background';
import NewHomeSessions from './Sessions';
import ExploreCopilot from './ExploreCopilot';
import {PromptBar, ScreenName} from '@/components/ui';
import useAnalystHomeTour from '@/components/sections/tours/analyst-home/useAnalystHomeTour';
import useAdminPluginTour from '@/components/sections/tours/admin-plugins/useAdminPluginTour';
import {ExploreHomeLibrary} from '@/components/sections/homeLibrary';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures, {MedeinaUrlParamFeatures} from '@/util/features';
import {PromptSuggestionDescriptor} from '@/api/prompts/suggestedPrompts.types';
import {SubmitIcon} from '@/components/ui/icons';
import {Button, Tooltip} from '@fluentui/react-components';
import {useNavigate} from 'react-router-dom';

export default function Home() {
    const classes = useClasses();
    const {background} = useLayout();
    const navigate = useNavigate();

    useAnalystHomeTour();
    useAdminPluginTour();

    const promptLibraryEnabled = useFeatureFlag(MedeinaFeatures.EnablePromptLibrary);
    const isAgentWorkshopEnabled = useFeatureFlag(MedeinaUrlParamFeatures.AgentWorkshop);

    const [defaultPromptSkill, setDefaultPromptSkill] = useState<SkillDescriptor | undefined>(
        undefined,
    );

    const [suggestedPrompt, setSuggestedPrompt] = useState<PromptSuggestionDescriptor | undefined>(
        undefined,
    );

    useEffect(() => {
        background?.setImage?.(BackgroundImage.triangles);
        // TODO: re-enable animation once we work out performance issues
        background?.setAnimation?.(BackgroundAnimation.none);

        return () => {
            background?.setImage?.(BackgroundImage.none);
            background?.setAnimation?.(BackgroundAnimation.none);
        };
    }, [background]);

    const handleSuggestedPromptClick = (prompt: PromptSuggestionDescriptor | undefined) => {
        setSuggestedPrompt(prompt);
    };

    return (
        <div className={classes.root}>
            <div className={promptLibraryEnabled ? classes.scrollableContainer : ''}>
                <div className={promptLibraryEnabled ? '' : classes.content}>
                    {promptLibraryEnabled ? (
                        <ExploreHomeLibrary
                            handleSuggestedPromptClick={handleSuggestedPromptClick}
                        />
                    ) : (
                        <>
                            <NewHomeSessions />
                            <ExploreCopilot />
                        </>
                    )}
                </div>
            </div>
            {isAgentWorkshopEnabled && (
                <div className={classes.agents}>
                    <Tooltip
                        content={'Create your first agent'}
                        relationship="label"
                        positioning="above"
                    >
                        <Button
                            appearance="primary"
                            icon={<SubmitIcon />}
                            onClick={() =>
                                MedeinaFeatures.NewSessionPage
                                    ? navigate('/sessions/new')
                                    : navigate('/')
                            }
                            data-testid="promptbar-agents-button"
                        >
                            Agent workshop
                        </Button>
                    </Tooltip>
                </div>
            )}
            <div className={classes.prompt}>
                <PromptBar
                    defaultSkill={defaultPromptSkill}
                    suggestedPrompt={suggestedPrompt}
                    handleSuggestedPromptClick={handleSuggestedPromptClick}
                    enableSkillsetConnector
                    enableSupportAssistance
                    screenName={ScreenName.Home}
                />
            </div>
        </div>
    );
}
