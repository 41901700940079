import {useState} from 'react';
import {DismissRegular} from '@fluentui/react-icons';
import {
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarTitle,
    Button,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useClasses from './FormErrorMessageBar.styles';

interface FormErrorMessageBarProps {
    messageTitle: string;
}

export const FormErrorMessageBar: React.FC<FormErrorMessageBarProps> = ({messageTitle}) => {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    let messageContent: string;
    const messageMap: {[key: string]: string} = {
        create: 'ManageWorkspaces.Errors.CreateWorkspace',
        edit: 'ManageWorkspaces.Errors.EditWorkspace',
        duplicate: 'ManageWorkspaces.Errors.DuplicateWorkspace',
        delete: 'ManageWorkspaces.Errors.DeleteWorkspace',
    };

    messageContent = messageMap[messageTitle] || 'ManageWorkspaces.Errors.Default';

    return (
        <div className={classes.messageBarContainer}>
            <MessageBar key="warning" layout="singleline" intent="error">
                <MessageBarBody className={classes.messageBarBody}>
                    <MessageBarTitle className={classes.title}>{t(messageContent)}</MessageBarTitle>
                    {t('ManageWorkspaces.Errors.TryAgain')}
                </MessageBarBody>
            </MessageBar>
        </div>
    );
};
