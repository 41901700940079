import {useState, useEffect, useMemo, useRef} from 'react';
import useClasses from '../ManageWorkspaces.styles';
import {
    TableCellLayout,
    Menu,
    MenuButton,
    MenuTrigger,
    MenuGroup,
    MenuGroupHeader,
    MenuDivider,
    MenuPopover,
    MenuList,
    MenuItem,
    Link,
} from '@fluentui/react-components';
import {AddIcon, OpenIcon, CheckmarkIcon} from '@/components/ui/icons';
import {Capacity} from '@/api/capacities/capacities.types';
import {useUpdateWorkspace, Workspace} from '@/api/workspaces';
import {useGetCapacityByName, useGetCapacities} from '@/api/capacities';
import {REGION_DATA} from '@/components/App.constants';
import {RegionName} from '@/components/App.types';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import DisconnectCapacityDialog from '../DisconnectCapacityDialog';
import CreateCapacity from '@/components/CreateCapacity';

interface CapacityColumnProps {
    workspace: Workspace;
    isWorkspacesDataFetched: boolean;
    workspaces: Workspace[];
    disconnectedWorkspaces: Workspace[];
    setDisconnectedWorkspaces: (workspace: Workspace[]) => void;
    disconnectedCapacities: Capacity[];
    setDisconnectedCapacities: (capacity: Capacity[]) => void;
}

export const CapacityColumn: React.FC<CapacityColumnProps> = ({
    workspace,
    isWorkspacesDataFetched,
    workspaces,
    disconnectedWorkspaces,
    setDisconnectedWorkspaces,
    disconnectedCapacities,
    setDisconnectedCapacities,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    // API call to update workspace capacity linkage
    const {mutate: updateWorkspace, isLoading: isUpdateWorkspaceLoading} = useUpdateWorkspace();
    const {data: capacityData} = useGetCapacityByName(workspace?.capacity?.referenceName || '', {
        enabled: isWorkspacesDataFetched,
        refetchOnMount: true,
    });
    const {data: data} = useGetCapacities({});
    const capacities: {value: Capacity[]} = useMemo(() => data || {value: []}, [data]);

    let capacityDisplayName: string | null =
        REGION_DATA[capacityData?.location as RegionName]?.displayName;
    if (
        disconnectedWorkspaces.some(
            (disconnectedWorkspace) => disconnectedWorkspace.name === workspace.name,
        )
    ) {
        capacityDisplayName = null;
    }
    // this should work when backend integration happens
    const [selectedCapacity, setSelectedCapacity] = useState<string | null>(capacityDisplayName);

    useEffect(() => {
        setSelectedCapacity(capacityDisplayName);
    }, [capacityDisplayName]);
    // check if in disconnected worksapces array and set accordingly

    const [tempSelectedCapacity, setTempSelectedCapacity] = useState<string | null>(null);
    const [attachedWorkspace, setAttachedWorkspace] = useState<Workspace>();

    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);

    const handleMenuItemClick = (capacity: Capacity) => {
        const tempCapacityDisplayName = REGION_DATA[capacity.location as RegionName]?.displayName;
        setTempSelectedCapacity(REGION_DATA[capacity?.location as RegionName]?.displayName);
        // check if capacity is part of disconnectedcapacities
        const isDisconnected = disconnectedCapacities.some(
            (disconnectedCapacity) => disconnectedCapacity.name === capacity.name,
        );
        let tempDisconnectedCapacities: Capacity[] = [...disconnectedCapacities];
        // the capacity chosen is already attached to another workspace
        if (!isDisconnected) {
            // find the corresponding workspace to the selected capacity that is to be disconnected
            const tempAttachedWorkspace = workspaces.find(
                (workspace: Workspace) => workspace.capacity?.referenceName === capacity.name,
            );
            // set attached workspace for disconnect capacity dialog
            setAttachedWorkspace(tempAttachedWorkspace);
            setDisconnectCapacityDialogOpen(true);
        }
        // capacity was available and not attached to any workspace
        else {
            // check if there was a valid original selected capacity
            if (selectedCapacity) {
                // add old capacity to disconnected capacities
                const originalCapacity = capacities.value.find(
                    (capacity) =>
                        REGION_DATA[capacity.location as RegionName]?.displayName ===
                        selectedCapacity,
                );
                if (originalCapacity) {
                    tempDisconnectedCapacities.push(originalCapacity);
                }
            }
            // set capacity to new capacity
            capacityDisplayName = tempCapacityDisplayName;
            setSelectedCapacity(tempCapacityDisplayName);
            // update workspace with new capacity
            updateWorkspace({
                ...workspace,
                name: workspace.name,
                capacity: {
                    type: 'capacity',
                    referenceName: capacity.name,
                },
            });
            // remove capacity from disconnected capacities
            tempDisconnectedCapacities = tempDisconnectedCapacities.filter(
                (c) => c.name !== capacity.name,
            );
            setDisconnectedCapacities(tempDisconnectedCapacities);
            // check if workspace was in disconnected workspaces
            const newDisconnectedWorkspaces = disconnectedWorkspaces.filter(
                (disconnectedWorkspace) => disconnectedWorkspace.name !== workspace.name,
            );
            // remove workspace from disconnected workspaces
            setDisconnectedWorkspaces(newDisconnectedWorkspaces);
        }
    };

    const handleDisconnectConfirm = () => {
        if (tempSelectedCapacity) {
            // add original capacity to disconnected capacities
            const originalCapacity = capacities.value.find(
                (capacity) =>
                    REGION_DATA[capacity.location as RegionName]?.displayName === selectedCapacity,
            );
            if (originalCapacity) {
                setDisconnectedCapacities([...disconnectedCapacities, originalCapacity]);
            }
            setSelectedCapacity((prev) => tempSelectedCapacity);
            console.log('Selected capacity: ' + tempSelectedCapacity);
        }
        if (attachedWorkspace) {
            // add attached workspace to disconnected workspaces and remove current workspace from disconnected workspaces
            const newDisconnectedWorkspaces = disconnectedWorkspaces.filter(
                (disconnectedWorkspace) => disconnectedWorkspace.name !== workspace.name,
            );
            setDisconnectedWorkspaces([...newDisconnectedWorkspaces, attachedWorkspace]);
            console.log(
                'new disconnected workspaces: ' +
                    JSON.stringify([...newDisconnectedWorkspaces, attachedWorkspace]),
            );
        }
        // disconnect capacity from attached workspace
        // or just pass in the disconnectedworkspace and disconnected capacities arrays in and if it lives there, then it has an error and show the error UI
        updateWorkspace(
            {
                ...attachedWorkspace,
                name: attachedWorkspace?.name || '',
                capacity: null,
            },
            {
                onSuccess: (data) => {
                    console.log(
                        'Successfully disconnected capacity ' +
                            tempSelectedCapacity +
                            ' from ' +
                            attachedWorkspace?.name +
                            ' workspace',
                    );
                    // Assign new capacity to workspace
                    updateWorkspace({
                        ...workspace,
                        name: workspace.name,
                        capacity: {
                            type: 'capacity',
                            referenceName: tempSelectedCapacity || '',
                        },
                    });
                    setDisconnectCapacityDialogOpen(false);
                },
                onError: () => {
                    console.log(
                        'Error disconnecting capacity ' +
                            tempSelectedCapacity +
                            ' from ' +
                            attachedWorkspace?.name +
                            ' workspace',
                    );
                },
            },
        );
        setDisconnectCapacityDialogOpen(false);
    };

    // new capacity button ref
    const newCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);

    const [isWorkspaceDisconnected, setIsWorkspaceDisconnected] = useState<boolean>(false);
    useEffect(() => {
        const checkIfWorkspaceDisconnected = disconnectedWorkspaces.some(
            (disconnectedWorkspace) => disconnectedWorkspace.name === workspace.name,
        );
        setIsWorkspaceDisconnected(checkIfWorkspaceDisconnected);
    }, [disconnectedWorkspaces, workspace.name]);
    return (
        <>
            <TableCellLayout>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        {isWorkspaceDisconnected ? (
                            <MenuButton
                                data-testid="capacity-menu-button"
                                className={classes.errorMenuButton}
                            >
                                {t('ManageWorkspaces.MenuButtons.NoCapacitySelected')}
                            </MenuButton>
                        ) : (
                            <MenuButton appearance="transparent" data-testid="capacity-menu-button">
                                {selectedCapacity}
                            </MenuButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuGroup>
                                <MenuGroupHeader>
                                    {t('ManageWorkspaces.MenuButtons.CapacitiesYouOwn')}
                                </MenuGroupHeader>
                                {capacities.value.map((capacity: Capacity, index: number) => {
                                    const isDisconnected = disconnectedCapacities.some(
                                        (disconnectedCapacity) =>
                                            disconnectedCapacity.name === capacity.name,
                                    );
                                    return (
                                        <MenuItem
                                            key={index}
                                            className={
                                                isDisconnected ? classes.errorMenuButton : ''
                                            }
                                            icon={
                                                REGION_DATA[capacity?.location as RegionName]
                                                    ?.displayName === selectedCapacity ? (
                                                    <CheckmarkIcon />
                                                ) : (
                                                    <div className={classes.fillerIcon} />
                                                )
                                            }
                                            onClick={() => handleMenuItemClick(capacity)}
                                        >
                                            {isDisconnected
                                                ? REGION_DATA[capacity.location as RegionName]
                                                      ?.displayName +
                                                  ' ' +
                                                  t(
                                                      'ManageWorkspaces.MenuButtons.NotConnectedError',
                                                  )
                                                : REGION_DATA[capacity.location as RegionName]
                                                      ?.displayName}
                                        </MenuItem>
                                    );
                                })}
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<AddIcon />}
                                    data-testid="new-capacity-menu-item"
                                    onClick={() => {
                                        setIsCreateCapacityDialogOpen(true);
                                    }}
                                >
                                    {t('ManageWorkspaces.MenuButtons.NewCapacity')}
                                </MenuItem>
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<OpenIcon />}
                                    data-testid="manage-capacities-menu-item"
                                >
                                    <Link
                                        href={MedeinaVariables.ManageCapacityAzureUri}
                                        target="_blank"
                                        aria-label={t(
                                            'ManageWorkspaces.ariaLabel.ManageCapacityAzureUri',
                                        )}
                                        appearance="subtle"
                                    >
                                        {t('ManageWorkspaces.MenuButtons.ManageCapacitiesAzure')}
                                    </Link>
                                </MenuItem>
                            </MenuGroup>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <DisconnectCapacityDialog
                    title={t('ManageWorkspaces.DisconnectCapacityDialog.Title', {
                        0: tempSelectedCapacity,
                    })}
                    description={t('ManageWorkspaces.DisconnectCapacityDialog.Description', {
                        0: attachedWorkspace?.name,
                    })}
                    switchButtonLabel={t(
                        'ManageWorkspaces.DisconnectCapacityDialog.DisconnectButton',
                    )}
                    isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                    newCapacityName={tempSelectedCapacity || ''}
                    workspaceName={attachedWorkspace?.name || ''}
                    onClose={() => {
                        setDisconnectCapacityDialogOpen(false);
                    }}
                    onConfirm={handleDisconnectConfirm}
                />
                <CreateCapacity
                    isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                    onCreateCapacityDialogClose={() => {
                        setIsCreateCapacityDialogOpen(false);
                        newCapacityButtonRef.current?.focus();
                    }}
                    hideWorkspaceSelectField={false}
                />
            </TableCellLayout>
        </>
    );
};
