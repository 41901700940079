import {Caption1, Link, MenuGroup, MenuGroupHeader} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {PromptBarMenuChildProps, PromptBarMenuListType} from './PromptBarMenu.types';
import useClasses from './PromptBarMenu.styles';
import {SkillDescriptor} from '@/api/skills';
import usePromptBarMenuCapabilitiesList from './usePromptBarMenuCapabilitiesList';
import {NextIcon, SystemCapabilityIcon} from '../icons';
import PromptBarMenuList from './PromptBarMenuList';
import {useEffect, useMemo} from 'react';
import PromptBarMenuShowAllList from './PromptBarMenuShowAllList';
import {PreviewBadge} from '../Badges';

export default function PromptBarMenuAgents(props: PromptBarMenuChildProps<SkillDescriptor>) {
    const classes = useClasses();
    const {t} = useTranslation('promptbar');
    const {filter, skillsets, limit, showAll, onShowAll, tabIndex, shouldRepositionMenu} = props;
    const lists = usePromptBarMenuCapabilitiesList({filter, skillsets, limit, isAgentView: true});

    const nextProps = {
        ...props,
        lists,
        noMatches: t('NoMatchingAgents'),
        getName: (item: SkillDescriptor) => item.name || item.altName,
        getDisplayName: (item: SkillDescriptor) => item.displayName,
        getDescription: (item: SkillDescriptor) => item.description,
        header: (
            <>
                <MenuGroupHeader className={classes.menuGroupHeader}>
                    <SystemCapabilityIcon className={classes.menuGroupIcon} />
                    <div className={classes.menuGroupTitle}>
                        <span
                            data-testid="agent-capabilities-menu-title"
                            className={classes.menuGroupTitleText}
                        >
                            {t('Agents')}
                        </span>
                    </div>
                    {!showAll && (
                        <div className={classes.menuGroupShowAll}>
                            <Link role="menuitem" onClick={() => onShowAll?.()}>
                                {t('ListAllAgents')} <NextIcon />
                            </Link>
                        </div>
                    )}
                </MenuGroupHeader>
                <Caption1 className={classes.groupDescription}>{t('AgentsDescription')}</Caption1>
            </>
        ),
        getGroupLabel: (key: string) => {
            const previewState = lists.skillsetPreviewStateMap?.[key];
            return (
                <PreviewBadge
                    previewState={previewState}
                    tooltipContent={t('PreviewBadgeTooltipText')}
                    customTooltipProps={{
                        positioning: 'above-start',
                    }}
                />
            );
        },
    };

    // Reposition the menu based on a change in content.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(shouldRepositionMenu, [lists.filteredList, lists.groupedList]);

    return (
        <>
            {(!showAll || showAll === PromptBarMenuListType.Agents) && (
                <MenuGroup>
                    {showAll === PromptBarMenuListType.Agents ? (
                        <PromptBarMenuShowAllList {...nextProps} />
                    ) : (
                        <PromptBarMenuList {...nextProps} />
                    )}
                </MenuGroup>
            )}
        </>
    );
}
