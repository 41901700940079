import React from 'react';
import {Field, Input, Textarea, Dropdown, Option} from '@fluentui/react-components';
import {KqlSettingProps, KQLSkillSettings, KqlTargetType} from './SkillsetCreator.types';
export default function KqlFormSettings({skillIndex, skill, handleFormChange}: KqlSettingProps) {
    return (
        <>
            <Field id={`target-${skillIndex}`} label="Target" required={true}>
                <Dropdown
                    value={(skill.settings as KQLSkillSettings).target}
                    onOptionSelect={(_e, data) =>
                        handleFormChange('target', data.optionValue, skillIndex)
                    }
                >
                    {Object.values(KqlTargetType).map((target) => (
                        <Option key={target} value={target}>
                            {target}
                        </Option>
                    ))}
                </Dropdown>
            </Field>
            <Field id={`cluster-${skillIndex}`} label="Cluster" required={true}>
                <Input
                    type="text"
                    value={(skill.settings as KQLSkillSettings).cluster}
                    onChange={(e) => handleFormChange('cluster', e.target.value, skillIndex)}
                    placeholder="Cluster"
                />
            </Field>
            <Field id={`database-${skillIndex}`} label="Database" required={true}>
                <Input
                    type="text"
                    value={(skill.settings as KQLSkillSettings).database}
                    onChange={(e) => handleFormChange('database', e.target.value, skillIndex)}
                    placeholder="Database"
                />
            </Field>
            <Field id={`template-${skillIndex}`} label="Template" required={true}>
                <Textarea
                    value={(skill.settings as KQLSkillSettings).template}
                    onChange={(e) => handleFormChange('template', e.target.value, skillIndex)}
                    placeholder="Template"
                />
            </Field>
        </>
    );
}
