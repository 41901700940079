import {DateTimeFormat} from '@/components/date';
import {useViewportSize} from '@/components/ui/Grid';
import {ParagraphContent, TextH1} from '@/components/ui/Text';
import {USAGE_MONITORING_TITLE, LAST_UPDATED} from './UsageDashboard.constants';
import useClasses from './UsageDashboard.styles';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

interface UsageDashboardTitleProps {
    lastUpdatedAt: number | Date;
}

export default function UsageDashboardTitle(props: UsageDashboardTitleProps) {
    const viewPortSize = useViewportSize();

    const shouldAlignDateAtTop =
        viewPortSize.isSmallScreen || viewPortSize.isMediumScreen || viewPortSize.isLargeScreen;

    const classes = useClasses();

    const {t: tAdmin} = useTranslation('admin');

    const titleSection = useMemo(
        () => (
            <TextH1 className={classes.titleTextContainer}>{tAdmin(USAGE_MONITORING_TITLE)}</TextH1>
        ),
        [tAdmin, USAGE_MONITORING_TITLE],
    );

    return (
        <div
            className={shouldAlignDateAtTop ? classes.titleContainerColumn : classes.titleContainer}
            data-testid="dashboard-title"
        >
            {!shouldAlignDateAtTop && titleSection}
            <ParagraphContent data-testid="last-updated-date">
                {tAdmin(LAST_UPDATED)} {<DateTimeFormat date={props.lastUpdatedAt} />}
            </ParagraphContent>
            {shouldAlignDateAtTop && titleSection}
        </div>
    );
}
