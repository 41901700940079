import {useMemo} from 'react';
import Fuse from 'fuse.js';
import {
    SkillDescriptor,
    SkillsetDescriptor,
    useGetAllSkills,
    useGetAllTopSkills,
    useGetSkillsets,
} from '@/api/skills';
import {PromptBarMenuChildOptions, PromptBarMenuLists} from './PromptBarMenu.types';
import {PreviewState} from '@/api/api.types';

const FUSE_OPTIONS = {
    keys: [{name: 'name', weight: 2}, {name: 'displayName', weight: 2}, 'description'],
    threshold: 0.3,
};

export default function usePromptBarMenuCapabilitiesList({
    filter,
    skillsets,
    limit,
    isAgentView = false,
}: PromptBarMenuChildOptions): PromptBarMenuLists<SkillDescriptor> {
    const {data: topSkills, loading: isLoadingTop} = useGetAllTopSkills({skillsets});
    const {data: allSkills, isLoading} = useGetAllSkills();
    const {data: allSkillsets} = useGetSkillsets();

    // Searchable list of All skills
    const allSkillsList = useMemo<Fuse<SkillDescriptor>>(() => {
        return new Fuse<SkillDescriptor>(allSkills?.value || [], FUSE_OPTIONS);
    }, [allSkills]);

    // Generate the filtered list of skills.
    const filteredSkillsList = useMemo<
        Array<Pick<Fuse.FuseResult<SkillDescriptor>, 'item'>>
    >(() => {
        // Filter the list of all skills, if a filter exists.
        if (filter && allSkillsList) {
            return allSkillsList
                .search(filter, {
                    limit,
                })
                .filter((result) => !isAgentView || result.item.format?.toLowerCase() === 'agent');
        }
        // Otherwise fallback to the list of top skills.
        else {
            return (
                topSkills
                    ?.filter((skill) => !isAgentView || skill?.format?.toLowerCase() === 'agent')
                    .slice(0, limit)
                    .map((skill) => ({item: skill})) || []
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topSkills, allSkillsList, allSkills, filter, limit, isAgentView]);

    // Generate a list of all available skills filtered.
    let allAvailableSkillsList = useMemo<Fuse.FuseResult<SkillDescriptor>[]>(() => {
        if (filter && allSkillsList) {
            return allSkillsList
                .search(filter)
                .filter(
                    (result) => !isAgentView || result?.item?.format?.toLowerCase() === 'agent',
                );
        } else if (allSkills?.value) {
            return allSkills?.value
                .filter((skill) => !isAgentView || skill?.format?.toLowerCase() === 'agent')
                .map((skill) => ({item: skill} as Fuse.FuseResult<SkillDescriptor>));
        } else {
            return [];
        }
    }, [allSkillsList, allSkills, filter, isAgentView]);

    // Group skills by skillset.
    const groupedSkills = useMemo<{[key: string]: Array<SkillDescriptor>}>(() => {
        let grouped = allAvailableSkillsList
            .map((obj) => obj.item)
            .reduce((groups: {[key: string]: Array<SkillDescriptor>}, item: SkillDescriptor) => {
                let key = item.skillsetDisplayName != null ? item.skillsetDisplayName : '';
                if (!groups[key]) {
                    groups[key] = [];
                }
                groups[key].push(item);
                return groups;
            }, {});
        // Alphabetize the groups.
        Object.keys(grouped).forEach((key) => {
            grouped[key] = grouped[key].sort((a, b) => {
                return (a.displayName || a.name).localeCompare(b.displayName || b.name);
            });
        });
        return grouped;
    }, [allAvailableSkillsList]);

    const skillsetPreviewStateMap = useMemo<{[key: string]: PreviewState | undefined}>(() => {
        const skillsetPreviewStateMap: {[key: string]: PreviewState | undefined} = {};
        allSkillsets?.value?.forEach((skillset: SkillsetDescriptor) => {
            // Align the key implementation with the one in `useGetAllSkills`
            skillsetPreviewStateMap[skillset.displayName ?? skillset.name] = skillset.previewState;
        });
        return skillsetPreviewStateMap;
    }, [allSkillsets?.value]);

    return {
        allList: allSkillsList,
        filteredList: filteredSkillsList,
        groupedList: groupedSkills,
        skillsetPreviewStateMap,
        isLoading: isLoading || isLoadingTop,
    };
}
