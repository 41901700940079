import React, {useMemo, useState, useEffect, useCallback} from 'react';
import useClasses from './SkillsetDesigner.styles';
import {Field, Textarea, Button, Combobox, Option} from '@fluentui/react-components';
import {AgentSettingProps, AgentSkillSettings} from './SkillsetCreator.types';
import {AddIcon, DeleteIcon} from '../icons';
import Fuse from 'fuse.js';

export default function AgentFormSettings({
    skillIndex,
    skill,
    prevFields,
    handleFormChange,
    setFormFields,
    allSkills,
}: AgentSettingProps) {
    const classes = useClasses();
    const uniqueSkills = useMemo(() => {
        return Array.from(new Set(allSkills.map((skill) => skill.name)));
    }, [allSkills]);
    const [selectedChildSkills, setSelectedChildSkills] = useState<string[]>([]);
    const [filteredSkills, setFilteredSkills] = useState(uniqueSkills);

    const allSkillsList = useMemo<Fuse<string>>(() => {
        const filteredUniqueSkills = uniqueSkills.filter(
            (skill) => !selectedChildSkills.includes(skill),
        );
        return new Fuse<string>(filteredUniqueSkills, {threshold: 0.3});
    }, [uniqueSkills, selectedChildSkills]);

    const [instructions, setInstructions] = useState<string>('');

    useEffect(() => {
        if (skill.settings && (skill.settings as AgentSkillSettings).instructions) {
            setInstructions((skill.settings as AgentSkillSettings).instructions);
        } else {
            setInstructions('');
        }
    }, [skill]);

    const handleInstructionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setInstructions(newValue);
        handleFormChange('instructions', newValue, skillIndex);
    };

    const handleRemoveChildSkill = useCallback(
        (childSkillIndex: number) => {
            setFormFields((prevFields) => {
                const newChildSkills = [...(prevFields.skills[skillIndex].childSkills || [])];
                const removedSkill = newChildSkills[childSkillIndex];
                newChildSkills.splice(childSkillIndex, 1);
                const newSkills = [...prevFields.skills];
                newSkills[skillIndex].childSkills = newChildSkills;
                setSelectedChildSkills((prevSelectedSkills) =>
                    prevSelectedSkills.filter((skill) => skill !== removedSkill),
                );
                return {
                    ...prevFields,
                    skills: newSkills,
                };
            });
        },
        [setFormFields, skillIndex, skill.childSkills],
    );

    return (
        <>
            <Field id={`instructions-${skillIndex}`} label="Instructions" required>
                <Textarea
                    value={instructions}
                    onChange={handleInstructionsChange}
                    placeholder="Instructions"
                />
            </Field>
            <Button
                className={classes.addButton}
                icon={<AddIcon />}
                onClick={() => {
                    setFormFields((prevFields) => {
                        const newSkills = [...prevFields.skills];
                        newSkills[skillIndex].childSkills = [
                            ...(newSkills[skillIndex].childSkills || []),
                            '',
                        ];
                        return {...prevFields, skills: newSkills};
                    });
                }}
            >
                Child Skill
            </Button>
            {skill.childSkills?.map((childSkill, childSkillIndex) => (
                <div key={childSkillIndex}>
                    <Field
                        id={`childSkill-${skillIndex}-${childSkillIndex}`}
                        label={`Child Skill ${childSkillIndex + 1}`}
                        required={true}
                    >
                        <div className={classes.row}>
                            <Combobox
                                placeholder="Choose a child skill"
                                onOptionSelect={(_, data) => {
                                    handleFormChange(
                                        'childSkills',
                                        data.optionText ?? '',
                                        skillIndex,
                                        undefined,
                                        childSkillIndex,
                                    );
                                    setSelectedChildSkills((prevSelectedSkills) => [
                                        ...prevSelectedSkills,
                                        data.optionText ?? '',
                                    ]);
                                    setFilteredSkills(
                                        uniqueSkills.filter(
                                            (skill) => !selectedChildSkills.includes(skill),
                                        ),
                                    );
                                }}
                                onChange={(e) => {
                                    const searchResults = allSkillsList.search(e.target.value);
                                    setFilteredSkills(searchResults.map((result) => result.item));
                                    handleFormChange(
                                        'childSkills',
                                        e.target.value,
                                        skillIndex,
                                        undefined,
                                        childSkillIndex,
                                    );
                                }}
                                value={childSkill}
                            >
                                {filteredSkills?.map((skill) => (
                                    <Option key={skill}>{skill}</Option>
                                ))}
                            </Combobox>
                            <Button
                                className={classes.inlineRemoveButton}
                                icon={<DeleteIcon />}
                                onClick={() => handleRemoveChildSkill(childSkillIndex)}
                            ></Button>
                        </div>
                    </Field>
                </div>
            ))}
        </>
    );
}
