import {DismissRegular} from '@fluentui/react-icons';
import {useState} from 'react';
import {
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarTitle,
    Button,
    Link,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import useClasses from './MessageBar.styles';

interface CapacityWarningMessageBarProps {
    capacityName: string;
}

export const CapacityWarningMessageBar: React.FC<CapacityWarningMessageBarProps> = ({
    capacityName,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(true);
    const handleDismiss = () => {
        setIsMessageBarVisible(false);
    };
    return (
        <>
            {isMessageBarVisible && (
                <div className={classes.messageBarContainer}>
                    <MessageBar key="warning" layout="singleline" intent="warning">
                        <MessageBarBody className={classes.messageBarBody}>
                            <MessageBarTitle className={classes.title}>
                                {t('ManageWorkspaces.WarningMessageBars.CapacityTitle', {
                                    0: capacityName,
                                })}
                            </MessageBarTitle>
                            {t('ManageWorkspaces.WarningMessageBars.CapacityBody')}
                            <Link
                                href={MedeinaVariables.ManageCapacityAzureUri}
                                className={classes.link}
                                appearance="subtle"
                                inline={true}
                                target="_blank"
                            >
                                {t('ManageWorkspaces.WarningMessageBars.DeleteCapacityLink')}
                            </Link>
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    aria-label={t('ManageWorkspaces.DismissButtonAriaLabel')}
                                    appearance="transparent"
                                    onClick={handleDismiss}
                                    icon={<DismissRegular />}
                                />
                            }
                        ></MessageBarActions>
                    </MessageBar>
                </div>
            )}
        </>
    );
};
