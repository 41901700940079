import {forwardRef, RefObject, useEffect, useRef, useState, useMemo} from 'react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    MenuList,
    Popover,
    PopoverSurface,
    PositioningImperativeRef,
    Button,
    Input,
    Tooltip,
    Divider,
} from '@fluentui/react-components';
import {NavigateBackIcon, SearchIcon, DismissIcon} from '@/components/ui/icons';
import {PromptBarMenuListType, PromptBarMenuProps} from './PromptBarMenu.types';
import useClasses from './PromptBarMenu.styles';
import PromptBarMenuCapabilities from './PromptBarMenuCapabilities';
import PromptBarMenuPromptbooks from './PromptBarMenuPromptbooks';
import usePromptBarMenuCapabilitiesList from './usePromptBarMenuCapabilitiesList';
import usePromptBarMenuPromptbooksList from './usePromptBarMenuPromptbooksList';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import AnnounceLabel from '../AnnounceText/AnnounceText';
import PromptBarMenuAgents from './PromptBarMenuAgents';

export * from './PromptBarMenu.types';

const SEARCH_LIMIT = 3;

const PromptBarMenu = forwardRef<HTMLDivElement, PromptBarMenuProps>(
    (
        {
            open = false,
            skillsets,
            onSkillSelect,
            onPromptbookSelect,
            onHide,
            agentView = false,
            directSkillMenu,
            menuHeight,
        },
        inputWrapperRef,
    ) => {
        const classes = useClasses();
        const {t} = useTranslation('promptbar');
        const {t: tCommon} = useTranslation('common');
        const [filter, setFilter] = useState<string>('');
        const [showAll, setShowAll] = useState<PromptBarMenuListType | null>(null);
        const {sm: isSmallScreen} = useViewportSize();
        const promptRef = useRef<HTMLDivElement | null>(null);

        // Pre-cache the menu items, since otherwise they won't be retrieved until menu toggle.
        // DO NOT REMOVE THESE, despite them not being actively used.
        const precachedCapabilities = usePromptBarMenuCapabilitiesList({
            filter,
            skillsets,
            limit: SEARCH_LIMIT,
        });
        const precachedPromptbooks = usePromptBarMenuPromptbooksList({
            filter,
            skillsets,
            limit: SEARCH_LIMIT,
        });

        const capabilitiesFilteredList = precachedCapabilities.filteredList;
        const promptbooksFilteredList = precachedPromptbooks.filteredList;

        const searchResultText = useMemo(() => {
            if (filter) {
                const total = capabilitiesFilteredList.length + promptbooksFilteredList.length;
                if (total === 1) {
                    return t('OneTotalResultText', {resultCount: total});
                } else if (total > 1) {
                    return t('nTotalResultsText', {resultCount: total});
                } else if (total === 0) {
                    return t('noResultsText');
                }
            } else {
                return '';
            }
        }, [filter, promptbooksFilteredList, capabilitiesFilteredList, t]);

        // Navigate from a show all list back to the main search.
        function navigateBack() {
            setShowAll(null);
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 0);
        }

        // Bind the slash menu positioning to the input wrapper
        const positioningRef = useRef<PositioningImperativeRef>(null);
        const shouldRepositionMenu = () => {
            const input = (inputWrapperRef as RefObject<HTMLDivElement>)?.current;
            if (input) {
                positioningRef.current?.setTarget(input);
            }
        };
        useEffect(shouldRepositionMenu, [
            positioningRef,
            inputWrapperRef,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            (inputWrapperRef as RefObject<HTMLTextAreaElement>)?.current,
        ]);

        // Contextual data and handlers for child components.
        const childProps = {
            limit: SEARCH_LIMIT,
            filter,
            skillsets,
            showAll,
            shouldRepositionMenu,
        };

        // When the user hits escape we want to toggle the menu closed
        useEffect(() => {
            const handleKeyDown = (ev: KeyboardEvent) => {
                if (ev.key === 'Escape') {
                    onHide?.();
                }
            };
            document.addEventListener('keydown', handleKeyDown);
            return () => document.removeEventListener('keydown', handleKeyDown);
        }, [onHide]);

        // backButtonRef is a reference to the "Back" button element.This ref is used to programmatically set focus to the "Back" button when needed,
        // such as when navigating back from viewing all capabilities to the main menu.
        const backButtonRef = useRef<HTMLButtonElement>(null);

        // searchInputRef is a reference to the search input element.This ref is used to programmatically set focus to the search input when the menu is opened.
        const searchInputRef = useRef<HTMLInputElement>(null);

        // Focus the search field initially.
        useEffect(
            () => {
                if (open && searchInputRef.current) {
                    searchInputRef.current.focus();
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [open, searchInputRef.current],
        );

        // Reset the filter on close.
        useEffect(() => {
            if (!open) {
                setFilter('');
            }
        }, [open]);

        // Focus the back button after toggling a show all.
        useEffect(() => {
            if (open && showAll !== null) {
                backButtonRef.current?.focus();
            }
        }, [open, showAll, backButtonRef]);

        return (
            <Popover
                inline={!isSmallScreen}
                open={open}
                positioning={{positioningRef, offset: 4, align: 'end'}}
                onOpenChange={(e, data) => {
                    if (data.open === false) {
                        onHide?.();
                        navigateBack();
                    }
                }}
            >
                <PopoverSurface
                    className={directSkillMenu ? classes.directskillsMenu : classes.menu}
                    tabIndex={-1}
                    ref={promptRef}
                    style={directSkillMenu ? {maxHeight: menuHeight} : {}}
                    aria-live="polite"
                    data-testid="promptbarmenu"
                >
                    {searchResultText && (
                        <AnnounceLabel visuallyHidden ariaLive="polite">
                            {searchResultText}
                        </AnnounceLabel>
                    )}
                    <div className={classes.navbar}>
                        {showAll && (
                            <Button
                                tabIndex={1}
                                appearance="subtle"
                                size="small"
                                onClick={() => navigateBack()}
                                className={classes.navigateBackIcon}
                                icon={<NavigateBackIcon />}
                                aria-label={tCommon('Back')}
                                ref={backButtonRef}
                            />
                        )}
                        <Input
                            tabIndex={2}
                            ref={searchInputRef}
                            className={classes.searchbar}
                            type="search"
                            placeholder={tCommon('Search')}
                            value={filter}
                            onChange={(ev: any) => {
                                setFilter(ev.target.value);
                            }}
                            contentBefore={<SearchIcon />}
                            appearance="underline"
                            // contentAfter={
                            //     <Tooltip content={tCommon('ClearSearch')} relationship="label">
                            //         <Button
                            //             tabIndex={3}
                            //             onClick={() => setFilter('')}
                            //             className={classes.subButton}
                            //             size="small"
                            //             shape="circular"
                            //             appearance="outline"
                            //             icon={<DismissIcon />}
                            //             data-testid="promptbarmenu-clear-search"
                            //         />
                            //     </Tooltip>
                            // }
                        />
                        {isSmallScreen && (
                            <Tooltip content={tCommon('ClearSearch')} relationship="label">
                                <Button
                                    tabIndex={3}
                                    onClick={() => {
                                        onHide?.();
                                        navigateBack();
                                    }}
                                    className={classes.subButton}
                                    size="small"
                                    shape="circular"
                                    appearance="outline"
                                    icon={<DismissIcon />}
                                    data-testid="promptbarmenu-clear-search"
                                />
                            </Tooltip>
                        )}
                    </div>
                    {!filter && !showAll && !directSkillMenu && (
                        <div className={classes.getStarted}>{t('GetStarted')}</div>
                    )}
                    {!agentView && (
                        <MenuList hasIcons>
                            {!directSkillMenu && (
                                <PromptBarMenuPromptbooks
                                    {...childProps}
                                    onSelect={onPromptbookSelect}
                                    onShowAll={() => setShowAll(PromptBarMenuListType.Promptbooks)}
                                    tabIndex={4}
                                />
                            )}
                            {!showAll && !directSkillMenu && <Divider />}
                            <PromptBarMenuCapabilities
                                {...childProps}
                                menuHeight={menuHeight}
                                onSelect={onSkillSelect}
                                directSkillMenu={directSkillMenu}
                                onShowAll={() =>
                                    setShowAll(PromptBarMenuListType.SystemCapabilities)
                                }
                                tabIndex={4}
                            />
                        </MenuList>
                    )}
                    {agentView && (
                        <MenuList hasIcons>
                            <PromptBarMenuAgents
                                {...childProps}
                                onSelect={onSkillSelect}
                                agentView={agentView}
                                onShowAll={() => setShowAll(PromptBarMenuListType.Agents)}
                                tabIndex={4}
                            />
                        </MenuList>
                    )}
                </PopoverSurface>
            </Popover>
        );
    },
);

PromptBarMenu.displayName = 'PromptBarMenu';
export default PromptBarMenu;
