import {ParagraphContent} from '@/components/ui/Text';
import MedeinaVariables from '@/util/variables';
import {
    USAGE_MONITORING_DESCRIPTION_WORKSPACES,
    USAGE_MONITORING_DESCRIPTION,
    LEARN_MORE_LINK,
} from './UsageDashboard.constants';
import UsageDashboardTitle from './UsageDashboardTitle';
import {Link} from '@fluentui/react-components';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';
import {useTranslation} from 'react-i18next';
import useClasses from './UsageDashboard.styles';

interface UsageDashboardHeadingProps {
    lastUpdatedAt: Date | number;
}

export default function UsageDashboardHeading(props: UsageDashboardHeadingProps) {
    const {t: tAdmin} = useTranslation('admin');
    const classes = useClasses();

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    return (
        <>
            <UsageDashboardTitle lastUpdatedAt={props.lastUpdatedAt} />
            <div className={classes.infoContainer}>
                <ParagraphContent data-testid="dashboard-content">
                    {isWorkspacesTestingEnabled
                        ? tAdmin(USAGE_MONITORING_DESCRIPTION_WORKSPACES)
                        : tAdmin(USAGE_MONITORING_DESCRIPTION)}{' '}
                    <Link href={MedeinaVariables.ManageCapacityLearnUri} target="_blank">
                        {tAdmin(LEARN_MORE_LINK)}
                    </Link>
                </ParagraphContent>
            </div>
        </>
    );
}
