export const MSW_NAMESPACE = 'medeina_msw';

export const MedeinaScenariosMap = {
    helloWorld: 'helloWorld',
    multiWorkspaces: 'multiWorkspaces',
    default: 'default',
    FRESetupForm: 'FRESetupForm',
    usageDashboardV2: 'usageDashboardV2',
    owner: 'owner',
    contributor: 'contributor',
    FREGANewTenant: 'FREGANewTenant',
    FREPodNewTenant: 'FREPodNewTenant',
};

// These map to the scenario handlers in the scenarioHandlers directory
// the key is the scenario name and the value is the file name
export const MedeinaScenarios = Object.keys(MedeinaScenariosMap).map((key) => key);

export const ScenarioFeatureFlagMap: Record<string, string> = {
    // workspaces is the only feature that has a scenario associated with it
    WorkspacesEnabled: MedeinaScenariosMap.multiWorkspaces,

    // hello world is an api demo
    HelloWorld: MedeinaScenariosMap.helloWorld,

    FRESetupForm: MedeinaScenariosMap.FRESetupForm,
    /** Testing of the full FRE flow, GA version prior to geo/pod changes*/
    FREGANewTenant: MedeinaScenariosMap.FREGANewTenant,
    ViewUsageDashboardV2: MedeinaScenariosMap.usageDashboardV2,
    FREPodNewTenant: MedeinaScenariosMap.FREPodNewTenant,
};
