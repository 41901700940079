import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        columnGap: tokens.spacingHorizontalM,
        paddingBottom: tokens.spacingVerticalM,
        ...shorthands.padding('14px', tokens.spacingHorizontalXXXL),
        paddingTop: tokens.spacingVerticalS,
        gridRowGap: tokens.spacingHorizontalXL,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    addButton: {
        ...shorthands.margin('10px', '10px', '10px', '0px'),

        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightRegular,
    },
    inlineRemoveButton: {
        ...shorthands.margin('0px', '10px', '10px', '10px'),
        ...shorthands.border('0px'),
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightRegular,
    },
    removeButton: {
        ...shorthands.margin('10px', '10px', '10px', '10px'),
        ...shorthands.border('0px'),
        fontFamily: tokens.fontFamilyBase,
        // backgroundColor: customTokens.securitySourcesBackground,
        fontWeight: tokens.fontWeightRegular,
    },
    editorToolbar: {
        rowGap: tokens.spacingVerticalMNudge,
        columnGap: tokens.spacingHorizontalS,
        padding: tokens.spacingHorizontalS,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: tokens.spacingVerticalM,
    },
});
