import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        columnGap: tokens.spacingHorizontalXL,
        width: '100%',
    },
    investigationRoot: {
        flexDirection: 'row',
    },
    session: {
        ...shorthands.flex(1, 1, 'auto'),
    },
    message: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 'auto'),
        maxWidth: '1100px',
        width: '100%',
    },
    skillsetCreator: {
        ...shorthands.margin('20px', '20px', '60px', '20px'),
        maxHeight: '90vh',
    },
});
