import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {Body1Strong, Link, mergeClasses} from '@fluentui/react-components';
import {InvestigationsIcon} from '@/components/ui/icons';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useClasses from './AuditLoggingConsent.styles';
import {useRef} from 'react';
import {motion} from 'framer-motion';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {useTranslation} from 'react-i18next';
import {TextH1} from '@/components/ui/Text';
import MedeinaVariables from '@/util/variables';
import AuditLoggingOptingSwitch from '@/components/admin/AuditLoggingOptingSwitch';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export default function AuditLoggingConsent() {
    const tour = useTourContext();
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const {t: tAdmin} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    const auditLoggingInfoRootRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <motion.div
                key="audit-logging-info"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourStageContainer ref={auditLoggingInfoRootRef}>
                    <TourStageContainer.StageContent>
                        <div className={mergeClasses(classes.icon)}>
                            <InvestigationsIcon />
                        </div>
                        <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                            <TextH1>{t('adminV2Fre.FREAuditLoggingOpting.Title')}</TextH1>
                        </div>
                        <div className={mergeClasses(tourClasses.tourXLargeSectionSpacing)}>
                            <div className={classes.description}>
                                {t('adminV2Fre.FREAuditLoggingOpting.Description')}{' '}
                            </div>
                            <Body1Strong role="heading">
                                {t('adminV2Fre.FREAuditLoggingOpting.StorageSubheading')}
                            </Body1Strong>
                            <div className={classes.description}>
                                {t('adminV2Fre.FREAuditLoggingOpting.DescriptionDataStore')}{' '}
                                <Link href={MedeinaVariables.DataResidency} target="_blank">
                                    {tAdmin(
                                        'ownerSettings.auditLoggingOpting.LearnMoreDataResidencyLinkText',
                                    )}
                                </Link>
                            </div>
                            <Body1Strong role="heading">
                                {t('adminV2Fre.FREAuditLoggingOpting.SetupSubheading')}
                            </Body1Strong>
                            <div className={classes.description}>
                                {t('adminV2Fre.FREAuditLoggingOpting.DescriptionProvision')}{' '}
                                <Link
                                    href={MedeinaVariables.ProvisioningAuditLogging}
                                    target="_blank"
                                >
                                    {tAdmin(
                                        'ownerSettings.auditLoggingOpting.LearnMoreProvisioningLinkText',
                                    )}
                                </Link>
                            </div>
                            <div className={tourClasses.switchLayout}>
                                <AuditLoggingOptingSwitch includeInfoLabel={false} />
                            </div>
                            <div className={classes.description}>
                                {isWorkspacesTestingEnabled
                                    ? t('adminV2Fre.FREAuditLoggingOpting.WorkspacesYouCanChange')
                                    : t('adminV2Fre.FREAuditLoggingOpting.YouCanChange')}
                            </div>
                        </div>
                    </TourStageContainer.StageContent>
                    <TourStageContainer.FooterContent>
                        <TourControls
                            spanFullScreen={true}
                            handlePrevious={tour.prevStop}
                            handleNext={tour.nextStop}
                            previousButton={commonLabel('Back')}
                            nextButton={commonLabel('Continue')}
                            showPrevious={!tour.currentStop?.isFirstStop}
                        ></TourControls>
                    </TourStageContainer.FooterContent>
                </TourStageContainer>
            </motion.div>
        </>
    );
}
