import {UserState} from './user.types';

export const LOCAL_STORAGE_NAMESPACE = 'UserState';

export const DEFAULT_USER_STATE: UserState = {
    showDailyTip: false,
    tenant: {
        tenantId: 'common',
        displayName: 'common',
    },
    isAdmin: false,
    isGlobalAdmin: false,
    isOperator: false,
    featureFlags: [],
    preferences: {
        locale: {
            languageCode: '',
            displayLanguage: '',
        },
        userId: '',
        tenantId: '',
        timezone: '',
        currentWorkspace: '',
        preferredEmbeddedWorkspace: '',
    },
    useBrowserLanguage: true,
    useBrowserTime: true,
    isEmailTriggerAllowed: true,
};
