import * as React from 'react';
import {Button, Combobox, Field, Option, Persona, Spinner, useId} from '@fluentui/react-components';
import type {ComboboxProps} from '@fluentui/react-components';
import useGetUserProfiles from '@/api/user/useGetUserProfiles';
import {Dismiss12Regular} from '@fluentui/react-icons';
import {UserProfile} from '@/api/user/user.types';
import {useEffect} from 'react';
import useClasses from './RoleMembersSelect.styles';
import PersonaElementWorkspaces from './PersonaElementWorkspaces';
import useDebounce from '@/util/useDebounce';
import {motion} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import {useMsal} from '@azure/msal-react';

interface RoleMembersSelectProps extends Partial<ComboboxProps> {
    sendDataToParent: (data: any) => void;
    isOwnersSelect: boolean;
}

export default function RoleMembersSelect(props: RoleMembersSelectProps) {
    const comboId = useId('combo-default');
    const selectedListId = `${comboId}-selection`;
    const classes = useClasses();
    const {t} = useTranslation('session');
    const {t: tAdmin} = useTranslation('admin');
    // refs for managing focus when removing tags
    const selectedListRef = React.useRef<HTMLUListElement>(null);
    const comboboxInputRef = React.useRef<HTMLInputElement | null>(null);
    // get current user info
    const {instance} = useMsal();
    const activeAccount = instance.getActiveAccount();
    const currentUserProfile: UserProfile = {
        id: activeAccount?.homeAccountId || '',
        mail: activeAccount?.username || '',
        displayName: activeAccount?.name || '',
        jobTitle: '',
        photo: activeAccount?.name || '',
    };

    // Helper function to construct the remove string
    const constructRemoveString = (comboId: string, index?: number): string => {
        return index !== undefined ? `${comboId}-remove-${index}` : `${comboId}-remove`;
    };

    // State for selected user profiles and search query
    const [selectedUserProfiles, setSelectedUserProfiles] = React.useState<UserProfile[]>(
        props.isOwnersSelect ? [currentUserProfile] : [],
    );
    const [userProfileSearch, setUserProfileSearch] = React.useState<string>('');
    const debouncedSearch = useDebounce(userProfileSearch, 300);

    // Get user profiles based on userProfileSearch
    const {
        data: userProfiles,
        refetch: fetchProfiles,
        isLoading,
    } = useGetUserProfiles(debouncedSearch);

    const handleObjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setUserProfileSearch(value);
    };

    // Fetch userprofiles only when userProfileSearch is not empty
    useEffect(() => {
        if (userProfileSearch && fetchProfiles) {
            // Check if fetchProfiles is not null
            fetchProfiles();
        }
    }, [userProfileSearch, fetchProfiles]);

    // Focus the Combobox input element when the component mounts
    useEffect(() => {
        if (comboboxInputRef.current) {
            comboboxInputRef.current.focus();
        }
    }, []);

    // Update the selected userProfiles in the Combobox.
    const onSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
        const matchingProfiles = userProfiles!.find((o) => o.mail === data.optionValue);
        if (matchingProfiles) {
            if (selectedUserProfiles.some((profile) => profile.mail === matchingProfiles.mail)) {
                // Deselect the profile, but prevent deselecting the current user
                if (props.isOwnersSelect && matchingProfiles.mail === currentUserProfile.mail) {
                    return;
                }
                const updatedSelectedUserProfiles = selectedUserProfiles.filter(
                    (profile) => profile.mail !== matchingProfiles.mail,
                );
                setSelectedUserProfiles(updatedSelectedUserProfiles);
                props.sendDataToParent(updatedSelectedUserProfiles);
            } else {
                // Select the profile
                const updatedSelectedUserProfiles = [...selectedUserProfiles, matchingProfiles];
                setSelectedUserProfiles(updatedSelectedUserProfiles);
                props.sendDataToParent(updatedSelectedUserProfiles);
            }
        }
    };

    // Modifying the tag when clicked and updating the selected users
    const onTagClick = (profile: UserProfile, index: number) => {
        // Prevent removing the current user if isOwnersSelect is true
        if (props.isOwnersSelect && profile.mail === currentUserProfile.mail) {
            return;
        }

        // Remove selected profile
        const updatedSelectedUserProfiles = selectedUserProfiles.filter(
            (o) => o.mail !== profile.mail,
        );
        setSelectedUserProfiles(updatedSelectedUserProfiles);
        props.sendDataToParent(updatedSelectedUserProfiles);

        // Focus previous or next option, defaulting to focusing back to the combo input
        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${constructRemoveString(comboId, indexToFocus)}`,
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
    };

    return (
        <Field
            label={
                props.isOwnersSelect
                    ? tAdmin('Workspaces.Form.Owners')
                    : tAdmin('Workspaces.Form.Contributors')
            }
        >
            {selectedUserProfiles.length ? (
                <ul
                    data-testid={props.isOwnersSelect ? 'selected-owners' : 'selected-contributors'}
                    id={selectedListId}
                    className={classes.tagsList}
                    ref={selectedListRef}
                >
                    {/* The "Remove" span is used for naming the buttons without affecting the Combobox name */}
                    <span id={constructRemoveString(comboId)} hidden>
                        {t('CloseButton')}
                    </span>
                    {selectedUserProfiles.map((profile, i) => (
                        <li key={profile.mail}>
                            <Button
                                size="small"
                                shape="circular"
                                appearance="primary"
                                icon={<Dismiss12Regular />}
                                iconPosition="after"
                                onClick={() => onTagClick(profile, i)}
                                id={constructRemoveString(comboId, i)}
                                aria-labelledby={`${constructRemoveString(
                                    comboId,
                                )} ${constructRemoveString(comboId, i)}`}
                            >
                                <PersonaElementWorkspaces profile={profile} />
                            </Button>
                        </li>
                    ))}
                </ul>
            ) : null}
            <Combobox
                data-testid={
                    props.isOwnersSelect
                        ? 'create-workspace-owners-peoplepicker'
                        : 'create-workspace-constributors-peoplepicker'
                }
                aria-labelledby={comboId}
                freeform
                placeholder={tAdmin('Workspaces.Form.StartTypingName')}
                onChange={handleObjectChange}
                onOptionSelect={onSelect}
                multiselect={true}
                selectedOptions={selectedUserProfiles.map((profile) => profile.mail)}
                ref={comboboxInputRef}
                autoComplete="off"
                {...props}
            >
                {isLoading && (
                    <motion.div
                        className={classes.loader}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0, duration: 0.3}}
                        exit={{opacity: 0, transition: {duration: 0.3}}}
                        key="Loading"
                    >
                        <Spinner labelPosition="after" label={t('LoadingText')} />
                    </motion.div>
                )}
                {userProfiles?.map((profile) => (
                    <Option
                        data-testid={
                            props.isOwnersSelect
                                ? 'userlist-owners-select'
                                : 'userlist-contributors-select'
                        }
                        key={profile.id}
                        value={profile.mail}
                        text={profile.displayName}
                    >
                        <div>
                            <PersonaElementWorkspaces profile={profile}></PersonaElementWorkspaces>
                        </div>
                    </Option>
                ))}
            </Combobox>
        </Field>
    );
}
