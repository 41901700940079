import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {DismissIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import useClasses from './DisconnectCapacityDialog.styles';

interface DisconnectCapacityDialogProps {
    title: string;
    descriptionShort?: string;
    description: string;
    switchButtonLabel: string;
    isDisconnectCapacityDialogOpen: boolean;
    workspaceName: string;
    newCapacityName: string;
    onClose: () => void;
    onConfirm: () => void;
}

export default function DisconnectCapacityDialog(props: DisconnectCapacityDialogProps) {
    const [isSetDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);
    const {t} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const classes = useClasses();

    const closeDisconnectCapacityDialog = () => {
        props.onClose();
    };

    const handleDisconnectClick = () => {
        props.onConfirm();
        closeDisconnectCapacityDialog();
    };

    useEffect(() => {
        setDisconnectCapacityDialogOpen(props.isDisconnectCapacityDialogOpen);
    }, [props.isDisconnectCapacityDialogOpen]);

    return (
        <>
            <Dialog open={isSetDisconnectCapacityDialogOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={t('ButtonLabels.Close')}
                                        icon={<DismissIcon />}
                                        onClick={closeDisconnectCapacityDialog}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <div className={classes.titleContainer}>
                                <div>{props.title}</div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={classes.contentSection}>
                            {props.descriptionShort && <div>{props.descriptionShort}</div>}
                            <div>{props.description}</div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                data-test-id="disconnect-capacity-button"
                                onClick={handleDisconnectClick}
                                aria-label={props.switchButtonLabel}
                                className={classes.disconnectButton}
                            >
                                {props.switchButtonLabel}
                            </Button>
                            <Button
                                data-test-id="cancel-button"
                                appearance="secondary"
                                aria-label={t('ButtonLabels.Cancel')}
                                onClick={closeDisconnectCapacityDialog}
                            >
                                {t('ButtonLabels.Cancel')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
}
