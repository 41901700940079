import {SearchCategory, UsageDashboardState} from './UsageDashboard.types';
import {
    useGetAggregatedCapacityUsageByDimensions,
    useGetUsageDataFacets,
    GetAggregatedCapacityUsageResponse,
    GetUsageDataFacetResponse,
    GetAggregatedCapacityUsageByDimensionsResponse,
    useGetHourlyUsageAggregateDataByDimensions,
} from '@/api/capacities';
import {useCallback, useEffect, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {
    GetAggregatedCapacityUsageParameters,
    SelectedSearchFacet,
} from '@/api/capacities/capacities.types';
import {DEFAULT_DATA_ORDER} from './UsageDashboard.constants';
interface ManageUsageDashboardDataSourcesProps {
    capacityName: string;
    state: UsageDashboardState;
}

interface ManageUsageDashboardDataSourcesResponse {
    hourlyDataModel: GetAggregatedCapacityUsageResponse | undefined;
    dimensionalDataModel: GetAggregatedCapacityUsageByDimensionsResponse | undefined;
    facetDataModel: GetUsageDataFacetResponse | undefined;
    isDataFacetsError: boolean;
    isHourlyCapacityUsageError: boolean;
    isDimensionalDataError: boolean;
    isDimensionalDataLoading: boolean;
    isDimensionalDataRefetching: boolean;
    dataFacetsLoading: boolean;
    hourlyCapacityUsageLoading: boolean;
    hourlyCapacityUsageDataUpdatedAt: number | undefined;
    isAggregateDataFetched: boolean;
    areSearchFacetsAvailable: boolean;
    fetchingMultipleDataSets: boolean;
}

export default function useManageUsageDashboardDataSources(
    props: ManageUsageDashboardDataSourcesProps,
): ManageUsageDashboardDataSourcesResponse {
    const [dimensionalData, setDimensionalData] =
        useState<GetAggregatedCapacityUsageByDimensionsResponse>();
    const [isAggregateDataFetched, setIsAggregateDataFetched] = useState(false);
    // This use state is used to track if the data is being fetched for multiple data sets, both the bar graph & the table
    const [fetchingMultipleDataSets, setFetchingMultipleDataSets] = useState(false);
    const [facetDataModel, setFacetDataModel] = useState<GetUsageDataFacetResponse>();
    const [areSearchFacetsAvailable, setAreSearchFacetsAvailable] = useState(false);

    const {
        data: dataFacets,
        isError: isDataFacetsError,
        isFetched: isDataFacetsFetched,
        isLoading: isDataFacetsLoading,
        isRefetching: isDataFacetsRefetching,
        isSuccess: isDataFacetsSuccess,
    } = useGetUsageDataFacets(
        {
            resourceName: props.capacityName || '',
            startDate: props.state.startDate.toISOString(),
            endDate: props.state.endDate.toISOString(),
        },
        {enabled: !!props.capacityName && props.state.needsFacetRefresh},
    );

    const DEFAULT_BODY: GetAggregatedCapacityUsageParameters = {
        startDate: props.state.startDate.toISOString(),
        endDate: props.state.endDate.toISOString(),
        users: [],
        experiences: [],
        invocationTypes: [],
        plugins: [],
        categories: [],
        invocationCategories: [],
        pageSize: props.state.pageSize,
        pageNumber: props.state.pageNumber,
        sortingDirection: props.state.sortDirection,
    };

    const [body, setBody] = useState<GetAggregatedCapacityUsageParameters>(DEFAULT_BODY);

    const {
        data: aggregatedCapacityUsageByDimensionsData,
        isSuccess: isDimensionalDataSuccess,
        isLoading: isDimensionalDataLoading,
        isRefetching: isDimensionalDataRefetching,
        isError: isDimensionalDataError,
    } = useGetAggregatedCapacityUsageByDimensions(
        {
            resourceName: props.capacityName || '',
            body,
        },
        {enabled: !!props.capacityName && props.state.needsDimensionalDataRefresh},
    );

    const {
        data: hourlyCapacityUsageEventsByDimensionsData,
        isLoading: isHourlyCapacityUsageByDimensionsLoading,
        isFetched: isHourlyCapacityUsageByDimensionsFetched,
        isRefetching: isHourlyCapacityUsageByDimensionsRefetching,
        isError: isHourlyCapacityUsageByDimensionsError,
        dataUpdatedAt: hourlyCapacityUsageByDimensionsDataUpdatedAt,
        isSuccess: isHourlyCapacityUsageByDimensionsSuccess,
    } = useGetHourlyUsageAggregateDataByDimensions(
        {
            capacityResourceName: props.capacityName || '',
        },
        body,
        {
            enabled: !!props.capacityName && props.state.needsHourlyDataRefresh,
        },
    );

    const hourlyCapacityUsageByDimensionsLoading =
        !isHourlyCapacityUsageByDimensionsFetched || isHourlyCapacityUsageByDimensionsLoading;

    const queryClient = useQueryClient();

    const dataFacetsLoading = !isDataFacetsFetched || isDataFacetsRefetching || isDataFacetsLoading;

    const populateFacets = useCallback((searchFacets: SelectedSearchFacet[]) => {
        body.users = [];
        body.experiences = [];
        body.invocationTypes = [];
        body.plugins = [];
        body.invocationCategories = [];

        searchFacets?.forEach((facet) => {
            switch (facet.category) {
                case SearchCategory.CopilotExperience:
                    body.experiences = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.InvocationCategory:
                    body.invocationCategories = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.InvocationType:
                    body.invocationTypes = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.PluginUsed:
                    body.plugins = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.Users:
                    body.users = facet.values.map((value) => value.toString());
                default:
                    break;
            }
        });
    }, []);

    // This is to ensure that the loading spinner is shown until all the data is fetched
    useEffect(() => {
        if (
            !isDimensionalDataLoading &&
            !isDimensionalDataRefetching &&
            !isHourlyCapacityUsageByDimensionsRefetching &&
            !isHourlyCapacityUsageByDimensionsLoading
        ) {
            setFetchingMultipleDataSets(false);
        }
    }, [
        isDimensionalDataLoading,
        isDimensionalDataRefetching,
        isDimensionalDataSuccess,
        isHourlyCapacityUsageByDimensionsLoading,
        isHourlyCapacityUsageByDimensionsRefetching,
        isHourlyCapacityUsageByDimensionsSuccess,
    ]);

    // This ensure that sorting direction gets updated
    // even if there is only a row worth of data
    useEffect(() => {
        const hasFetchingCompleted = !isDimensionalDataLoading && !isDimensionalDataRefetching;
        if (!isDimensionalDataLoading && !isDimensionalDataRefetching) {
            setIsAggregateDataFetched(true);
        }

        if (hasFetchingCompleted && isDimensionalDataSuccess) {
            setDimensionalData(aggregatedCapacityUsageByDimensionsData);
        } else {
            setDimensionalData(undefined);
        }
    }, [isDimensionalDataLoading, isDimensionalDataRefetching, isDimensionalDataSuccess]);

    useEffect(() => {
        const hasFetchingCompleted = !isDataFacetsLoading && !isDataFacetsRefetching;
        if (hasFetchingCompleted && isDataFacetsSuccess) {
            setFacetDataModel(dataFacets);
        }
    }, [isDataFacetsLoading, isDataFacetsRefetching, isDataFacetsSuccess]);

    useEffect(() => {
        const searchFacets = props.state.searchFilters;
        populateFacets(searchFacets);
        body.startDate = props.state.startDate.toISOString();
        body.endDate = props.state.endDate.toISOString();
        body.pageSize = props.state.pageSize;
        body.pageNumber = props.state.pageNumber;
        body.sortingDirection = props?.state?.sortDirection ?? DEFAULT_DATA_ORDER;
        setBody(body);
    }, [props.state]);

    useEffect(() => {
        var areFacetsAvailable = false;

        if ((dataFacets?.value?.length ?? 0) !== 0) {
            for (var i = 0; i < (dataFacets?.value?.length ?? 0); i++) {
                areFacetsAvailable = (dataFacets?.value[i].values?.length ?? 0) > 0;
                if (areFacetsAvailable) {
                    areFacetsAvailable = true;
                    break;
                }
            }
        }
        setAreSearchFacetsAvailable(areFacetsAvailable);
    }, [dataFacets]);

    useEffect(() => {
        if (props.state.needsFacetRefresh) {
            queryClient.refetchQueries(
                [
                    'usage-facets',
                    props.capacityName,
                    props.state.startDate.toISOString(),
                    props.state.endDate.toISOString(),
                ],
                {exact: true},
            );
        }

        if (props.state.needsHourlyDataRefresh && !props.state.isHourlyBarActive) {
            queryClient.refetchQueries(
                ['hourly-capacity-events-aggregate-by-dimensions', props.capacityName],
                {exact: true},
            );
        }

        if (props.state.needsDimensionalDataRefresh) {
            setIsAggregateDataFetched(false);
            queryClient.refetchQueries(['dimensions', props.capacityName], {exact: true});
        }

        if (props.state.needsDimensionalDataRefresh && props.state.needsHourlyDataRefresh) {
            setFetchingMultipleDataSets(true);
        }
    }, [
        props.state.searchFilters,
        props.state.startDate,
        props.state.endDate,
        props.state.pageNumber,
        props.state.sortDirection,
    ]);

    return {
        hourlyDataModel: hourlyCapacityUsageEventsByDimensionsData,
        dimensionalDataModel: dimensionalData,
        facetDataModel: facetDataModel,
        isDataFacetsError,
        isHourlyCapacityUsageError: isHourlyCapacityUsageByDimensionsError,
        isDimensionalDataError,
        dataFacetsLoading,
        isDimensionalDataLoading,
        isDimensionalDataRefetching,
        hourlyCapacityUsageLoading: hourlyCapacityUsageByDimensionsLoading,
        hourlyCapacityUsageDataUpdatedAt: hourlyCapacityUsageByDimensionsDataUpdatedAt,
        isAggregateDataFetched,
        fetchingMultipleDataSets,
        areSearchFacetsAvailable,
    };
}
