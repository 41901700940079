import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import useFetch from '../useFetch';
export default function useGetPreferredEmbeddedWorkspace(options?: UseQueryOptions<string>) {
    const {customFetch} = useFetch();
    const getPreferredEmbeddedWorkspace = async () => {
        try {
            return await customFetch<string>(`/userPreferences/preferredEmbeddedWorkspace`, {
                method: 'GET',
            });
        } catch (error) {
            throw error;
        }
    };
    return useQuery({
        queryFn: getPreferredEmbeddedWorkspace,
        queryKey: ['userPreferences', 'preferredEmbeddedWorkspace'],
        // we only want to fetch the user preferences once per session
        // so we set staleTime to Infinity
        staleTime: Infinity,
        // don't refetch the user preferences when the window regains focus
        refetchOnWindowFocus: false,
        // don't refetch the user preferences when the user is idle
        refetchOnMount: false,
    });
}
