import {PromptBarSubmitOptions} from '@/components/ui';

export interface ExploreCopilotProps {
    onSubmit?: ({content, skillsets, featureFlags}: PromptBarSubmitOptions) => void;
    className?: string;
}

export interface ExploreCardProps extends ExploreCopilotProps {}

export interface ExploreCardListProps {
    children: React.ReactNode;
    className?: string;
}

export enum ExploreTab {
    Prompts = 'prompts',
    Promptbooks = 'promptbooks',
}

//Need to hard code the values for now as the API gives only the role's Display Name
export enum PersonaRoleMapping {
    CISO = 'Chief Information Security Officer (CISO)',
    SOCAnalyst = 'SOC Analyst',
    ThreatIntelAnalyst = 'Threat Intel Analyst',
    ITAdmin = 'IT Administrator',
    IdentityAdmin = 'Identity Administrator',
    DataSecurityAdmin = 'Data Security Administrator',
    CloudAdmin = 'Cloud Administrator',
}
