import {WorkspaceFormDialogProps} from './WorkspaceForm.types';
import React, {useMemo, useRef} from 'react';
import {DialogSurface} from '@fluentui/react-components';
import useClasses from './WorkspaceForm.styles';
import {MultiViewDialog, ViewConfiguration, DialogViewInputs} from '@/components/ui/Dialog';
import CreateEdit from './CreateEdit';
import Delete from './Delete';
import {useTranslation} from 'react-i18next';

/** The integration component for the different workspace forms
 * that handles the different views for creating, editing, and deleting workspaces
 * and manages the actual data submission and deletion
 * as well as telemetry and error handling
 * @param props the props for the component
 * @returns the WorkspaceForm component
 */
function WorkspaceForm(props: WorkspaceFormDialogProps) {
    const {
        mode,
        workspace,
        onClose,
        hideCapacitySelectField: hideCapacitySelectField,
        onWorkspaceCreated,
    } = props;
    const classes = useClasses();
    const dialogRef: React.RefObject<DialogViewInputs> = useRef(null);
    const {t} = useTranslation('admin');

    const DeleteViewConfiguration = useMemo(() => {
        return {
            value: 'delete',
            view: (props: DialogViewInputs) => (
                <Delete mode="delete" onClose={onClose} workspace={workspace} />
            ),
            title: {
                title: t('Workspaces.Form.Titles.Delete', {workspaceName: workspace?.name}),
                showBackButton: false,
            },
        };
    }, [t, workspace, onClose]);

    const viewConfiguration: ViewConfiguration = useMemo(() => {
        let configuration: ViewConfiguration = [];

        // depending on the mode that the form is in, we will load in different views
        if (mode === 'create') {
            // in create mode we only need the create view
            configuration = [
                {
                    value: 'create',
                    view: (props: DialogViewInputs) => (
                        <CreateEdit
                            mode="create"
                            onClose={props.onClose}
                            hideCapacitySelectField={hideCapacitySelectField}
                            onWorkspaceCreated={onWorkspaceCreated}
                        />
                    ),
                    title: t('Workspaces.Form.Titles.Create'),
                },
            ];
        }

        if (mode === 'edit') {
            // when we launch into edit mode we should only be able to delete or edit the workspace
            configuration = [
                {
                    value: 'edit',
                    view: (props: DialogViewInputs) => (
                        <CreateEdit
                            mode="edit"
                            onClose={props.onClose}
                            onDelete={() => props.setView('delete')}
                            workspace={workspace}
                        />
                    ),
                    title: t('Workspaces.Form.Titles.Edit', {workspaceName: workspace?.name}),
                },
                DeleteViewConfiguration,
            ];
        }

        if (mode === 'duplicate') {
            // when we launch into duplicate mode we should only be able to duplicate the workspace
            configuration = [
                {
                    value: 'duplicate',
                    view: (props: DialogViewInputs) => (
                        <CreateEdit
                            mode="duplicate"
                            onClose={props.onClose}
                            workspace={workspace}
                        />
                    ),
                    title: t('Workspaces.Form.Titles.Duplicate', {
                        workspaceName: workspace?.name,
                    }),
                },
            ];
        }

        if (mode === 'delete') {
            // when we launch into delete mode we should only be able to delete the workspace
            configuration = [DeleteViewConfiguration];
        }

        return configuration;
    }, [DeleteViewConfiguration, mode, t, workspace]);

    return (
        <DialogSurface className={classes.root}>
            <MultiViewDialog
                ref={dialogRef}
                viewConfiguration={viewConfiguration}
                onClose={onClose}
            />
        </DialogSurface>
    );
}

export default WorkspaceForm;
