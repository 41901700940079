import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetCapacitiesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import {customFetch} from '../api';
import securityGraphApi from '@/api/securityGraph.api';
import {capacitiesApiOptions} from './useGetCapacities';

//The idea to have this is that capacity invocations for a tenant
//will return 404s if the account isn't set up & 403's if workspace is not created and user will have no access policies on data plane calls.
// Thus during the FRE there will be a need to suppress the 404 and 403 errors
export default function useGetCapacitiesForAdminFre(
    useQueryOptions?: Pick<UseQueryOptions<GetCapacitiesResponse>, 'enabled'>,
) {
    const url = securityGraphApi(capacitiesApiOptions);

    const defaults: GetCapacitiesResponse = {
        value: [],
        count: 0,
    };

    return useQuery({
        ...useQueryOptions,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['capacities-presetup'],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () => {
            const result = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                    headersFromOptions: null,
                },
                true,
            );
            if (!result.ok && (result.status === 404 || result.status === 403)) {
                return defaults;
            }

            const data = await result.json();
            return data as GetCapacitiesResponse;
        },
    });
}
