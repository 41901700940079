import {PromptbookPrompts, PromptInputs} from '@/api/promptbooks';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useClasses from './PromptbookPrompt.styles';
import {
    Button,
    Label,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Textarea,
    ToolbarButton,
    Tooltip,
    mergeClasses,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {
    AcceptIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    DeleteIcon,
    DismissIcon,
    EditIcon,
    SkillIcon,
} from '@/components/ui/icons';
import {EMPTY_PROMPTBOOK_PROMPT_CONTENT} from './Promptbook.types';
import {MoreHorizontalIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import DirectSkillForm from './DirectSkillForm';
import {SkillDescriptor, SkillInputDescriptor} from '@/api/skills';
import DirectSkillsMenu from '../../ui/PromptBar/PromptBarMenu';
import {PromptType} from '@/api/prompts';
export interface PromptbookPromptProps {
    promptDefn: PromptbookPrompts;
    totalItems: number;
    // Optional index for use in lists
    index?: number;
    onPromptDeleted?: (index: number) => void;
    onPromptMovedDown?: (index: number) => void;
    onPromptMovedUp?: (index: number) => void;
    onPromptEdited?: (index: number, promptDefn: PromptbookPrompts) => void;
    onPromptDataLoaded?: (index: number, promptDefn: PromptbookPrompts) => void;
}

export default function PromptbookPrompt(props: PromptbookPromptProps) {
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const classes = useClasses();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const editIconRef = useRef<HTMLButtonElement>(null);
    const [editMode, setEditMode] = useState(props.promptDefn.editMode ?? false);
    const [hasBeenSaved, setHasBeenSaved] = useState(false);
    const [tempPrompt, setTempPrompt] = useState('');
    const [promptDefn, setPromptDefn] = useState<PromptbookPrompts>(props.promptDefn);
    const [skillListOpen, setSkillListOpen] = useState<boolean>(false);
    const inputWrapperRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    // Handle skill states.
    const [skill, setSkill] = useState<SkillDescriptor | undefined>(props.promptDefn.skill);
    const [skillName, setSkillName] = useState<string | undefined>(props.promptDefn.skillName);
    const [plugins, setPlugins] = useState<string[] | undefined>(props.promptDefn.plugins);
    const [skillInputDescriptors, setSkillInputDescriptors] = useState<
        SkillInputDescriptor[] | undefined
    >(props.promptDefn.skillInputDescriptors);
    const [skillInputs, setSkillInputs] = useState<PromptInputs | undefined>(
        props.promptDefn.inputs,
    );
    const [tempSkillInputs, setTempSkillInputs] = useState(skillInputs);
    const [isRequiredFilled, setIsRequiredFilled] = useState(
        props.promptDefn.skillInputDescriptors === undefined ||
            props.promptDefn.skillInputDescriptors?.length === 0,
    );
    const {t} = useTranslation('promptbooks');
    // handlers for restoring focus when switching from edit mode back to normal mode
    const restoreFocusSource = useRestoreFocusSource();
    const restoreFocusTarget = useRestoreFocusTarget();
    const promptRef = useRef<HTMLDivElement | null>(null);

    // Used to adjust height of menu based on position in viewport
    const calcMenuHeight = () => {
        if (promptRef.current) {
            const rect = promptRef.current?.getBoundingClientRect();
            const spaceAbove = rect.top; // Space from the top of the viewport
            const heightRanges = [
                {max: 300, value: '7vh'},
                {max: 400, value: '13vh'},
                {max: 500, value: '20vh'},
                {max: Infinity, value: '30vh'},
            ];
            const selectedHeight = heightRanges.find((range) => spaceAbove < range.max)?.value;
            return selectedHeight || '30vh';
        }
        return '30vh';
    };
    const [menuHeight, setMenuHeight] = useState(calcMenuHeight);

    const userSkillsets = useMemo<string[] | undefined>(() => {
        const params = new URLSearchParams(window.location.search);
        return (
            params
                .get('skillsets')
                ?.split(',')
                .map((item: string) => item.trim()) ?? undefined
        );
    }, [typeof window !== 'undefined' && window.location.search]);

    const handleEditPrompt = (promptDefn: PromptbookPrompts) => {
        setEditMode(true);
        setTempPrompt(promptDefn.content ?? '');
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    };

    const handleDeletePrompt = (index: number) => {
        if (index > -1) {
            props.onPromptDeleted?.(index);
        }
    };

    const handlePromptMoveUp = (index: number) => {
        if (index > -1) {
            props.onPromptMovedUp?.(index);
        }
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    };

    const handlePromptMoveDown = (index: number) => {
        if (index > -1) {
            props.onPromptMovedDown?.(index);
        }
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    };

    // Handle skill selection.
    const handleSelect = useCallback(
        (skill: SkillDescriptor | undefined) => {
            setSkill(skill);
            setSkillName(skill?.name ?? skill?.displayName);
            setSkillInputs(
                skill?.inputs.reduce((values, input) => {
                    if (input.required) {
                        values[input.name] = '';
                    }
                    return values;
                }, {} as PromptInputs),
            );
            setSkillInputDescriptors(skill?.inputs);
            const skillset = skill?.skillsetName;
            setPlugins(skillset ? [skillset] : []);
            setSkillListOpen(false);
            textareaRef.current?.focus();
        },
        [textareaRef, setSkill, setSkillListOpen],
    );

    const handleAcceptEditPrompt = (index: number) => {
        if (!skillName) {
            const newDefn: PromptbookPrompts = {
                ...promptDefn!,
                content: tempPrompt,
                plugins: [],
            };
            setPromptDefn(newDefn);
            props.onPromptEdited?.(index, newDefn);
        } else {
            if (!isRequiredFilled) {
                return;
            }
            const newDefn: PromptbookPrompts = {
                ...promptDefn!,
                promptType: PromptType.Skill,
                skillName: skillName,
                inputs: tempSkillInputs,
                skill,
                plugins: plugins,
            };
            setSkillInputs(tempSkillInputs);
            setHasBeenSaved(true);
            setPromptDefn(newDefn);
            props.onPromptEdited?.(index, newDefn);
        }
        setTempPrompt('');
        setEditMode(false);
    };

    const handleClosePromptEditing = (index: number) => {
        if (!hasBeenSaved || !isRequiredFilled) {
            setSkill(undefined);
            setSkillName(undefined);
        }
        setTempPrompt('');
        setEditMode(false);
    };

    useEffect(() => {
        if (editMode && textAreaRef && textAreaRef.current) {
            textAreaRef.current.focus();
        } else if (!editMode && editIconRef && editIconRef.current) {
            editIconRef.current.focus();
        }
    }, [editMode]);

    useEffect(() => {
        setIsRequiredFilled(tempPrompt.trim() !== '' ? true : false);
    }, [tempPrompt]);

    // Calculate what the menu height should be on first render
    useEffect(() => {
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    }, []);

    function renderEditModeContent() {
        return skillName ? (
            <div
                className={mergeClasses(
                    classes.flexContainerRow,
                    classes.flexGrow,
                    classes.hoverParentPromptInput,
                    classes.promptLabel,
                )}
                style={{position: 'relative'}}
                role="group"
                tabIndex={0}
                data-test-id={'edit-duplicate-prompt'}
                {...restoreFocusTarget}
            >
                <div className={classes.promptNumberSkill}>
                    {props.index ? props.index! + 1 : 1}
                </div>
                <div className={classes.skillFormWrapper}>
                    <DirectSkillForm
                        skillName={skillName}
                        skillInputDescriptors={skillInputDescriptors ?? []}
                        skillInputs={skillInputs ?? {}}
                        editMode={editMode}
                        onCancel={() => handleSelect(undefined)}
                        onChange={(inputs) => setTempSkillInputs(inputs)}
                        onRequiredStatusChange={(isFilled) => setIsRequiredFilled(isFilled)}
                    />
                </div>
                {renderToolbarContent()}
            </div>
        ) : (
            <div
                className={mergeClasses(classes.flexContainerRow, classes.flexGrow)}
                style={{position: 'relative'}}
                data-test-id={'edit-duplicate-prompt'}
                {...restoreFocusSource}
            >
                <Textarea
                    className={classes.flexGrow}
                    placeholder={t(EMPTY_PROMPTBOOK_PROMPT_CONTENT)}
                    value={tempPrompt}
                    data-testid="edit-prompt-textarea"
                    resize="vertical"
                    onChange={(e, data) => {
                        setTempPrompt(data.value);
                    }}
                    ref={textAreaRef}
                />
                <div className={classes.promptbookInputActionsContainer}>
                    {renderToolbarContent()}
                </div>
            </div>
        );
    }

    function renderNormalModeContent() {
        return skillName ? (
            <div className={classes.flexGrow} role="group">
                <div className={classes.promptNumberSkill}>
                    {props.index ? props.index! + 1 : 1}
                </div>
                <div className={classes.skillFormWrapper}>
                    <DirectSkillForm
                        skillName={skillName}
                        skillInputDescriptors={skillInputDescriptors ?? []}
                        skillInputs={skillInputs ?? {}}
                        editMode={editMode}
                        onCancel={() => handleSelect(undefined)}
                        onChange={(inputs) => setTempSkillInputs(inputs)}
                    />
                </div>
            </div>
        ) : (
            <Label className={classes.flexGrow}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.promptNumber}>
                            {props.index ? props.index! + 1 : 1}
                        </div>
                        <p className={classes.promptContent}>
                            {isDirectSkillInvocationEnabled
                                ? props.promptDefn.content && props.promptDefn.content !== ''
                                    ? props.promptDefn.content
                                    : t(EMPTY_PROMPTBOOK_PROMPT_CONTENT)
                                : props.promptDefn.content ?? t(EMPTY_PROMPTBOOK_PROMPT_CONTENT)}
                        </p>
                    </div>
                </div>
            </Label>
        );
    }

    function renderToolbarContent() {
        return (
            <div
                className={mergeClasses(
                    classes.flexContainerRow,
                    classes.flexGrow,
                    skillName ? classes.toolBarContainer : undefined,
                )}
            >
                {isDirectSkillInvocationEnabled && (
                    <>
                        <Tooltip
                            content={t('DirectSkill')}
                            relationship="label"
                            positioning="above"
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<SkillIcon />}
                                className={
                                    skillName ? classes.toolBarButtonSkill : classes.toolBarButton
                                }
                                aria-label={t('ChooseSkill')}
                                data-testid="choose-skill-prompt"
                                onClick={() => setSkillListOpen(!skillListOpen)}
                            />
                        </Tooltip>
                        <div className={classes.directSkillsMenu}>
                            <DirectSkillsMenu
                                data-testid="skill-menu"
                                ref={inputWrapperRef}
                                open={skillListOpen}
                                skillsets={userSkillsets ?? undefined}
                                onSkillSelect={handleSelect}
                                onHide={() => setSkillListOpen(false)}
                                directSkillMenu={true}
                                menuHeight={menuHeight}
                            />
                        </div>
                        {!skillName && <div className={classes.verticalLine} />}
                    </>
                )}
                <ToolbarButton
                    className={skillName ? classes.toolBarButtonSkill : classes.toolBarButton}
                    appearance="subtle"
                    data-testid="accept-edit-prompt"
                    aria-label={t('AcceptEdit')}
                    icon={<AcceptIcon />}
                    disabled={!isRequiredFilled}
                    onClick={() => handleAcceptEditPrompt(props.index ?? -1)} //change this function to combine the skill one as well
                />
                <ToolbarButton
                    className={skillName ? classes.toolBarButtonSkill : classes.toolBarButton}
                    appearance="subtle"
                    data-testid="close-edit-prompt"
                    aria-label={t('CloseEdit')}
                    icon={<DismissIcon />}
                    onClick={() => handleClosePromptEditing(props.index ?? -1)}
                />
            </div>
        );
    }

    return (
        <>
            {props.promptDefn &&
                (editMode ? (
                    renderEditModeContent()
                ) : (
                    <div
                        className={mergeClasses(
                            classes.flexContainerRow,
                            classes.flexGrow,
                            classes.hoverParentPromptInput,
                            classes.promptLabel,
                        )}
                        ref={promptRef}
                        role="group"
                        tabIndex={0}
                        data-test-id={'edit-duplicate-prompt'}
                        {...restoreFocusTarget}
                    >
                        {renderNormalModeContent()}
                        <ToolbarButton
                            className={classes.promptbookInput}
                            appearance="subtle"
                            data-testid="edit-prompt"
                            icon={<EditIcon />}
                            aria-label={t('EditPrompt')}
                            role="button"
                            ref={editIconRef}
                            onClick={() => handleEditPrompt(props.promptDefn)}
                        />
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button
                                    icon={<MoreHorizontalIcon />}
                                    data-testid="more-options"
                                    appearance="subtle"
                                    className={classes.moreOptionsButton}
                                    aria-label={t('SessionOptionsButton')}
                                />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    <MenuItem
                                        icon={<DeleteIcon />}
                                        data-testid="delete-prompt"
                                        onClick={() => {
                                            handleDeletePrompt(props.index ?? -1);
                                        }}
                                    >
                                        {t('Delete')}
                                    </MenuItem>
                                    {props.index !== 0 && (
                                        <MenuItem
                                            icon={<ArrowUpIcon />}
                                            data-testid="move-up-prompt"
                                            onClick={() => {
                                                handlePromptMoveUp(props.index ?? -1);
                                            }}
                                        >
                                            {t('Move Up')}
                                        </MenuItem>
                                    )}
                                    {props.index !== props.totalItems - 1 && (
                                        <MenuItem
                                            icon={<ArrowDownIcon />}
                                            data-testid="move-down-prompt"
                                            onClick={() => {
                                                handlePromptMoveDown(props.index ?? -1);
                                            }}
                                        >
                                            {t('Move Down')}
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                ))}
        </>
    );
}
