import {UserProfile} from '@/api/user/user.types';
import {Persona} from '@fluentui/react-components';

export default function PersonaElementWorkspaces({profile}: {profile: UserProfile}) {
    {
        /* ToDo: Response from Graph API is taking time for rendering. So displaying user profile without photos for now */
    }
    return (
        <div>
            <Persona
                name={profile?.displayName}
                textAlignment="center"
                avatar={{color: 'colorful'}}
            />
        </div>
    );
}
