import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export const useClasses = makeStyles({
    dropdownWrapper: {
        width: '40%',
        height: '60px',
    },
    dropdown: {
        maxWidth: '250px',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
        ...shorthands.borderColor(tokens.colorNeutralStroke1),
        ...shorthands.padding('2px'),
    },
    dropdownItem: {
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground1,
    },
    dropdownItemSelected: {
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground1,
    },
    dropdownTitle: {
        padding: '14px',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground1,
    },
    menuButton: {
        minWidth: 'unset',
        padding: '5px',
        '& :first-child': {
            marginRight: '0px',
        },
    },
    splitPrimaryButton: {
        borderImageWidth: '0px',
        borderRightStyle: 'none',
        borderRightWidth: '0px',
        borderRightColor: 'transparent',
    },
    splitDropdownButton: {
        borderImageWidth: '0px',
        borderLeftStyle: 'none',
        borderLeftWidth: '0px',
        borderLeftColor: 'transparent',
    },
});
