import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {PageResponse} from '../api';
import {SkillDescriptor, SkillsetDescriptor} from './skills.types';
import {featureFlags} from '@/util/hasStaticFeatureFlags';

interface useGetAllSkillsProps {
    /** Enable to lookup all skills including hidden skills, use this to resolve skill display names
     * This addresses an issue where hidden skillsets from embedded experiences are not resolved correctly
     */
    includeHidden?: boolean;
}

function constructParams(featureFlagsParams: string | null, includeHidden: boolean): string {
    const params = new URLSearchParams();
    if (featureFlagsParams) {
        params.append('featureFlags', featureFlagsParams);
    }
    if (includeHidden) {
        params.append('includeHidden', 'true');
    }
    return `?${params.toString()}`;
}

export default function useGetAllSkills(props?: useGetAllSkillsProps) {
    const {includeHidden = false} = props ?? {};
    const {customFetch} = useFetch();
    const featureFlagsParams = featureFlags();
    const singleSkillParams = constructParams(featureFlagsParams || '', includeHidden);
    const skillsetParams = constructParams(featureFlagsParams || '', false);

    let defaultKeys = ['skillsets', 'skills'];
    if (includeHidden) {
        defaultKeys.push('withHiddenSkills');
    }
    return useQuery({
        // Cache for entire session, as this rarely changes.
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        queryKey: defaultKeys,
        queryFn: async () => {
            try {
                // Retrieve the list of skillsets.
                const skillsets = await customFetch<PageResponse<SkillsetDescriptor>>(
                    `/skillsets${skillsetParams}`,
                    {
                        method: 'GET',
                    },
                );
                // Then retrieve each subset.
                const skills = await Promise.all(
                    skillsets.value
                        .filter((skillset) => skillset.enabled)
                        .map((skillset) =>
                            customFetch<PageResponse<SkillDescriptor>>(
                                `/skillsets/${skillset.name}/skills${singleSkillParams}`,
                                {
                                    method: 'GET',
                                },
                            ).then((skills) => {
                                return {
                                    ...skills,
                                    value: skills.value
                                        .filter((skill) => (includeHidden ? true : !skill.hidden))
                                        // TODO: Remove this when the skillset property is populated as expected.
                                        .map((skill) => ({
                                            ...skill,
                                            skillsetDisplayName:
                                                skillset.displayName ?? skillset.name,
                                            skillsetName: skillset.name,
                                        })),
                                };
                            }),
                        ),
                );

                // Concatenate all skills into a single list.
                return skills.reduce(
                    (page, skills) => {
                        page.value.unshift(...skills.value);
                        return page;
                    },
                    {value: []} as PageResponse<SkillDescriptor>,
                );
            } catch (error) {
                console.error('Error fetching all skills', error);
                throw error;
            }
        },
    });
}
