import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        height: '830px',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorNeutralBackground5} ${tokens.colorTransparentBackground}`,
        '&::-webkit-scrollbar': {
            width: tokens.spacingHorizontalS,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorTransparentBackground,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralBackground5,
            ...shorthands.borderRadius('20px'),
        },
    },
    title: {
        fontSize: '20px',
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center' /* Center horizontally */,
        alignItems: 'center' /* Center vertically */,
        height: '100%',
    },

    contentSection: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        height: '866px',
    },
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    dialogContentSection: {
        marginBottom: '20px',
        marginTop: tokens.spacingVerticalS,
    },
    dialogRoot: {
        maxWidth: '770px !important',
    },
    fullWidthControl: {
        width: '100%',
    },
    capacityDropDown: {
        height: '32px',
        marginBottom: tokens.spacingVerticalSNudge,
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalMNudge),
    },
    icon: {
        scale: 1.5,
        top: '3px',
    },
    listBoxHeight: {
        maxHeight: '200px',
        height: 'auto',
    },
    field: {
        marginBottom: tokens.spacingVerticalM,
    },
    label: {
        display: 'block',
        textAlign: 'left',
        marginBottom: tokens.spacingVerticalMNudge,
    },
    leftAlignedContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    textFieldStyle: {
        minWidth: '100%',
        ...shorthands.borderStyle('none'),
        backgroundColor: tokens.colorNeutralBackground5,
        height: tokens.lineHeightHero800,
    },
    tooltipContent: {
        backgroundColor: tokens.colorNeutralBackground5,
    },
    visible: {
        color: tokens.colorNeutralForeground2BrandSelected,
    },
    infoIcon: {
        fontSize: tokens.fontSizeBase200,
        ...shorthands.border('0px'),
        minWidth: '12px',
        minHeight: '12px',
        ...shorthands.padding('6px', '6px', '0px', '0px'),
        alignSelf: 'center',
        position: 'relative',
    },
    infoLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorNeutralForeground4,
    },
    dropdownSelector: {
        minWidth: '100%',
        ...shorthands.borderStyle('none'),
        backgroundColor: tokens.colorNeutralBackground5,
        display: 'flex',
        height: tokens.lineHeightHero800,
    },
    checkboxStyle: {
        marginRight: tokens.spacingHorizontalXS,
    },
    termsLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        color: tokens.colorNeutralForeground3,
    },
    warningLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorStatusDangerForeground3,
    },
    termsLinkStyle: {
        display: 'inline-block',
        marginLeft: '6px',
        marginRight: '6px',
    },
    readMoreLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorNeutralForeground2,
    },
    termsButtonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 'auto',
    },
    newWorkspaceLinkStyle: {
        marginLeft: tokens.spacingHorizontalSNudge,
        marginRight: tokens.spacingHorizontalSNudge,
        marginTop: tokens.spacingVerticalSNudge,
    },
    fillerIcon: {
        width: '16px',
        display: 'inline-block',
    },
});
