import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {DismissIcon} from './ui/icons';
import {useTranslation} from 'react-i18next';
import useClasses from './SetPreferredWorkspaceDialog.styles';
import UserSettingsModal from '@/components/Navigation/UserSettingsModal';

interface SetPreferredWorkspaceDialogProps {
    isSetPreferredWorkspaceDialogOpen: boolean;
    onClose: () => void;
}

export default function SetPreferredWorkspaceDialog(props: SetPreferredWorkspaceDialogProps) {
    const [isSetPreferredWorkspaceDialogOpen, setSetPreferredWorkspaceDialogOpen] =
        useState<boolean>(false);
    const {t} = useTranslation('common');
    const classes = useClasses();

    const closePreferredWorkspaceDialogConfirmation = () => {
        props.onClose();
    };

    // settings modal
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const restoreFocusSourceAttribute = useRestoreFocusSource();

    useEffect(() => {
        setSetPreferredWorkspaceDialogOpen(props.isSetPreferredWorkspaceDialogOpen);
    }, [props.isSetPreferredWorkspaceDialogOpen]);

    return (
        <>
            <Dialog open={isSetPreferredWorkspaceDialogOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={t('ButtonLabels.Close')}
                                        icon={<DismissIcon />}
                                        onClick={closePreferredWorkspaceDialogConfirmation}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <div className={classes.titleContainer}>
                                <div>{t('ChooseNewPreferredWorkspaceTitle')}</div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={classes.contentSection}>
                            <div>{t('ChooseNewPreferredWorkspaceDescription')}</div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-test-id="settings-button"
                                appearance="primary"
                                aria-label={t('GoToSettingsButton')}
                                onClick={() => setSettingsOpen(true)}
                                {...restoreFocusTargetAttribute}
                            >
                                {t('GoToSettingsButton')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <UserSettingsModal
                data-testid="SettingsButton"
                open={settingsOpen}
                setOpen={setSettingsOpen}
                setMenuOpen={setOpen}
                {...restoreFocusSourceAttribute}
            />
        </>
    );
}
