import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap(tokens.spacingHorizontalL),
        width: '100%',
        height: '100%',
    },
    promptbookLibraryContainer: {
        ...shorthands.padding(0),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        width: '100%',
        '@media (min-width: 1920px)': {
            width: '1256px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '1256px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '832px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '408px',
            ...shorthands.padding(0),
            ...shorthands.gap(tokens.spacingVerticalSNudge),
            ...shorthands.margin(tokens.spacingVerticalXXL, 'auto'),
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '384px',
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalXXL, 'auto'),
        },
        '@media (max-width: 479px)': {
            width: '272px',
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalL, 'auto'),
        },
    },
    promptLibraryContainer: {
        ...shorthands.padding(0),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        width: '100%',
        '@media (min-width: 1920px)': {
            width: '1256px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '1256px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '938px',
            paddingTop: tokens.spacingVerticalSNudge,
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '544px',
            ...shorthands.padding(0),
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalXL, 'auto'),
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '384px',
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalXXL, 'auto'),
        },
        '@media (max-width: 479px)': {
            width: '272px',
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalL, 'auto'),
        },
    },
    card: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXL),
        boxSizing: 'border-box',
        // min width is required to prevent flex-box
        // from sizing based off long content
        minWidth: '0px',
        ':hover': {
            ...shorthands.borderStyle('solid'),
            ...shorthands.borderWidth('1px'),
            ...shorthands.borderColor(tokens.colorBrandStroke2),
            backgroundColor: tokens.colorNeutralBackground1Hover,
        },
        ':focus': {
            ...shorthands.borderStyle('solid'),
            ...shorthands.borderWidth('2px'),
            ...shorthands.borderColor(tokens.colorStrokeFocus2),
            ...shorthands.outline('0px', 'solid', tokens.colorStrokeFocus2),
        },
        height: '222px',
        ...shorthands.borderRadius('10px'),
        '@media (forced-colors: active)': {
            ':hover': {
                backgroundColor: tokens.colorNeutralBackground1Hover,
            },
        },
    },
    cardEmpty: {
        backgroundColor: tokens.colorTransparentBackground,
        ...shorthands.borderRadius('10px'),
        ':hover': {
            ...shorthands.borderStyle('none'),
        },
    },
    cardAcrylic: {
        height: '100%',
    },
    cardContent: {
        color: tokens.colorNeutralForeground1,
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontSize: tokens.fontSizeBase400,
    },
    cardFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: tokens.fontSizeBase400,
        lineHeight: tokens.lineHeightBase400,
        width: '100%',
    },
    hiddenPromptbar: {
        display: 'none',
    },
    summary: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase400,
        '& svg': {
            fontSize: tokens.fontSizeBase300,
            marginBottom: '-2px',
        },
        '@media (min-width: 1920px)': {
            'max-width': '280px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            'max-width': '280px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            'max-width': '204.67px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            'max-width': '178px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            'max-width': '345px',
        },
        '@media (max-width: 480px)': {
            'max-width': '170px',
        },
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        ...shorthands.overflow('hidden'),
    },
    summaryContent: {
        lineHeight: tokens.lineHeightBase400,
    },
    promptCard: {
        height: '222px',
        '@media (min-width: 1920px)': {
            width: '302px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '302px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '302px',
            height: '222px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '264px',
            height: '222px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '384px',
        },
        '@media (max-width: 479px)': {
            width: '272px',
        },
    },
    // Promptbook additional stuff
    promptbookCard: {
        height: '222px',
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXL),
        '@media (min-width: 1920px)': {
            width: '408px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '408px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '408px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '408px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '384px',
        },
        '@media (max-width: 479px)': {
            width: '272px',
        },
    },
    promptbookCardContent: {
        display: 'flex',
        rowGap: tokens.spacingHorizontalXS,
        flexDirection: 'column',
    },
    cardTitle: {
        fontSize: '16px',
        '@media (max-width: 479px)': {
            'white-space': 'nowrap',
            'text-overflow': 'ellipsis',
            maxWidth: '240px',
        },
        cursor: 'pointer',
    },
    promptCardTitle: {
        paddingBottom: tokens.spacingVerticalS,
    },
    cardDescription: {
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        'text-overflow': 'ellipsis',
        ...shorthands.overflow('hidden'),
        lineHeight: tokens.lineHeightBase300,
        fontSize: '16px',
        paddingTop: tokens.spacingVerticalS,
    },
    viewPromptbooks: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalSNudge),
        textAlign: 'right',

        '> a': {
            color: tokens.colorNeutralForeground2,
        },

        '& svg': {
            fontSize: tokens.fontSizeBase300,
            marginBottom: '-2px',
        },
    },
    promptbookBadge: {
        color: tokens.colorBrandForeground1,
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    sequenceLabel: {
        color: tokens.colorNeutralForeground2,
        fontSize: tokens.fontSizeBase100,
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalSNudge),
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXL),
    },
    loadingbox: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        width: '100%',
        ...shorthands.flex(1, 1, 'auto'),
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        backgroundColor: tokens.colorNeutralBackgroundAlpha,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalS),
    },
    emptybox: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        width: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.flex(1, 1, 'auto'),
        backgroundColor: tokens.colorNeutralBackgroundAlpha,
        alignItems: 'center',
    },
    notFoundBody: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXS),
    },
    promptInputField: {
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorNeutralBackground5,
        fontSize: tokens.fontSizeBase300,
        textAlign: 'center',
        borderRadius: '4px',
        height: '24px',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalS),
    },
    footerActions: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
});
