import {useState, useMemo, useEffect} from 'react';
import {
    Combobox,
    Option,
    OptionGroup,
    Field,
    FieldProps,
    Tooltip,
    useId,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {useGetCapacities} from '@/api/capacities';
import {Capacity} from '@/api/capacities';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {useGetWorkspaces} from '@/api/workspaces';
import useClasses from './CapacitySelect.styles';
import {Workspace} from '@/api/workspaces';
import DisconnectCapacityDialog from '../ManageWorkspaces/DisconnectCapacityDialog';

interface CapacitySelectProps {
    value?: string;
    required?: boolean;
    onOptionSelect?: (selectedCapacity: Capacity) => void;
    fieldProps?: FieldProps;
    showOnlyAssignableCapacities?: boolean;
    disabled: boolean;
}

export default function CapacitySelect(props: CapacitySelectProps) {
    const {showOnlyAssignableCapacities, value: propValue, fieldProps, required} = props;
    const classes = useClasses();
    // translation related functions by namespace
    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    // data fetching
    const {data, error, isLoading} = useGetCapacities();
    const capacityItems = data?.value || [];
    // when showOnlyAssignableCapacities is true, we only show capacities that are assignable to the workspace
    // we enable fetching of workspaces to get the assignable capacities
    const {data: workspacesData, error: workspaceError} = useGetWorkspaces({
        enabled: showOnlyAssignableCapacities,
    });

    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);
    const [tempSelectedCapacity, setTempSelectedCapacity] = useState<string | null>(null);
    const [attachedWorkspace, setAttachedWorkspace] = useState<Workspace>();

    // the value of the component is handled externally, update the internal value only when the prop value changes
    useEffect(() => {
        if (propValue !== value) {
            setValue(propValue || '');
        }
        // only trigger change when the prop value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propValue]);

    const selectableCapacities = useMemo(() => {
        if (!showOnlyAssignableCapacities) {
            return capacityItems || [];
        }
        // from the workspace data get capacity names that are already linked from the capacity property
        const linkedWorkspaces =
            workspacesData?.value?.filter((workspace: Workspace) => workspace.capacity) || [];
        // from linkedWorkspaces create a simple array of capacity names
        const assignedCapacities = linkedWorkspaces.map(
            (workspace: Workspace) => workspace.capacity?.referenceName,
        );
        console.log({assignedCapacities});

        return (
            data?.value?.filter(
                (capacity: Workspace) => !assignedCapacities.includes(capacity.name),
            ) || []
        );
    }, [capacityItems, data?.value, showOnlyAssignableCapacities, workspacesData?.value]);

    const hasSingleSelectableCapacity = selectableCapacities.length === 1;

    const [value, setValue] = useState<string>(
        props.value ||
            (hasSingleSelectableCapacity && selectableCapacities
                ? selectableCapacities[0].name
                : ''),
    );

    // determine if capacity is being used
    // declare an array of available capacities
    const {availableCapacities, unavailableCapacities} = useMemo(() => {
        let unavailableCapacities: Capacity[] = [];
        let availableCapacities: Capacity[] = [];
        // make an array of unavailable capacities
        if (workspacesData?.value) {
            for (const capacity of capacityItems) {
                if (
                    workspacesData?.value?.find(
                        (workspace: Workspace) =>
                            workspace.capacity?.referenceName === capacity.name,
                    )
                ) {
                    // workspace is attached to this capacity, so the capacity is in use
                    unavailableCapacities.push(capacity);
                } else {
                    availableCapacities.push(capacity);
                }
            }
        }
        return {availableCapacities, unavailableCapacities};
    }, [capacityItems, workspacesData?.value]);
    const handleDisconnectConfirm = () => {
        if (tempSelectedCapacity) {
            setValue(tempSelectedCapacity);
            // find the capacity object
            const selectedCapacity = capacityItems.find(
                (capacity: Capacity) => capacity.name === tempSelectedCapacity,
            );
            if (selectedCapacity && props.onOptionSelect) {
                props.onOptionSelect(selectedCapacity);
                document.getElementById(capacitySelectId)?.focus();
            }
        }
        setDisconnectCapacityDialogOpen(false);
    };
    const handleAvailableCapacityClick = (capacityName: string) => {
        setValue(capacityName);
        // find the capacity object
        const selectedCapacity = capacityItems.find(
            (capacity: Capacity) => capacity.name === capacityName,
        );
        if (selectedCapacity && props.onOptionSelect) {
            props.onOptionSelect(selectedCapacity);
            document.getElementById(capacitySelectId)?.focus();
        }
    };
    const handleInUseCapacityClick = (capacityName: string) => {
        setTempSelectedCapacity(capacityName);
        const tempAttachedWorkspace = workspacesData?.value.find(
            (workspace: Workspace) => workspace.capacity?.referenceName === capacityName,
        );
        setDisconnectCapacityDialogOpen(true);
        setAttachedWorkspace(tempAttachedWorkspace);
    };
    const capacitySelectId = useId('capacitySelect');
    const renderDropdown = () => (
        <Field required={required} {...fieldProps}>
            <MedeinaInfoLabel
                labelContent={t('createCapacity.CapacityLabel')}
                infoContent={t('switchCapacity.Info')}
                className={classes.label}
            />
            <Combobox
                disabled={props.disabled}
                placeholder={t('switchCapacity.Placeholder')}
                aria-label={commonLabel('Capacity')}
                data-test-id="capacity-dropdown"
                value={value}
                selectedOptions={value ? [value] : []}
                id={capacitySelectId}
            >
                <OptionGroup label={t('Workspaces.Form.Available')}>
                    {availableCapacities
                        ?.sort((itemA: Capacity, itemB: Capacity) =>
                            itemA.name > itemB.name ? 1 : -1,
                        )
                        .map(({id, name}: Capacity) => {
                            return (
                                <Option
                                    key={name}
                                    value={name}
                                    onClick={() => handleAvailableCapacityClick(name)}
                                >
                                    {name}
                                </Option>
                            );
                        })}
                </OptionGroup>
                <OptionGroup label={t('Workspaces.Form.InUse')}>
                    {unavailableCapacities
                        ?.sort((itemA: Capacity, itemB: Capacity) =>
                            itemA.name > itemB.name ? 1 : -1,
                        )
                        .map(({id, name}: Capacity) => {
                            return (
                                <Option
                                    key={name}
                                    value={name}
                                    onClick={() => handleInUseCapacityClick(name)}
                                >
                                    {name}
                                </Option>
                            );
                        })}
                </OptionGroup>
            </Combobox>
            <DisconnectCapacityDialog
                title={t('ManageWorkspaces.DisconnectCapacityDialog.Title', {
                    0: tempSelectedCapacity,
                })}
                description={t('ManageWorkspaces.DisconnectCapacityDialog.Description', {
                    0: attachedWorkspace?.name,
                })}
                switchButtonLabel={t('ManageWorkspaces.DisconnectCapacityDialog.DisconnectButton')}
                isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                newCapacityName={tempSelectedCapacity || ''}
                workspaceName={attachedWorkspace?.name || ''}
                onClose={() => {
                    setDisconnectCapacityDialogOpen(false);
                }}
                onConfirm={handleDisconnectConfirm}
            />
        </Field>
    );
    return (
        <>
            {props.disabled ? (
                <Tooltip
                    content={t('ManageWorkspaces.Errors.CapacityOwnerPermission')}
                    relationship="label"
                    withArrow={true}
                >
                    {renderDropdown()}
                </Tooltip>
            ) : (
                renderDropdown()
            )}
        </>
    );
}
