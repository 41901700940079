import {
    ArrowClockwiseFilled,
    ArrowClockwiseRegular,
    ArrowRightFilled,
    ArrowRightRegular,
    bundleIcon,
    ChatHelpFilled,
    ChatHelpRegular,
    CircleEditFilled,
    CircleEditRegular,
    CodeFilled,
    CodeRegular,
    DeleteFilled,
    DeleteRegular,
    DismissFilled,
    DismissRegular,
    EditFilled,
    EditRegular,
    FilterFilled,
    FilterRegular,
    HomeFilled,
    HomeRegular,
    NavigationFilled,
    NavigationRegular,
    PersonFeedbackFilled,
    PersonFeedbackRegular,
    PinFilled,
    PinRegular,
    PinOffFilled,
    PinOffRegular,
    SendFilled,
    SendRegular,
    SparkleFilled,
    SparkleRegular,
    WindowNewFilled,
    WindowNewRegular,
    ShareRegular,
    ShareFilled,
    NotebookFilled,
    NotebookRegular,
    ArrowSyncFilled,
    ArrowSyncRegular,
    CheckmarkStarburstRegular,
    CheckmarkStarburstFilled,
    TargetEditRegular,
    TargetEditFilled,
    FlagFilled,
    FlagRegular,
    PanelLeftContractFilled,
    PanelLeftContractRegular,
    HatGraduationFilled,
    HatGraduationRegular,
    DocumentSearchFilled,
    DocumentSearchRegular,
    DarkThemeFilled,
    DarkThemeRegular,
    ChevronLeftRegular,
    ChevronLeftFilled,
    ChevronRightRegular,
    ChevronRightFilled,
    ArrowExitRegular,
    ArrowExitFilled,
    ExpandUpRightRegular,
    ArrowDownloadFilled,
    ArrowDownloadRegular,
    FlowchartFilled,
    FlowchartRegular,
    ArrowLeftRegular,
    ArrowLeftFilled,
    CopyRegular,
    CopyFilled,
    SearchRegular,
    SearchFilled,
    StopFilled,
    StopRegular,
    ClockFilled,
    ClockRegular,
    OpenFilled,
    OpenRegular,
    TagFilled,
    TagRegular,
    PeopleAddFilled,
    PeopleAddRegular,
    DividerShortFilled,
    DividerShortRegular,
    StatusFilled,
    StatusRegular,
    MoreHorizontalRegular,
    MoreHorizontalFilled,
    ChevronDownRegular,
    ChevronDownFilled,
    ChevronUpRegular,
    ChevronUpFilled,
    FilterAddRegular,
    FilterAddFilled,
    CircleSmallRegular,
    CircleSmallFilled,
    AddRegular,
    AddFilled,
    SelectAllOffFilled,
    SelectAllOffRegular,
    LockClosedFilled,
    LockClosedRegular,
    PlugDisconnectedFilled,
    PlugDisconnectedRegular,
    PanelRightContractRegular,
    CheckmarkLockFilled,
    CheckmarkLockRegular,
    PanelRightContractFilled,
    PanelRightExpandRegular,
    PanelRightExpandFilled,
    DismissCircleFilled,
    DismissCircleRegular,
    LinkRegular,
    LinkFilled,
    LinkMultipleRegular,
    LinkMultipleFilled,
    CopySelectRegular,
    CopySelectFilled,
    AppsRegular,
    AppsFilled,
    PlugConnectedRegular,
    PlugConnectedFilled,
    ArrowResetRegular,
    ArrowResetFilled,
    MailRegular,
    MailFilled,
    ArrowFitRegular,
    ArrowFitFilled,
    ArrowFitInRegular,
    ArrowFitInFilled,
    ImageRegular,
    ImageFilled,
    DocumentArrowUpRegular,
    DocumentArrowUpFilled,
    PlayRegular,
    PlayFilled,
    PauseRegular,
    PauseFilled,
    CheckmarkCircleFilled,
    CheckmarkCircleRegular,
    ErrorCircleFilled,
    ErrorCircleRegular,
    SettingsRegular,
    SettingsFilled,
    OptionsRegular,
    OptionsFilled,
    PlugConnectedAddRegular,
    PlugConnectedAddFilled,
    AppsAddInRegular,
    AppsAddInFilled,
    ArrowUploadRegular,
    ArrowUploadFilled,
    DocumentCopyFilled,
    DocumentCopyRegular,
    PersonQuestionMarkFilled,
    PersonQuestionMarkRegular,
    CalendarPlayFilled,
    CalendarPlayRegular,
    NewsFilled,
    NewsRegular,
    PeopleCommunityFilled,
    PeopleCommunityRegular,
    PersonSupportFilled,
    PersonSupportRegular,
    VoteRegular,
    VoteFilled,
    QuestionFilled,
    QuestionRegular,
    DataUsageFilled,
    DataUsageRegular,
    LayerDiagonalFilled,
    LayerDiagonalRegular,
    CheckmarkFilled,
    CheckmarkRegular,
    HeartFilled,
    HeartRegular,
    DocumentErrorRegular,
    DocumentErrorFilled,
    ListBarFilled,
    ListBarRegular,
    ArrowSquareUpRightRegular,
    ArrowSquareUpRightFilled,
    QuestionCircleFilled,
    QuestionCircleRegular,
    BookAddFilled,
    BookAddRegular,
    EmojiMehFilled,
    EmojiMehRegular,
    ArrowUndoFilled,
    ArrowUndoRegular,
    WrenchFilled,
    WrenchRegular,
    SquaresNestedFilled,
    SquaresNestedRegular,
    InfoFilled,
    InfoRegular,
    EarthRegular,
    EarthFilled,
    AppsListDetailRegular,
    AppsListDetailFilled,
    SaveRegular,
    SaveFilled,
    PersonRegular,
    PersonFilled,
    LibraryRegular,
    LibraryFilled,
    DocumentPdfRegular,
    DocumentFilled,
    ShieldCheckmarkRegular,
    ShieldCheckmarkFilled,
    ArrowUpRegular,
    ArrowUpFilled,
    ArrowDownFilled,
    ArrowDownRegular,
    WarningFilled,
    WarningRegular,
    HeartPulseFilled,
    HeartPulseRegular,
    TextSortAscendingRegular,
    TextSortAscendingFilled,
    ListFilled,
    ListRegular,
    SlideTextMultipleFilled,
    SlideTextMultipleRegular,
    PersonLockFilled,
    PersonLockRegular,
    SpinnerIosRegular,
    DocumentCheckmarkRegular,
    CalendarClockRegular,
    CalendarClockFilled,
    CheckboxUncheckedRegular,
    BrainCircuitRegular,
    BrainCircuitFilled,
    LaptopPersonRegular,
    LaptopPersonFilled,
} from '@fluentui/react-icons';
import MicrosoftLogo from './customIcons/MicrosoftLogo';
import DataVizLogo from './customIcons/DataViz';
import QuestionMark from './customIcons/QuestionMark';

// Common
export const NestedSquareIcon = bundleIcon(SquaresNestedFilled, SquaresNestedRegular);
export const EarthIcon = bundleIcon(EarthFilled, EarthRegular);
export const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
export const FailedIcon = bundleIcon(DismissCircleFilled, DismissCircleRegular);
export const EditIcon = bundleIcon(EditFilled, EditRegular);
export const UndoIcon = bundleIcon(ArrowUndoFilled, ArrowUndoRegular);
export const FilterIcon = bundleIcon(FilterFilled, FilterRegular);
export const SubmitIcon = bundleIcon(ArrowRightFilled, ArrowRightRegular);
export const WindowNewIcon = bundleIcon(WindowNewFilled, WindowNewRegular);
export const DismissIcon = bundleIcon(DismissFilled, DismissRegular);
export const AcceptIcon = bundleIcon(CheckmarkFilled, CheckmarkRegular);
export const BasicAuthIcon = bundleIcon(PersonLockFilled, PersonLockRegular);
export const OAuthIcon = bundleIcon(CheckmarkLockFilled, CheckmarkLockRegular);
export const RandomizeIcon = bundleIcon(ArrowSyncFilled, ArrowSyncRegular);
export const ExpandIcon = bundleIcon(ExpandUpRightRegular, ExpandUpRightRegular);
export const CancelIcon = bundleIcon(StopFilled, StopRegular);
export const ClockIcon = bundleIcon(ClockFilled, ClockRegular);
export const TagIcon = bundleIcon(TagFilled, TagRegular);
export const PeopleIcon = bundleIcon(PeopleAddFilled, PeopleAddRegular);
export const DividerIcon = bundleIcon(DividerShortFilled, DividerShortRegular);
export const StatusIcon = bundleIcon(StatusFilled, StatusRegular);
export const MoreIcon = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);
export const DownIcon = bundleIcon(ChevronDownFilled, ChevronDownRegular);
export const UpIcon = bundleIcon(ChevronUpFilled, ChevronUpRegular);
export const FilterAddIcon = bundleIcon(FilterAddFilled, FilterAddRegular);
export const CircleIcon = bundleIcon(CircleSmallFilled, CircleSmallRegular);
export const AddIcon = bundleIcon(AddFilled, AddRegular);
export const LinkIcon = bundleIcon(LinkRegular, LinkFilled);
export const ExternalLinkIcon = bundleIcon(ArrowSquareUpRightFilled, ArrowSquareUpRightRegular);
export const LinkMultipleIcon = bundleIcon(LinkMultipleRegular, LinkMultipleFilled);
export const SelectAllIcon = bundleIcon(SelectAllOffFilled, SelectAllOffRegular);
export const ConnectionIcon = bundleIcon(PlugDisconnectedFilled, PlugDisconnectedRegular);
export const SearchIcon = bundleIcon(SearchFilled, SearchRegular);
export const ReturnIcon = bundleIcon(ArrowLeftFilled, ArrowLeftRegular);
export const ArrowUpIcon = bundleIcon(ArrowUpFilled, ArrowUpRegular);
export const ArrowDownIcon = bundleIcon(ArrowDownFilled, ArrowDownRegular);
export const SettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);
export const SubmittedIcon = bundleIcon(CheckmarkCircleFilled, CheckmarkCircleRegular);
export const SidePanelContractIcon = bundleIcon(
    PanelRightContractFilled,
    PanelRightContractRegular,
);
export const SidePanelExpandIcon = bundleIcon(PanelRightExpandFilled, PanelRightExpandRegular);
export const SkillsetIcon = bundleIcon(AppsFilled, AppsRegular);
export const ConnectedIcon = bundleIcon(PlugConnectedFilled, PlugConnectedRegular);
export const ConnectIcon = bundleIcon(PlugConnectedFilled, PlugConnectedRegular);
export const ResetIcon = bundleIcon(ArrowResetFilled, ArrowResetRegular);
export const ImageIcon = bundleIcon(ImageFilled, ImageRegular);
export const UploadIcon = bundleIcon(DocumentArrowUpFilled, DocumentArrowUpRegular);
export const OrgSettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);
export const OptionsIcon = bundleIcon(OptionsFilled, OptionsRegular);
export const AddSkillsetIcon = bundleIcon(PlugConnectedAddFilled, PlugConnectedAddRegular);
export const SkillsetsIcon = bundleIcon(AppsAddInFilled, AppsAddInRegular);
export const UploadSkillsetIcon = bundleIcon(ArrowUploadFilled, ArrowUploadRegular);
export const WarningIcon = bundleIcon(WarningFilled, WarningRegular);
export const MoreHorizontalIcon = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);
export const DataGridSortIcon = bundleIcon(TextSortAscendingFilled, TextSortAscendingRegular);
export const OwnerSettingsIcon = bundleIcon(PersonLockFilled, PersonLockRegular);

// Menu
export const NavigationIcon = bundleIcon(NavigationFilled, NavigationRegular);
export const HomeIcon = bundleIcon(HomeFilled, HomeRegular);
export const InvestigationsIcon = bundleIcon(DocumentSearchFilled, DocumentSearchRegular);
export const HelpIcon = bundleIcon(ChatHelpFilled, ChatHelpRegular);
export const FeedbackIcon = bundleIcon(PersonFeedbackFilled, PersonFeedbackRegular);
export const PanelLeftContractIcon = bundleIcon(PanelLeftContractFilled, PanelLeftContractRegular);
export const TutorialIcon = bundleIcon(HatGraduationFilled, HatGraduationRegular);
export const ThemeIcon = bundleIcon(DarkThemeFilled, DarkThemeRegular);
export const ResponseDebuggerIcon = bundleIcon(CodeFilled, CodeRegular);
export const AdminIcon = bundleIcon(LockClosedFilled, LockClosedRegular);

// Settings
export const DebugIcon = bundleIcon(CodeFilled, CodeRegular);
export const PrivacyIcon = bundleIcon(LockClosedFilled, LockClosedRegular);

// Investigation
export const EditStatusIcon = bundleIcon(CircleEditFilled, CircleEditRegular);
export const ShareIcon = bundleIcon(ShareFilled, ShareRegular);
export const CodeIcon = bundleIcon(CodeFilled, CodeRegular);
export const FlowchartIcon = bundleIcon(FlowchartFilled, FlowchartRegular);
export const MailIcon = bundleIcon(MailFilled, MailRegular);

// Session
export const PinIcon = bundleIcon(PinFilled, PinRegular);
export const PinOffIcon = bundleIcon(PinOffFilled, PinOffRegular);
export const RerunIcon = bundleIcon(ArrowClockwiseFilled, ArrowClockwiseRegular);
export const SkillIcon = bundleIcon(SparkleFilled, SparkleRegular);
export const ExportIcon = bundleIcon(ArrowDownloadFilled, ArrowDownloadRegular);
export const NavigateBackIcon = bundleIcon(ArrowLeftFilled, ArrowLeftRegular);
export const CopyIcon = bundleIcon(CopyFilled, CopyRegular);
export const CompactViewIcon = bundleIcon(ArrowFitInFilled, ArrowFitInRegular);
export const FullwidthViewIcon = bundleIcon(ArrowFitFilled, ArrowFitRegular);
export const SystemCapabilityIcon = bundleIcon(ListFilled, ListRegular);
export const PromptIcon = bundleIcon(SlideTextMultipleFilled, SlideTextMultipleRegular);

// Feedback
export const HelpfulFeedbackIcon = bundleIcon(CheckmarkStarburstFilled, CheckmarkStarburstRegular);
export const UnhelpfulFeedbackIcon = bundleIcon(TargetEditFilled, TargetEditRegular);
export const OffTargetFeedbackIcon = bundleIcon(WrenchFilled, WrenchRegular);
export const ReportFeedbackIcon = bundleIcon(FlagFilled, FlagRegular);
export const SendIcon = bundleIcon(SendFilled, SendRegular);
export const DocumentIcon = bundleIcon(DocumentCopyFilled, DocumentCopyRegular);
export const FaqIcon = bundleIcon(PersonQuestionMarkFilled, PersonQuestionMarkRegular);
export const InteractiveTourIcon = bundleIcon(CalendarPlayFilled, CalendarPlayRegular);
export const WhatsNewIcon = bundleIcon(NewsFilled, NewsRegular);
export const CommunityIcon = bundleIcon(PeopleCommunityFilled, PeopleCommunityRegular);
export const SupportIcon = bundleIcon(PersonSupportFilled, PersonSupportRegular);
export const GiveFeedbackIcon = bundleIcon(VoteFilled, VoteRegular);
export const HelpMenuIcon = bundleIcon(QuestionFilled, QuestionRegular);
export const ComplimentIcon = bundleIcon(HeartFilled, HeartRegular);
export const ReportErrorIcon = bundleIcon(DocumentErrorFilled, DocumentErrorRegular);
export const SubmitEvaluationFeedbackIcon = bundleIcon(EmojiMehFilled, EmojiMehRegular);

export const RunIcon = bundleIcon(PlayRegular, PlayRegular);

export const StopIcon = bundleIcon(StopRegular, StopRegular);
export const CheckboxUncheckedIcon = bundleIcon(CheckboxUncheckedRegular, CheckboxUncheckedRegular);

// Tour
export const BackIcon = bundleIcon(ChevronLeftFilled, ChevronLeftRegular);
export const NextIcon = bundleIcon(ChevronRightFilled, ChevronRightRegular);
export const ExitIcon = bundleIcon(ArrowExitFilled, ArrowExitRegular);
export const PlayIcon = bundleIcon(PlayFilled, PlayRegular);
export const PauseIcon = bundleIcon(PauseFilled, PauseRegular);
export const HeartBeatSizeIcon = bundleIcon(HeartPulseFilled, HeartPulseRegular);
export const InfoIcon = bundleIcon(InfoFilled, InfoRegular);
export const Shield = bundleIcon(ShieldCheckmarkFilled, ShieldCheckmarkRegular);
export const WarningTourIcon = bundleIcon(WarningFilled, WarningRegular);
export const ExpiringIcon = bundleIcon(CalendarClockFilled, CalendarClockRegular);

// Custom Icons
export const MicrosoftIcon = MicrosoftLogo;

export const DataVizIcon = DataVizLogo;

export const QuestionMarkIcon = QuestionMark;
// Edit Investigation Icons
export const DuplicateIcon = bundleIcon(CopySelectFilled, CopySelectRegular);

// Debugger States Icons
export const CompletionIcon = bundleIcon(CheckmarkCircleFilled, CheckmarkCircleRegular);
export const ErrorIcon = bundleIcon(ErrorCircleFilled, ErrorCircleRegular);
export const CancelledIcon = bundleIcon(DismissCircleFilled, DismissCircleRegular);
export const QuestionCircleIcon = bundleIcon(QuestionCircleFilled, QuestionCircleRegular);

// Promptbook Icons
export const PromptbookIcon = bundleIcon(NotebookFilled, NotebookRegular);
export const PromptbookListIcon = bundleIcon(ListBarFilled, ListBarRegular);
export const CreatePromptbookIcon = bundleIcon(BookAddFilled, BookAddRegular);
export const PromptbookDetailsIcon = bundleIcon(AppsListDetailRegular, AppsListDetailFilled);
export const SaveIcon = bundleIcon(SaveRegular, SaveFilled);
export const PersonIcon = bundleIcon(PersonFilled, PersonRegular);

// File Icons
export const LibraryIcon = bundleIcon(LibraryFilled, LibraryRegular);
export const PdfIcon = DocumentPdfRegular;
export const SuccessFileIcon = bundleIcon(DocumentFilled, DocumentCheckmarkRegular);
export const ErrorFileIcon = bundleIcon(DocumentErrorFilled, DocumentErrorRegular);
export const InProgressFileIcon = bundleIcon(DocumentSearchFilled, SpinnerIosRegular);

// Manage Workspaces
export const OpenIcon = bundleIcon(OpenFilled, OpenRegular);
export const WorkspacesIcon = bundleIcon(LayerDiagonalFilled, LayerDiagonalRegular);
export const DataUsageIcon = bundleIcon(DataUsageFilled, DataUsageRegular);
export const CheckmarkIcon = bundleIcon(CheckmarkFilled, CheckmarkRegular);
export const DividerShort16Icon = bundleIcon(DividerShortFilled, DividerShortRegular);

// Agent
export const DefaultOrchestratorIcon = bundleIcon(BrainCircuitFilled, BrainCircuitRegular);
export const AgentIcon = bundleIcon(LaptopPersonFilled, LaptopPersonRegular);

// Icon aliases
export const SuccessIcon = CompletionIcon;
