import {useCallback, useEffect, useMemo, useState} from 'react';

import useClasses from './Session.styles';
import {mergeClasses, Spinner, MenuButton, Tooltip} from '@fluentui/react-components';
import {SessionProps} from './Session.types';
import SessionPrompts from './SessionPrompts';
import {InvestigationPanel} from '../investigations';
import useNavActions from '@/components/Navigation/useNavActions';
import {useGetSession, useGetPinboardSummary, useIsUserSessionOwner} from '@/api/sessions';
import ShareSessionDialog from './ShareSessionDialog';
import useSidePanel from '@/components/ui/SidePanel/useSidePanel';
import ExportDocument from '@/util/Export/ExportDocument';
import {useSummarizeInvestigation} from '@/api/sessions/useSummarizeSession';
import {useGetPinnedEvaluations} from '@/api/evaluations';
import {ExportIcon} from '@/components/ui/icons';
import {useLayout} from '@/components/ui/Layout';
import useDefenderIntroTour from '../tours/defender/useDefenderIntroTour';
import MedeinaFeatures, {MedeinaUrlParamFeatures} from '@/util/features';
import {useTranslation} from 'react-i18next';
import ShareSessionButton from './ShareSessionButton';
import {useViewportSize} from '@/components/ui/Grid';
import {useFeatureFlag} from '@/api/user';
import SkillsetCreator from '@/components/ui/SkillsetCreator/SkillsetCreator';

export * from './Session.types';

export default function Session({sessionId}: SessionProps) {
    useDefenderIntroTour();
    // use the common and session namespaces
    const {t} = useTranslation(['session']);
    const classes = useClasses();
    const setSidePanel = useSidePanel();
    const {data: session, isLoading, isError, refetch: getSession} = useGetSession({sessionId});

    // If the user is not the owner of the session, they should not be able to edit it.
    // Assume the user is the owner until able to determine once the session is loaded.
    const {isUserSessionOwner: isUserSessionOwnerResponse} = useIsUserSessionOwner(sessionId);
    const [fetchedSharedSession, setFetchedSharedSession] = useState<boolean>(false);
    const [isSessionOwner, setIsSessionOwner] = useState<boolean>(true);
    const [openShareDialog, setOpenShareDialog] = useState(false);
    useEffect(() => {
        // Handle fetching shared sessions if the user is not the owner of the session
        if (isError && !isUserSessionOwnerResponse && !fetchedSharedSession) {
            getSession();
            setIsSessionOwner(false);
            setFetchedSharedSession(true);
        }
    }, [isError, isUserSessionOwnerResponse, getSession, fetchedSharedSession]);

    const {data: pinnedEvaluations} = useGetPinnedEvaluations({
        sessionId,
    });
    const {data: summaryResponse} = useSummarizeInvestigation({
        sessionId,
        pinnedItems: pinnedEvaluations?.value || [],
    });
    const {
        data: getSummaryResponse,
        isLoading: isSummaryLoading,
        isError: isSummaryError,
    } = useGetPinboardSummary({
        sessionId,
        pinnedItems: pinnedEvaluations?.value || [],
        sessionData: session,
    });
    const {sidePanel} = useLayout();
    const {setNavActions} = useNavActions();
    const screen = useViewportSize();

    // Feature flags
    const isAgentViewEnabled = useFeatureFlag(MedeinaUrlParamFeatures.AgentView);

    // we care if the screen switches to medium width and below
    useEffect(() => {
        if (screen.sm || screen.md) {
            // if we have switched to a small screen, close the side panel
            sidePanel?.toggleClosed?.();
        }
    }, [screen]);

    useEffect(() => {
        setSidePanel(
            session ? (
                isAgentViewEnabled ? (
                    <div className={classes.skillsetCreator}>
                        <SkillsetCreator onCloseCreator={() => sidePanel?.toggleClosed?.()} />
                    </div>
                ) : (
                    <InvestigationPanel
                        sessionId={session.sessionId}
                        isReadOnly={!isSessionOwner}
                    />
                )
            ) : null,
        );
    }, [isSessionOwner, session, setSidePanel]);

    const getPinnedEvaluationIds = useCallback(() => {
        let evaluationIds =
            pinnedEvaluations?.value.map((evaluation) => String(evaluation.evaluationId)) || [];
        return evaluationIds;
    }, [pinnedEvaluations]);

    const sessionName = session?.name || '';
    const evaluationSummary = !MedeinaFeatures.SavePinboardSummary
        ? summaryResponse?.summary
        : getSummaryResponse?.pinboardSummary?.summary;

    const sessionNavActions = useMemo(() => {
        const actions = [];
        if (sessionName) {
            actions.push(
                <ShareSessionButton
                    onClick={() => setOpenShareDialog(true)}
                    isUserSessionOwner={isUserSessionOwnerResponse}
                />,
            );
        }
        if (evaluationSummary) {
            actions.push(
                <ExportDocument
                    evaluation={evaluationSummary}
                    sessionId={sessionId}
                    evaluationIds={getPinnedEvaluationIds()}
                    investigationTitle={sessionName}
                    isSummaryExport={true}
                    key={`sessionExport${sessionId}`}
                    isUserSessionOwner={isSessionOwner}
                >
                    <Tooltip content={t('TooltipExportSessionSummary')} relationship="label">
                        <MenuButton
                            size="small"
                            appearance="transparent"
                            icon={<ExportIcon />}
                            menuIcon={null}
                        >
                            {t('MenuButtonExport')}
                        </MenuButton>
                    </Tooltip>
                </ExportDocument>,
            );
        }
        return actions;
    }, [sessionName, evaluationSummary, sessionId, isSessionOwner, getPinnedEvaluationIds, t]);

    // small hacky way to keep the navAction button up to date
    // TODO: Move this to a higher component that deals with the pane
    useEffect(() => {
        setNavActions(sessionNavActions);
    }, [sessionNavActions]);

    return (
        <>
            <div
                className={
                    sidePanel.open
                        ? mergeClasses(classes.root, classes.investigationRoot)
                        : classes.root
                }
            >
                {session && session.sessionId ? (
                    <div className={classes.session}>
                        <SessionPrompts sessionId={sessionId} isReadOnly={!isSessionOwner} />
                    </div>
                ) : (
                    <div className={classes.message}>
                        {(isLoading || isSummaryLoading) && !isError && <Spinner />}
                        <div aria-live="polite">
                            {(isError || isSummaryError) && t('SessionNotFoundOrNoPermission')}
                        </div>
                    </div>
                )}
            </div>
            {session?.name && (
                <ShareSessionDialog
                    key={`sessionShare${sessionId}`}
                    sessionId={sessionId}
                    sessionName={session.name}
                    isUserSessionOwner={isSessionOwner}
                    open={openShareDialog}
                    onClose={() => setOpenShareDialog(false)}
                />
            )}
        </>
    );
}
