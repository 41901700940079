import * as React from 'react';
import {useEffect, useState} from 'react';

import {Accordion, AccordionToggleEventHandler} from '@fluentui/react-components';
import RoleSection from './RoleSection';
import {GetRoleMembersResponse, useGetRoleMemberDetails} from '@/api/rbac';
import {
    Condition,
    PolicyWorkspace,
    RoleMember,
    RoleMembers,
    RoleMemberIds,
    GetRoleByNameResponse,
    MemberResponseItem,
} from '@/api/rbac/rbac.types';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {
    MemberType,
    RoleObjectIdData,
    RoleType,
    RemoveProps,
    RemoveMemberType,
} from '@/components/admin/rbac/rbac.types';
import {MEMBER_REMOVE_PROPS} from './MemberRemoveProps.constants';
import {useTranslation} from 'react-i18next';
import {
    PARTNER_ROLES_CONDITION,
    PARTNER_ROLES_ID,
    ROLE_POLICY_NAME_PREFIX_MAP,
} from './rbac.constants';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface RoleGroupProps {
    workspacePolicy: PolicyWorkspace;
    werePoliciesSuccessfullyFetched: boolean;
    onPolicySuccessfullyProcessed?: (roleObjectIdData: RoleObjectIdData) => void;
    dataRefetching: boolean;
    onGraphDataError?: () => void;
    onGraphDataLoaded?: () => void;
    onGraphDataLoading?: () => void;
    isAddRoleMemberError: boolean;
    isDataLoading: boolean;
    policyReferenceName: string;
    onRecommendedRolesDetected?: (hasRecommendedRoles: boolean) => void;
}
export default function RoleGroup({
    workspacePolicy,
    werePoliciesSuccessfullyFetched,
    onPolicySuccessfullyProcessed,
    dataRefetching,
    onGraphDataError,
    onGraphDataLoaded,
    onGraphDataLoading,
    isAddRoleMemberError,
    isDataLoading,
    policyReferenceName,
    onRecommendedRolesDetected,
}: RoleGroupProps) {
    const [openItems, setOpenItems] = useState<string[]>([]);
    const [roleData, setRoleData] = useState<GetRoleMembersResponse>();
    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems ?? []);
    };
    const is1pRbacEnabled = useFeatureFlag(MedeinaFeatures.Enable1pRbac);
    const enablePurviewDgRoles = useFeatureFlag(MedeinaFeatures.EnableDataGovernanceRoles);

    const {
        mutateAsync: getRoleMemberDetails,
        isSuccess: graphDataSuccess,
        isError: graphDataError,
        isLoading: graphDataLoading,
    } = useGetRoleMemberDetails({workspacePolicy, onRecommendedRolesDetected});

    useEffect(() => {
        if (graphDataError) {
            onGraphDataError?.();
        }
    }, [graphDataError]);

    const [ownerRoleMembers, setOwnerRoleMembers] = useState<RoleMembers>({
        users: [],
        roles: [],
        groups: [],
    });
    const [contributorRoleMembers, setContributorRoleMembers] = useState<RoleMembers>({
        users: [],
        roles: [],
        groups: [],
    });

    const [graphResponse, setGraphResponse] = useState<GetRoleByNameResponse>({
        details: {
            User: [],
            Group: [],
            Role: [],
        },
    });

    const [policyProcessed, setPolicyProcessed] = useState<boolean>(false);

    useEffect(() => {
        if (dataRefetching) {
            setPolicyProcessed(false);
        }
    }, [dataRefetching]);

    // TO generate the owner and contributors data with respective users,roles and groups memebrs list using graph and policy APIs.
    useEffect(() => {
        if (werePoliciesSuccessfullyFetched && !!workspacePolicy && !!policyReferenceName) {
            onGraphDataLoading?.();
            setRoleData(workspacePolicy);

            // Initialize the ownerRoleMemberIds and contributorRoleMemberIds objects
            let ownerRoleMemberIds: RoleMemberIds = {IamItems: {User: [], Role: [], Group: []}};
            let contributorRoleMemberIds: RoleMemberIds = {
                IamItems: {User: [], Role: [], Group: []},
            };

            // Find the attribute rule for the workspace-owner role and get the role member IDs
            workspacePolicy.properties.attributeRules
                .find(
                    (x) =>
                        x.id ===
                        'purviewworkspacerole_builtin_workspace-owner:' + policyReferenceName,
                )
                ?.dnfCondition?.map((conditions) =>
                    getRoleMemberIds(conditions, ownerRoleMemberIds),
                );

            // Find the attribute rule for the workspace-contributor role and get the role member IDs
            workspacePolicy.properties.attributeRules
                .find(
                    (x) =>
                        x.id ===
                        'purviewworkspacerole_builtin_workspace-contributor:' + policyReferenceName,
                )
                ?.dnfCondition?.map((conditions) =>
                    getRoleMemberIds(conditions, contributorRoleMemberIds),
                );

            // Combine the owner and contributor role member IDs
            let allRoleMemberIds: RoleMemberIds = {
                IamItems: {
                    User: [
                        ...new Set([
                            ...ownerRoleMemberIds.IamItems?.User,
                            ...contributorRoleMemberIds.IamItems?.User,
                        ]),
                    ],
                    Role: [
                        ...new Set([
                            ...ownerRoleMemberIds.IamItems?.Role,
                            ...contributorRoleMemberIds.IamItems?.Role,
                        ]),
                    ],
                    Group: [
                        ...new Set([
                            ...ownerRoleMemberIds.IamItems?.Group,
                            ...contributorRoleMemberIds.IamItems?.Group,
                        ]),
                    ],
                },
            };

            // Fetch the role member details from the graph API and extract owner/ contributor information and set the respective objects
            fetchAllRoleMemberDetailsFromGraph(allRoleMemberIds)
                .then((data) => {
                    setGraphResponse(data as GetRoleByNameResponse);
                    const response = data as GetRoleByNameResponse;

                    const roleMembers = getRoleMembersWithDetails(
                        ownerRoleMemberIds,
                        response,
                        MEMBER_REMOVE_PROPS.owner,
                    );
                    setOwnerRoleMembers(roleMembers);

                    const contributorDetails = getRoleMembersWithDetails(
                        contributorRoleMemberIds,
                        response,
                        MEMBER_REMOVE_PROPS.contributor,
                    );

                    setContributorRoleMembers(contributorDetails);

                    const roleObjectIdData: RoleObjectIdData = {
                        [RoleType.Owner]: {
                            [MemberType.User]: ownerRoleMemberIds.IamItems.User,
                            [MemberType.Role]: ownerRoleMemberIds.IamItems.Role,
                            [MemberType.Group]: ownerRoleMemberIds.IamItems.Group,
                        },
                        [RoleType.Contributor]: {
                            [MemberType.User]: contributorRoleMemberIds.IamItems.User,
                            [MemberType.Role]: contributorRoleMemberIds.IamItems.Role,
                            [MemberType.Group]: contributorRoleMemberIds.IamItems.Group,
                        },
                    };

                    if (is1pRbacEnabled) {
                        // Check if recommended roles are present in the policy
                        const hasRecommendedRoles = workspacePolicy.properties.attributeRules.some(
                            (rule) =>
                                rule.id ===
                                    `${ROLE_POLICY_NAME_PREFIX_MAP[RoleType.Contributor]}:${
                                        workspacePolicy.properties.entity.referenceName
                                    }` &&
                                rule.dnfCondition.some(
                                    (condition) =>
                                        JSON.stringify(condition) ===
                                        JSON.stringify(PARTNER_ROLES_CONDITION),
                                ),
                        );

                        onRecommendedRolesDetected?.(hasRecommendedRoles);

                        if (hasRecommendedRoles) {
                            const entraRolesObj = t(
                                'RoleAssignment.AddRoles.RoleTypes.EntraRoles.Roles',
                                {
                                    returnObjects: true,
                                },
                            ) as {[key: string]: string};

                            const purviewRiskRolesObj = t(
                                'RoleAssignment.AddRoles.RoleTypes.PurviewRoles.Roles',
                                {
                                    returnObjects: true,
                                },
                            ) as {[key: string]: string};

                            const purviewDgRolesObj = t(
                                'RoleAssignment.AddRoles.RoleTypes.PurviewDgRoles.Roles',
                                {
                                    returnObjects: true,
                                },
                            ) as {[key: string]: string};

                            let totalRoles =
                                Object.values(entraRolesObj).length +
                                Object.values(purviewRiskRolesObj).length;

                            if (enablePurviewDgRoles) {
                                totalRoles += Object.values(purviewDgRolesObj).length;
                            }

                            const rolesListDisplayName = t('RoleAssignment.AddRoles.DisplayName');

                            setContributorRoleMembers((prevState) => ({
                                ...prevState,
                                users: [
                                    ...prevState.users,
                                    {
                                        id: PARTNER_ROLES_ID,
                                        displayName: rolesListDisplayName,
                                        mail: `Group • ${totalRoles.toString()} roles`,
                                        canBeDeleted: true,
                                    },
                                ],
                            }));
                        }
                    }
                    setPolicyProcessed(true);
                    onPolicySuccessfullyProcessed?.(roleObjectIdData);
                    onGraphDataLoaded?.();
                })
                .catch((x) => {
                    onGraphDataLoaded?.();
                });
        }
    }, [werePoliciesSuccessfullyFetched, workspacePolicy, policyReferenceName]);

    //  Fetch member details using Graph API.
    async function fetchAllRoleMemberDetailsFromGraph(allRoleMemberIds: RoleMemberIds) {
        try {
            return await getRoleMemberDetails(allRoleMemberIds);
        } catch (error) {
            //TODO:: How to handle the error?
            console.log('Fetching GraphResponse errored.');
        }
    }

    // To filter the user,role and group ids from all members in policy.
    function getRoleMemberIds(conditions: Condition[], roleMemberIds: RoleMemberIds) {
        if (conditions[0].attributeName === 'principal.microsoft.id') {
            roleMemberIds.IamItems.User = getRoleMemberIdsList(conditions);
        }
        if (conditions[0].attributeName === 'principal.microsoft.aad.approles') {
            roleMemberIds.IamItems.Role = getRoleMemberIdsList(conditions);
        }
        if (conditions[0].attributeName === 'principal.microsoft.groups') {
            roleMemberIds.IamItems.Group = getRoleMemberIdsList(conditions);
        }
    }

    //  Returns a list of unique member ids based on policy API data.
    function getRoleMemberIdsList(condition: Condition[]) {
        return (
            condition[0]?.attributeValueIncludedIn?.map((id) => {
                return id;
            }) ?? []
        );
    }

    //  Returns an object containing users,roles and groups role members details.
    function getRoleMembersWithDetails<RoleMembers>(
        roleMemberIds: RoleMemberIds,
        graphResponse: GetRoleByNameResponse,
        roleRemove: RemoveMemberType,
    ) {
        let roleMembersWithDetails = {
            users: getRoleMembersWithDetails(
                roleMemberIds.IamItems.User,
                graphResponse.details.User,
                MemberType.User,
                roleRemove.User,
            ),
            roles: getRoleMembersWithDetails(
                roleMemberIds.IamItems.Role,
                graphResponse.details.Role,
                MemberType.Role,
                roleRemove.Role,
            ),
            groups: getRoleMembersWithDetails(
                roleMemberIds.IamItems.Group,
                graphResponse.details.Group,
                MemberType.Group,
                roleRemove.Group,
            ),
        };

        function getRoleMembersWithDetails(
            ids: string[],
            members: MemberResponseItem[],
            type: MemberType,
            removeProp: RemoveProps[],
        ) {
            return ids.map((id) => {
                let memberResponseItem: MemberResponseItem = members.find((x) => x.id === id) ?? {};
                let canBeDeleted: boolean =
                    removeProp.find((x) => x.id === id)?.canBeDeleted ?? true;
                let roleMember: RoleMember = {...memberResponseItem, type, canBeDeleted};
                return roleMember;
            });
        }
        return roleMembersWithDetails;
    }

    const {t} = useTranslation('admin');

    return (
        <Accordion
            openItems={openItems}
            onToggle={handleToggle}
            multiple
            collapsible
            as="div"
            hidden={
                (!werePoliciesSuccessfullyFetched || !graphDataSuccess || isDataLoading) &&
                !isAddRoleMemberError
            }
        >
            <RoleSection
                sectionId="1"
                sectionValue={t('RoleAssignment.RoleGroup.Owner.SectionHeading')}
                header={t('RoleAssignment.RoleGroup.Owner.SectionHeading')}
                infoContent={t('RoleAssignment.RoleGroup.Owner.SectionInfoContent')}
                roleMembers={ownerRoleMembers}
                workspacePolicy={workspacePolicy}
                role={RoleType.Owner}
                policiesSuccessfullyFetched={werePoliciesSuccessfullyFetched}
                policyProcessed={policyProcessed}
            />
            <RoleSection
                sectionId="2"
                sectionValue={t('RoleAssignment.RoleGroup.Contributor.SectionHeading')}
                header={t('RoleAssignment.RoleGroup.Contributor.SectionHeading')}
                infoContent={t('RoleAssignment.RoleGroup.Contributor.SectionInfoContent')}
                roleMembers={contributorRoleMembers}
                workspacePolicy={workspacePolicy}
                role={RoleType.Contributor}
                policiesSuccessfullyFetched={werePoliciesSuccessfullyFetched}
                policyProcessed={policyProcessed}
            />
        </Accordion>
    );
}
