import {shorthands, makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        maxWidth: '600px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('28px'),
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: tokens.spacingVerticalXXXL,
    },
    leftActions: {
        marginRight: 'auto',
    },
    rightActions: {
        display: 'flex',
        marginLeft: 'auto',
        gap: '0.5rem', // Space between right buttons
    },
    peoplepicker: {
        width: '100%',
    },
    newCapacityLinkStyle: {
        marginLeft: tokens.spacingHorizontalSNudge,
        marginRight: tokens.spacingHorizontalSNudge,
        marginTop: tokens.spacingVerticalSNudge,
    },
    hidden: {
        display: 'none',
    },
});
