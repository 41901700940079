import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {DismissIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import useClasses from './ErrorDialogs.styles';

interface SetErrorWorkspaceDialogProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
}
// error dialog for switching current workspace or setting preferred embedded workspace
export default function ErrorSettingWorkspace(props: SetErrorWorkspaceDialogProps) {
    const [isErrorWorkspaceDialogOpen, setErrorWorkspaceDialogOpen] = useState<boolean>(false);
    const {t} = useTranslation('admin');
    const classes = useClasses();

    const closeErrorPreferredWorkspaceDialogConfirmation = () => {
        props.onClose();
    };

    useEffect(() => {
        setErrorWorkspaceDialogOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        <Dialog open={isErrorWorkspaceDialogOpen}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label={t('ButtonLabels.Close')}
                                    icon={<DismissIcon />}
                                    onClick={closeErrorPreferredWorkspaceDialogConfirmation}
                                />
                            </DialogTrigger>
                        }
                    >
                        <div className={classes.titleContainer}>
                            <div>{props.title}</div>
                        </div>
                    </DialogTitle>
                    <DialogContent className={classes.contentSection}>
                        <div>{t('ManageWorkspaces.Errors.WaitAndTryAgain')}</div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
