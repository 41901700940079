import {ButtonProps} from '@fluentui/react-components';
import MedeinaFeatures from './features';
import {useFeatureFlag} from '@/api/user';

interface ForbiddenMessagingProps {
    heading: string;
    subHeading?: string;
    actionButtonProps?: ButtonProps;
}

export const forbiddenMessageMappings: Record<string, ForbiddenMessagingProps> = {
    MissingSecurityCopilotRoleAssignment: {
        heading: 'ForbiddenContent.MissingSecurityCopilotRoleAssignment.heading',
        subHeading: 'ForbiddenContent.MissingSecurityCopilotRoleAssignment.subHeading',
    },
    InvalidRegion: {
        heading: 'ForbiddenContent.InvalidRegion.heading',
        subHeading: 'ForbiddenContent.InvalidRegion.subHeading',
        actionButtonProps: {
            children: 'ForbiddenContent.InvalidRegion.regions',
        },
    },
    SecurityCopilotNotSetup: {
        heading: 'ForbiddenContent.SecurityCopilotNotSetup.heading',
        subHeading: 'ForbiddenContent.SecurityCopilotNotSetup.subHeading',
    },
    UnauthorizedPage: {
        heading: 'ForbiddenContent.UnauthorizedPage.heading',
        subHeading: 'ForbiddenContent.UnauthorizedPage.subHeading',
    },
    AuthorizeFetchFailed: {
        heading: 'ForbiddenContent.AuthorizeFetchFailed.heading',
        subHeading: 'ForbiddenContent.AuthorizeFetchFailed.subHeading',
    },
    CapacitiesRemovedAdmin: {
        heading: 'ForbiddenContent.CapacitiesRemovedAdmin.heading',
        subHeading: 'ForbiddenContent.CapacitiesRemovedAdmin.subHeading',
        actionButtonProps: {
            children: 'ForbiddenContent.CapacitiesRemovedAdmin.purchaseNewCapacity',
        },
    },
    WorkspacesCapacitiesRemovedNonAdmin: {
        heading: 'ForbiddenContent.WorkspacesCapacitiesRemovedNonAdmin.heading',
        subHeading: 'ForbiddenContent.CapacitiesRemovedNonAdmin.subHeading',
    },
    CapacitiesRemovedNonAdmin: {
        heading: 'ForbiddenContent.CapacitiesRemovedNonAdmin.heading',
        subHeading: 'ForbiddenContent.CapacitiesRemovedNonAdmin.subHeading',
    },
    default: {
        heading: 'ForbiddenContent.Default.heading',
        subHeading: 'ForbiddenContent.Default.subHeading',
        actionButtonProps: {
            children: 'ForbiddenContent.Default.refresh',
            onClick: () => window.location.reload(),
        },
    },
    defaultEAP: {
        heading: 'ForbiddenContent.PreConsumptionModelDefault.heading',
        subHeading: 'ForbiddenContent.PreConsumptionModelDefault.subHeading',
    },
};

export function useForbiddenUserFriendlyMessage(message: string): ForbiddenMessagingProps {
    const isFidelisEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisGA);

    return (
        forbiddenMessageMappings[message] ||
        (isFidelisEnabled ? forbiddenMessageMappings.default : forbiddenMessageMappings.defaultEAP)
    );
}
