import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    AvatarGroup,
    AvatarGroupItem,
    AvatarGroupPopover,
    partitionAvatarGroupItems,
    Tooltip,
} from '@fluentui/react-components';
import {GetRoleByNameResponse, useGetRoleMemberDetails} from '@/api/rbac';
import {MEMBER_REMOVE_PROPS} from '@/components/admin/rbac/MemberRemoveProps.constants';
import {
    PolicyWorkspace,
    RoleMembers,
    RoleMember,
    RoleMemberIds,
    MemberResponseItem,
} from '@/api/rbac/rbac.types';
import {
    MemberType,
    RemoveProps,
    RemoveMemberType,
    RoleObjectIdData,
} from '@/components/admin/rbac/rbac.types';
import {useMsal} from '@azure/msal-react';
import {UserProfile} from '@/api/user';
import {Workspace} from '@/api/workspaces';
interface UserPileProps {
    workspace: Workspace;
    roleMemberIds: RoleMemberIds;
    workspacePolicy: PolicyWorkspace;
    onRecommendedRolesDetected?: (hasRecommendedRoles: boolean) => void;
    getOwnerMembers: boolean;
    onPolicySuccessfullyProcessed?: (roleObjectIdData: RoleObjectIdData) => void;
    setPolicyProcessed: (processed: boolean) => void;
    roleObjectIdData: RoleObjectIdData;
    onOwnerDataProcessed?: (isUserOwner: boolean, workspace: Workspace) => void;
}

export default function UserPile({
    workspace,
    roleMemberIds,
    workspacePolicy,
    onRecommendedRolesDetected,
    getOwnerMembers,
    onPolicySuccessfullyProcessed,
    setPolicyProcessed,
    roleObjectIdData,
    onOwnerDataProcessed,
}: UserPileProps) {
    const [ownerRoleMembers, setOwnerRoleMembers] = useState<RoleMembers>({
        users: [],
        roles: [],
        groups: [],
    });
    const [contributorRoleMembers, setContributorRoleMembers] = useState<RoleMembers>({
        users: [],
        roles: [],
        groups: [],
    });
    const {
        mutateAsync: getRoleMemberDetails,
        isSuccess: graphDataSuccess,
        isError: graphDataError,
        isLoading: graphDataLoading,
    } = useGetRoleMemberDetails({workspacePolicy, onRecommendedRolesDetected});
    const [graphResponse, setGraphResponse] = useState<GetRoleByNameResponse>({
        details: {
            User: [],
            Group: [],
            Role: [],
        },
    });

    useEffect(() => {
        fetchAllRoleMemberDetailsFromGraph(roleMemberIds);
    }, []);

    async function fetchAllRoleMemberDetailsFromGraph(allRoleMemberIds: RoleMemberIds) {
        try {
            return await getRoleMemberDetails(allRoleMemberIds, {
                onSuccess: (data) => {
                    setGraphResponse(data as GetRoleByNameResponse);
                    const response = data as GetRoleByNameResponse;
                    const roleMembers = getRoleMembersWithDetails(
                        allRoleMemberIds,
                        response,
                        getOwnerMembers
                            ? MEMBER_REMOVE_PROPS.owner
                            : MEMBER_REMOVE_PROPS.contributor,
                    );
                    if (getOwnerMembers) {
                        setOwnerRoleMembers(roleMembers);
                    } else {
                        setContributorRoleMembers(roleMembers);
                    }
                    setPolicyProcessed(true);
                    onPolicySuccessfullyProcessed?.(roleObjectIdData);
                },
            });
        } catch (error) {
            console.log('Fetching GraphResponse errored.');
        }
    }

    function getRoleMembersWithDetails<RoleMembers>(
        roleMemberIds: RoleMemberIds,
        graphResponse: GetRoleByNameResponse,
        roleRemove: RemoveMemberType,
    ) {
        const mapRoleMembers = (
            ids: string[],
            members: MemberResponseItem[],
            type: MemberType,
            removeProp: RemoveProps[],
        ) => {
            return ids.map((id) => {
                let memberResponseItem: MemberResponseItem = members.find((x) => x.id === id) ?? {};
                let canBeDeleted: boolean =
                    removeProp.find((x) => x.id === id)?.canBeDeleted ?? true;
                let roleMember: RoleMember = {...memberResponseItem, type, canBeDeleted};
                return roleMember;
            });
        };

        return {
            users: mapRoleMembers(
                roleMemberIds.IamItems.User,
                graphResponse.details.User,
                MemberType.User,
                roleRemove.User,
            ),
            roles: mapRoleMembers(
                roleMemberIds.IamItems.Role,
                graphResponse.details.Role,
                MemberType.Role,
                roleRemove.Role,
            ),
            groups: mapRoleMembers(
                roleMemberIds.IamItems.Group,
                graphResponse.details.Group,
                MemberType.Group,
                roleRemove.Group,
            ),
        };
    }
    // Display only role members with display names
    const getRoleMemberNames = (roleMembers: RoleMembers) =>
        roleMembers.users
            ?.filter((roleMember: RoleMember) => roleMember.displayName)
            .map((roleMember: RoleMember) => roleMember.displayName as string) || [];

    const ownerRoleMemberNames = getRoleMemberNames(ownerRoleMembers);
    const contributorRoleMemberNames = getRoleMemberNames(contributorRoleMembers);

    const partitionedOwners = partitionAvatarGroupItems({items: ownerRoleMemberNames});
    const partitionedContributors = partitionAvatarGroupItems({items: contributorRoleMemberNames});

    const [ownersLimit, setOwnersLimit] = useState(partitionedOwners.inlineItems.length);
    const [contributorsLimit, setContributorsLimit] = useState(
        partitionedContributors.inlineItems.length,
    );

    // check if user is owner of workspace
    const [isUserOwner, setIsUserOwner] = useState<boolean>(false);
    const {instance} = useMsal();
    const activeAccount = instance.getActiveAccount();
    const currentUserProfile: UserProfile = {
        id: activeAccount?.homeAccountId || '',
        mail: activeAccount?.username || '',
        displayName: activeAccount?.name || '',
        jobTitle: '',
        photo: activeAccount?.name || '',
    };
    useEffect(() => {
        setIsUserOwner(
            ownerRoleMembers.users.some(
                (roleMember: RoleMember) => roleMember.mail === currentUserProfile.mail,
            ),
        );
        if (isUserOwner) {
            onOwnerDataProcessed?.(isUserOwner, workspace);
        }
        // TODO: write to storage or workspace provider the workspace and user owner boolean
    }, []);

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        const limit =
            windowWidth < 400
                ? 1
                : windowWidth < 600
                ? 2
                : windowWidth < 1500
                ? 3
                : windowWidth < 2000
                ? 4
                : 6;
        setOwnersLimit(limit);
        setContributorsLimit(limit);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [partitionedOwners.inlineItems.length, partitionedContributors.inlineItems.length]);

    const renderAvatarGroup = (names: string[], limit: number) => {
        const partitionedItems = partitionAvatarGroupItems({items: names});
        const visibleItems = partitionedItems.inlineItems.slice(0, limit);
        const overflowItems = partitionedItems.inlineItems
            .slice(limit)
            .concat(partitionedItems.overflowItems ?? []);

        return (
            <AvatarGroup layout="stack" size={28}>
                {visibleItems.map((name) => (
                    <Tooltip content={name} relationship="label" withArrow key={name}>
                        <AvatarGroupItem name={name} />
                    </Tooltip>
                ))}
                {overflowItems.length > 0 && (
                    <AvatarGroupPopover>
                        {overflowItems.map((name) => (
                            <Tooltip content={name} relationship="label" withArrow key={name}>
                                <AvatarGroupItem name={name} />
                            </Tooltip>
                        ))}
                    </AvatarGroupPopover>
                )}
            </AvatarGroup>
        );
    };

    return (
        <>
            {getOwnerMembers
                ? renderAvatarGroup(ownerRoleMemberNames, ownersLimit)
                : renderAvatarGroup(contributorRoleMemberNames, contributorsLimit)}
        </>
    );
}
