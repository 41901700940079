import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalM,
    },
    skill: {
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(0, tokens.spacingHorizontalM, 0, tokens.spacingHorizontalXXL),
        fontSize: tokens.fontSizeBase300,
        position: 'relative',
    },
    skillIcon: {
        position: 'absolute',
        fontSize: tokens.fontSizeBase400,
        left: 0,
    },
    optionalFieldsButton: {
        border: 'none',
        backgroundColor: tokens.colorNeutralBackground5,
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding(0, 0, tokens.spacingVerticalS, 0),
        fontSize: tokens.fontSizeBase100,
        position: 'relative',
    },
    inputs: {
        display: 'flex',
        marginBottom: tokens.spacingVerticalL,
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalXS,
        ...shorthands.flex(1, 1, 'auto'),
        maxHeight: '60vh',
        '@media (min-width: 640px) and (max-width: 1023px)': {
            maxHeight: '45vh',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            maxHeight: '40vh',
        },
        '@media (min-width: 321px) and (max-width: 479px)': {
            maxHeight: '50vh',
        },
        '@media (max-width: 320px)': {
            maxHeight: '20vh',
        },
        overflowY: 'auto',
    },
    largeIcon: {
        position: 'absolute',
        fontSize: tokens.fontSizeBase300,
        bottom: '35%',
        left: '105%',
    },
});
