// ResourceGroupSelector.jsx
import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {Input, Button, useId} from '@fluentui/react-components';
import useClasses from '../ResourceGroupSelector.styles';
import {ResourceGroup} from '@/api/user/user.types';
import {useTranslation} from 'react-i18next';
import {MedeinaErrorLabel} from '@/components/ui/Buttons/MedeinaErrorLabel';
import {useViewportSize} from '@/components/ui/Grid';
import {ParagraphContent, TextSubtitle2} from '@/components/ui/Text';
import {AnnounceText} from '@/components/ui/AnnounceText';

interface ResourceGroupFieldProps {
    handleOkResourceGroupClick: (resourceGroup: string) => void;
    newResourceGroup?: ResourceGroup;
    dropdownResourceGroups: Array<ResourceGroup>;
    onClose: () => void;
}

function ResourceGroupField({
    newResourceGroup,
    handleOkResourceGroupClick,
    dropdownResourceGroups,
    onClose,
}: ResourceGroupFieldProps) {
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const classes = useClasses();
    const {sm: isSmallScreen} = useViewportSize();

    const buttonSize = isSmallScreen ? 'small' : 'medium';

    const paragraphContent = (
        <ParagraphContent>{t('adminV2Fre.setup.ResourceGroupSubTitle')}</ParagraphContent>
    );

    const headingContent = <TextSubtitle2>{commonLabel('CreateResourceGroup')}</TextSubtitle2>;

    const errorLabelID = useId('resource-group-error');
    const errorLabelIDEmpty = useId('resource-group-error-empty');
    const errorLabelIDInvalid = useId('resource-group-error-invalid');
    const errorLabelIDExisting = useId('resource-group-error-existing');
    const [fieldError, setFieldError] = useState({message: '', id: ''});
    const [resourceGroup, setResourceGroup] = useState(newResourceGroup?.name || '');
    // Function to validate the resource group name
    const isValidResourceGroupName = useCallback(() => {
        const pattern = /^[-\w\._\(\)]+$/;
        return resourceGroup.length > 0 && pattern.test(resourceGroup);
    }, [resourceGroup]);

    const checkResourceGroup = useCallback(() => {
        if (isValidResourceGroupName()) {
            return dropdownResourceGroups?.some((rg: ResourceGroup) => {
                return rg.name === `(new) ${resourceGroup}`;
            });
        }
    }, [dropdownResourceGroups, isValidResourceGroupName, resourceGroup]);

    const ariaDescribedProp = useMemo(
        () => (fieldError.message.length ? {'aria-describedby': fieldError.id} : {}),
        [fieldError],
    );
    // When we change the resource group, we want to check if it is valid
    // and set the correct error message and error message id
    useEffect(() => {
        let error = {message: '', id: ''};
        if (resourceGroup.length === 0) {
            error.id = errorLabelIDEmpty;
            error.message = t('adminV2Fre.setup.EmptyResourceGroup');
        } else if (!isValidResourceGroupName()) {
            error = {
                id: errorLabelIDInvalid,
                message: t('adminV2Fre.setup.InvalidResourceGroupRegex'),
            };
        } else if (checkResourceGroup()) {
            error = {
                id: errorLabelIDExisting,
                message: t('adminV2Fre.setup.ExistingResourceGroup'),
            };
        }
        if (error.message !== '') {
            setFieldError(error);
        } else {
            setFieldError({message: '', id: ''});
        }
    }, [
        checkResourceGroup,
        errorLabelIDEmpty,
        errorLabelIDExisting,
        errorLabelIDInvalid,
        isValidResourceGroupName,
        resourceGroup,
        t,
    ]);

    return (
        <div className={classes.resourceGroupPopoverContainer}>
            <div className={classes.largeSectionSpacing}>{headingContent}</div>
            <div className={classes.mediumSectionSpacing}>{paragraphContent}</div>
            <Input
                className={classes.popoverInput}
                aria-required="true"
                type="text"
                aria-label={t('adminV2Fre.setup.ariaLabel.ResourceGroup')}
                placeholder={t('adminV2Fre.setup.NameResourceGroup')}
                value={resourceGroup}
                onChange={(e) => setResourceGroup(e.target.value)}
                {...ariaDescribedProp}
            />
            {fieldError.message && (
                <MedeinaErrorLabel
                    errorContent={fieldError.message}
                    needsResponsiveStyles={true}
                    id={fieldError.id}
                />
            )}

            <div className={classes.popoverButtonsContainer}>
                <Button
                    size={buttonSize}
                    appearance="primary"
                    onClick={() => {
                        handleOkResourceGroupClick(resourceGroup);
                    }}
                    disabled={
                        !(resourceGroup.length > 0) ||
                        !isValidResourceGroupName() ||
                        checkResourceGroup()
                    }
                >
                    {t('adminV2Fre.setup.ResourceGroupOk')}
                </Button>
                <Button
                    size={buttonSize}
                    appearance="secondary"
                    onClick={() => {
                        onClose();
                    }}
                >
                    {t('adminV2Fre.setup.ResourceGroupCancel')}
                </Button>
            </div>
        </div>
    );
}

export default ResourceGroupField;
