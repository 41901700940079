import {useIsAuthenticated} from '@azure/msal-react';
import {useAuthorized, useGetUserInfo} from '@/api/app';
import SuccessAuthResult from '@/components/msal/SuccessAuthResult';
import ErrorAuthResult from '@/components/msal/ErrorAuthResult';
import Forbidden from './Forbidden';
import {Alert} from '@fluentui/react-components/unstable';
import {DismissIcon} from '@/components/ui/icons';
import MedeinaFeatures from '@/util/features';
import {useEffect, useState} from 'react';
import InfoMessageBar from '../sections/infoDisplay/InfoMessageBar';
import {useDetermineDenyPostAuthAccess} from '.';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import {useFeatureFlag} from '@/api/user';
import useDetermineFrePending from './useDetermineFrePending';
import {parseISO} from 'date-fns';
import useGetEapExpired from '@/api/app/useGetEapExpired';
import {clarity} from 'react-microsoft-clarity';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {ErrorBoundary, ViewErrorBoundary} from '@/components/ui/ErrorBoundary';
import {useLocation} from 'react-router-dom';
import {useGetWorkspaces} from '@/api/workspaces';

interface AuthorizedTemplateProps {
    children: React.ReactNode;
    isAdminViewable?: boolean;
}

export default function AuthorizedTemplate({children, isAdminViewable}: AuthorizedTemplateProps) {
    const {data: authorized, isError, isSuccess} = useAuthorized();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    let returnValue = null;
    const [isCapacityExpired, setIsCapacityExpired] = useState<boolean>(false);
    const [announcementBanner, setAnnouncementBanner] = useState<string | boolean>(
        MedeinaFeatures.AnnouncementBanner,
    );

    const {
        data: expiryDateData,
        isLoading: isExpiryDateLoading,
        isSuccess: isExpirySuccessful,
    } = useGetEapExpired();

    const {isInvalidRegion, isSetupInvalid, areCapacitiesRemoved, isProviderLoaded} =
        useWorkspaceState();
    useEffect(() => {
        if (isProviderLoaded && isExpirySuccessful && !!expiryDateData?.expiryDate) {
            try {
                const capacityExpiryDate = new Date(expiryDateData?.expiryDate);
                const expiryDate = parseISO(capacityExpiryDate.toISOString());
                setIsCapacityExpired(expiryDate < new Date());
            } catch {
                setIsCapacityExpired(false);
            }
        }
    }, [isProviderLoaded, isExpiryDateLoading]);

    const {isInvalidUser} = useDetermineDenyPostAuthAccess();
    const isFidelisEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisGA);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    const {data: userInfo} = useGetUserInfo();
    const isFrePending = useDetermineFrePending();
    const tenantId = getCurrentTenantId();
    // multi-workspaces
    const {data: workspaces} = useGetWorkspaces();
    const areWorkspacesRemoved = !workspaces || workspaces.value.length === 0;

    useEffect(() => {
        if (tenantId && clarity.hasStarted()) {
            clarity.setTag('TenantId', tenantId);
        }
    });

    if (isAuthenticated) {
        switch (authorized?.authorizedCode) {
            case '204':
                returnValue = <SuccessAuthResult>{children}</SuccessAuthResult>;
                break;
            case '401':
                returnValue = <Forbidden></Forbidden>;
                break;
            case '403':
                // If the tenant is not fidelis enabled, we show the forbidden message
                if (!isFidelisEnabled) {
                    returnValue = <Forbidden message={authorized.message}></Forbidden>;
                } else {
                    if (isFidelisEnabled) {
                        if (isFrePending) {
                            // If the user is pending FRE and is an admin, we don't want to show the forbidden message
                            // If the user is pending FRE and is not an admin, we want to show not setup message message
                            if (!userInfo?.isAdmin) {
                                returnValue = (
                                    <Forbidden message="SecurityCopilotNotSetup"></Forbidden>
                                );
                            }
                        } else {
                            // If the user is not pending FRE, we want to show the forbidden message
                            returnValue = <Forbidden message={authorized.message}></Forbidden>;
                        }
                    } else {
                        // If the tenant is not fidelis enabled, we show the forbidden message
                        returnValue = <Forbidden message={authorized.message}></Forbidden>;
                    }
                }
                break;
            default:
                if (isError) {
                    returnValue = <Forbidden message="AuthorizeFetchFailed"></Forbidden>;
                } else if (isSuccess) {
                    returnValue = (
                        <ErrorAuthResult>
                            Service return unhandle error code: {authorized?.authorizedCode}
                        </ErrorAuthResult>
                    );
                }
        }

        if (isFidelisEnabled) {
            if (isInvalidUser) {
                returnValue = <Forbidden message="SecurityCopilotNotSetup"></Forbidden>;
            } else if (isInvalidRegion) {
                returnValue = <Forbidden message="InvalidRegion"></Forbidden>;
            } else if (!!userInfo && !userInfo?.isAdmin && isAdminViewable) {
                return <Forbidden message="UnauthorizedPage"></Forbidden>;
            } else if (isWorkspacesTestingEnabled && !userInfo?.isAdmin && areWorkspacesRemoved) {
                return <Forbidden message="UnauthorizedPage"></Forbidden>;
            }
        }

        return (
            <ErrorBoundary key={location.pathname} FallbackComponent={ViewErrorBoundary}>
                {Boolean(announcementBanner) && (
                    <Alert
                        intent="warning"
                        appearance="primary"
                        action={{
                            icon: <DismissIcon />,
                            onClick: () => setAnnouncementBanner(false),
                        }}
                    >
                        {announcementBanner}
                    </Alert>
                )}
                <InfoMessageBar />
                {returnValue}
            </ErrorBoundary>
        );
    }
    return null;
}
