import {useUpdateWorkspacePolicyById} from '@/api/rbac';
import {PolicyWorkspace, RbacErrorResponse} from '@/api/rbac/rbac.types';
import {usePolicyManager} from '@/components/admin/rbac';
import {DismissIcon, PersonIcon} from '@/components/ui/icons';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    MenuItem,
    MenuList,
    MessageBarIntent,
    Spinner,
    mergeClasses,
    Text,
    Link,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RoleObjectIdData} from '../rbac.types';
import useClasses from './AddRoles.styles';
import AddRolesConfirmation from './AddRolesConfirmation';
import AddRolesErrorMessage from './AddRolesErrorMessage';
import {AddRolesMessages, useAddRolesErrorHanding} from './useAddRolesErrorHanding';
import {EVERYONE_IDENTIFIER} from '@/api/rbac/rbac.constants';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import React from 'react';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';

interface AddRolesInfoProps {
    isAddRolesOpen: boolean;
    existingMembers?: RoleObjectIdData;
    onAddRolesClose?: () => void;
    viewOnly?: boolean;
    workspacePolicy?: PolicyWorkspace;
}

export type ErrorMessageBarProps = {
    title: string;
    message: string;
    messageType: MessageBarIntent;
};

export default function AddRoles(props: AddRolesInfoProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [error, setError] = useState<AddRolesMessages>();
    const [selectedItem, setSelectedItem] = useState<number | null>(null);
    const enablePurviewDgRoles = useFeatureFlag(MedeinaFeatures.EnableDataGovernanceRoles);

    const entraRolesObj = t('RoleAssignment.AddRoles.RoleTypes.EntraRoles.Roles', {
        returnObjects: true,
    }) as {[key: string]: string};

    const entraRoles: string[] = Object.values(entraRolesObj);

    const purviewRiskRolesObj = t('RoleAssignment.AddRoles.RoleTypes.PurviewRoles.Roles', {
        returnObjects: true,
    }) as {[key: string]: string};

    const purviewRiskRoles: string[] = Object.values(purviewRiskRolesObj);

    const purviewDgRolesObj = t('RoleAssignment.AddRoles.RoleTypes.PurviewDgRoles.Roles', {
        returnObjects: true,
    }) as {[key: string]: string};

    const purviewDgRoles: string[] = Object.values(purviewDgRolesObj);

    const closeAddRolesDialog = () => {
        props?.onAddRolesClose?.();
        setSelectedItem(null);
    };

    let purviewRoles = purviewRiskRoles;

    if (enablePurviewDgRoles) {
        purviewRoles = purviewRiskRoles.concat(purviewDgRoles).sort();
    }

    const {mutate: trackEvent} = useTrackEvent();

    const [isEveryoneAdded, setIsEveryoneAdded] = useState<boolean>(false);

    useEffect(() => {
        setError(AddRolesMessages.NO_MESSAGE);
        generateErrorMessage(AddRolesMessages.NO_MESSAGE);
        setIsEveryoneAdded(true);

        const isEveryone = props.existingMembers?.Contributor?.User?.includes(EVERYONE_IDENTIFIER)
            ? true
            : false;
        setIsEveryoneAdded(isEveryone);
        if (isEveryone) {
            generateErrorMessage(AddRolesMessages.EMPTY_ERROR_MESAGE, {
                message: t('RoleAssignment.AddRoleMembers.CannotAddRecommendedRoles.Title'),
            });
        }
    }, [props.existingMembers, props.isAddRolesOpen]);

    const {mutateAsync: updateWorkspacePolicy, isLoading: updatePolicyLoading} =
        useUpdateWorkspacePolicyById();

    const {workspaceName} = useWorkspaceState();

    const policyReferenceName = props?.workspacePolicy?.properties?.entity?.referenceName;

    const {togglePartnerRolesCondition} = usePolicyManager({
        workspacePolicy: props?.workspacePolicy as PolicyWorkspace,
        workspaceName: policyReferenceName as string,
    });

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
        const accordionHeader = document.querySelector(`#role-section-Owner`);

        const button = accordionHeader?.querySelector('button');

        if (button) {
            if (button) {
                (button as HTMLButtonElement).focus();
                setTimeout(() => {}, 0);
            }
        }
    };

    const {errorMessage, generateErrorMessage} = useAddRolesErrorHanding(error as AddRolesMessages);

    const addRoles = async () => {
        const updatedPolicy = togglePartnerRolesCondition(undefined, undefined, true);

        if (!!updatedPolicy) {
            try {
                await updateWorkspacePolicy({
                    workspaceName: workspaceName as string,
                    policy: updatedPolicy,
                });
                closeAddRolesDialog();
                setConfirmDialogOpen(true);
            } catch (error) {
                const errorData = error as RbacErrorResponse;

                generateErrorMessage(AddRolesMessages.UPDATE_ERROR, {
                    message: errorData.Error.Message,
                });
            }
        }
    };

    const handleHeaderClick = (itemNumber: number) => {
        setSelectedItem(selectedItem === itemNumber ? null : itemNumber);
    };

    return (
        <>
            <Dialog open={props.isAddRolesOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>
                            <div className={classes.titleWrapper}>
                                <span className={classes.title}>
                                    {props.viewOnly
                                        ? t('RoleAssignment.AddRoles.ViewOnlyTitle')
                                        : t('RoleAssignment.AddRoles.Title')}
                                </span>
                                <Button
                                    data-test-id="close-add-roles-dialog-button"
                                    appearance="transparent"
                                    aria-label={t('RoleAssignment.AddRoles.AriaLabels.DialogClose')}
                                    className={classes.closeButton}
                                    onClick={closeAddRolesDialog}
                                    icon={<DismissIcon />}
                                />
                            </div>
                        </DialogTitle>
                        <DialogContent
                            className={mergeClasses(
                                classes.contentSection,
                                scrollClasses.colorNeutralBackground1,
                            )}
                        >
                            <div className={classes.dialogInfo}>
                                {t('RoleAssignment.AddRoles.Info')}{' '}
                                <Link
                                    data-test-id="privacy-and-data-security-link"
                                    href="https://learn.microsoft.com/copilot/security/authentication"
                                    target="_blank"
                                >
                                    {t('RoleAssignment.AddRoles.DocumentationLink')}
                                </Link>
                            </div>

                            <AddRolesErrorMessage error={errorMessage as ErrorMessageBarProps} />

                            <Accordion collapsible>
                                <AccordionItem
                                    onClick={() => handleHeaderClick(1)}
                                    value="1"
                                    className={mergeClasses(
                                        classes.accordianItem,
                                        selectedItem === 1 && classes.selectedPanel,
                                    )}
                                >
                                    <AccordionHeader>
                                        <div className={classes.rolesTypeContainer}>
                                            <Text className={classes.rolesTypeHeader}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.EntraRoles.Header',
                                                )}
                                            </Text>
                                            <Text className={classes.rolesTypeDescription}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.EntraRoles.DescriptionV2',
                                                )}
                                            </Text>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionPanel>
                                        <div className={classes.rolesList}>
                                            <MenuList>
                                                {entraRoles.map((role, index) => (
                                                    <MenuItem
                                                        className={mergeClasses(
                                                            classes.customMenuItemWidth,
                                                            classes.menuItemHover,
                                                        )}
                                                        key={index}
                                                    >
                                                        <div className={classes.personIcon}>
                                                            <PersonIcon />
                                                        </div>
                                                        <span className={classes.roleName}>
                                                            {role}
                                                        </span>
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem
                                    value="2"
                                    onClick={() => handleHeaderClick(2)}
                                    className={mergeClasses(
                                        classes.accordianItem,
                                        selectedItem === 2 && classes.selectedPanel,
                                    )}
                                >
                                    <AccordionHeader size="extra-large">
                                        <div className={classes.rolesTypeContainer}>
                                            <Text className={classes.rolesTypeHeader}>
                                                {' '}
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.PurviewRoles.Header',
                                                )}
                                            </Text>
                                            <Text className={classes.rolesTypeDescription}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.PurviewRoles.DescriptionV2',
                                                )}
                                            </Text>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionPanel>
                                        <div className={classes.rolesList}>
                                            <MenuList>
                                                {purviewRoles.map((role, index) => (
                                                    <MenuItem
                                                        className={mergeClasses(
                                                            classes.customMenuItemWidth,
                                                            classes.menuItemHover,
                                                        )}
                                                        key={index}
                                                    >
                                                        <div className={classes.personIcon}>
                                                            <PersonIcon />
                                                        </div>
                                                        <span className={classes.roleName}>
                                                            {role}
                                                        </span>
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem
                                    value="3"
                                    onClick={() => handleHeaderClick(3)}
                                    className={mergeClasses(
                                        classes.accordianItem,
                                        selectedItem === 3 && classes.selectedPanel,
                                    )}
                                >
                                    <AccordionHeader size="extra-large">
                                        <div className={classes.rolesTypeContainer}>
                                            <Text className={classes.rolesTypeHeader}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.UsxRoles.Header',
                                                )}
                                            </Text>
                                            <Text className={classes.rolesTypeDescription}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.UsxRoles.DescriptionV2',
                                                )}
                                            </Text>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionPanel>
                                        <div className={classes.rolesList}>
                                            {t(
                                                'RoleAssignment.AddRoles.RoleTypes.UsxRoles.Roles.CustomRolesLabel',
                                            )}
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            {!props.viewOnly ? (
                                <>
                                    <Button
                                        appearance="primary"
                                        onClick={() => {
                                            addRoles();
                                            trackEvent({
                                                name: MedeinaTelemetryEvent.Rbac
                                                    .AddRecommendedRoles,
                                                eventType: MedeinaEvent.ActionEvent,
                                            });
                                        }}
                                        disabled={updatePolicyLoading || isEveryoneAdded}
                                        aria-label={t(
                                            'RoleAssignment.AddRoles.AriaLabels.AddAllRolesButton',
                                        )}
                                    >
                                        {updatePolicyLoading ? (
                                            <Spinner size="tiny"></Spinner>
                                        ) : (
                                            commonLabel('ButtonLabels.Add')
                                        )}
                                    </Button>
                                    <Button onClick={closeAddRolesDialog}>
                                        {commonLabel('ButtonLabels.Cancel')}
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    appearance="primary"
                                    onClick={closeAddRolesDialog}
                                    disabled={updatePolicyLoading}
                                    aria-label={t('RoleAssignment.AddRoles.AriaLabels.DoneButton')}
                                >
                                    {t('RoleAssignment.AddRoles.DoneButton')}
                                </Button>
                            )}
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <AddRolesConfirmation
                isConfirmDialogOpen={isConfirmDialogOpen}
                onConfirmDialogClose={handleConfirmDialogClose}
            ></AddRolesConfirmation>
        </>
    );
}
