import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    label: {
        paddingBottom: tokens.spacingVerticalXXS,
    },
    fillerIcon: {
        width: '16px',
        display: 'inline-block',
    },
});
