import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    title: {
        fontSize: tokens.fontSizeBase500,
        fontWeight: 'bold',
        color: tokens.colorNeutralForeground1,
        ...shorthands.padding(tokens.spacingVerticalXXS, tokens.spacingHorizontalNone),
        marginTop: tokens.spacingVerticalXS,
        paddingBottom: tokens.spacingVerticalXXXL,
    },
    stickyActions: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        paddingBottom: tokens.spacingVerticalXL,
        ...shorthands.gap(tokens.spacingVerticalM),
    },
    toolbar: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingVerticalM),
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    filterSection: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        ...shorthands.gap(tokens.spacingVerticalM),
    },
    filterButton: {
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalM),
        fontSize: tokens.fontSizeBase300,
        backgroundColor: tokens.colorNeutralBackground2,
        ':hover': {
            ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
        },
        ':focus': {
            ...shorthands.border('2px', 'solid', tokens.colorNeutralBackground4),
            ...shorthands.outline('0px', 'solid', tokens.colorStrokeFocus2),
        },
    },
    filterChecked: {
        backgroundColor: tokens.colorNeutralForeground2,
        ':hover': {
            ...shorthands.border('0px', 'solid', tokens.colorNeutralStroke2),
            backgroundColor: tokens.colorNeutralBackgroundInverted,
            color: tokens.colorNeutralForegroundInverted,
        },
        ':focus': {
            ...shorthands.border('0px', 'solid', tokens.colorNeutralBackground4),
            ...shorthands.outline('0px', 'solid', tokens.colorStrokeFocus2),
        },
        color: tokens.colorNeutralForegroundInverted,
        fontWeight: tokens.fontWeightSemibold,
    },
    libraryContainer: {
        display: 'flex',
        maxHeight: 'calc(100vh - 330px)',
        minHeight: 'calc(100vh - 330px)',
        '@media (max-width: 479px)': {
            maxHeight: 'calc(100vh - 400px)',
            minHeight: 'calc(100vh - 400px)',
        },
        height: 'auto',
        width: '100%',
    },
    searchbar: {
        display: 'flex',
        ...shorthands.flex(1, 1, 'auto'),
        width: 'auto',
        marginRight: tokens.spacingHorizontalNone,
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXS),
    },
    btnSearchStart: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
        '@media (forced-colors: active)': {
            backgroundColor: tokens.colorNeutralBackground1,
        },
    },
    subButton: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        color: 'inherit',
        marginLeft: tokens.spacingHorizontalXS,
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            color: tokens.colorNeutralForeground2,
        },
    },
    scrollableFilterContainer: {
        maxHeight: '300px',
        overflowY: 'auto',
    },
    menuItemCheckbox: {
        '& .fui-MenuItem__checkmark': {
            color: tokens.colorNeutralForeground2,
        },
        fontSize: tokens.fontSizeBase300,
        ': hover': {
            backgroundColor: tokens.colorNeutralBackground6,
        },
    },
    menuButton: {
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalM),
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            backgroundColor: tokens.colorNeutralBackground2,
        },
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        maxWidth: '300px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...shorthands.overflow('hidden'),
        display: 'block',
    },
    customDivider: {
        ...shorthands.borderRadius('50%'),
        pointerEvents: 'none',
        height: '50%',
        alignSelf: 'center',
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,
        '::before': {
            ...shorthands.borderColor(tokens.colorNeutralForeground4),
        },
        '::after': {
            ...shorthands.borderColor(tokens.colorNeutralForeground4),
        },
    },
});
