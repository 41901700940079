import React, {useEffect, useState, useMemo} from 'react';
import WorkspacesContext from './WorkspacesContext';
import useGetWorkspaces from '@/api/workspaces/useGetWorkspaces';
import {writeToSessionStorage} from '@/util/sessionStorage.util';
import {WORKSPACE_NAMESPACE} from './workspaces.constants';
import {WorkspaceSessionData, isGeoPodEnabled} from './';
import {useGetWorkspaceByName} from '@/api/workspaces';
import {useGetAccount} from '@/api/accounts';

/** Manages rendering the WorkspacesContextProvider if enabled
 * @returns the WorkspacesProvider component or children
 *
 * WIP - Only available in dev with feature flag
 */
const WorkspacesProvider = ({children}: {children: React.ReactNode}) => {
    // ECS based flag returned from userInfo is populated the UserContext
    // isGeoPodEnabled will check the session storage value from UserContext
    const geoPodEnabled = useMemo(() => Boolean(isGeoPodEnabled()), []);
    const [workspaceName, setWorkspaceName] = useState('');

    // Right now we get all workspaces
    const {data: workspaces, refetch} = useGetWorkspaces({enabled: Boolean(geoPodEnabled)});
    // Click stop 2 should include the call to get /workspacesettings
    // WorkspacesSettings will give us the currentWorkspace last selected by the user
    const {data: workspace, isLoading} = useGetWorkspaceByName(workspaceName, {
        enabled: !!workspaceName,
    });

    const {data: account} = useGetAccount();

    useEffect(() => {
        if (geoPodEnabled && workspaces?.value?.length) {
            // When geopods are enabled we need to update how we route microsoft security graph requests
            // First we store the geo pod workspace in session storage
            // right now there's only one workspace so pull the first one
            const geoPodWorkspace = workspaces.value[0];
            setWorkspaceName(geoPodWorkspace.name);
            // We want to keep the minimum amount of data for this
            const workspaceData = {
                name: geoPodWorkspace.name,
                path: geoPodWorkspace.path,
                managementUrl: geoPodWorkspace.managementUrl,
                // we want to capture the second part of the path of  pods/82e853fb-c82c-4d62-9812-439bf8a887c3/workspaces/default,
                podId: geoPodWorkspace.path?.split('/')[1],
            };
            writeToSessionStorage<WorkspaceSessionData>(WORKSPACE_NAMESPACE, workspaceData);
        }
    }, [geoPodEnabled, workspaces]);

    useEffect(() => {
        if (workspaceName && !workspace && !isLoading) {
            refetch();
        }
    }, [workspaceName, workspace, isLoading, refetch]);

    useEffect(() => {
        // TODO: when we have the ability to switch workspaces
        // we will want to clear out the workspace from session storage when we are switching workspaces
        // this will force the WorkspacesProvider to re-fetch the currentWorkspace and update the cache
        // return () => {
        //     sessionStorage.removeItem(WORKSPACE_NAMESPACE);
        // };
    }, []);

    // Original flow for GA
    // When a user does not have the feature flag enabled it means they are a new customer and need the FRE flow
    if (!geoPodEnabled) {
        return <>{children}</>;
    }

    // if we have the workspace we can render the context provider so that workspace state provider
    // it will check that the workspace is correctly setup
    return <WorkspacesContext>{children}</WorkspacesContext>;
};

export default WorkspacesProvider;
