import React, {useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    MenuDivider,
    Button,
    Text,
} from '@fluentui/react-components';
import {useGetSession, useIsUserSessionOwner} from '@/api/sessions';
import MedeinaFeatures from '@/util/features';
import DuplicateSessionDialog from '../sessions/DuplicateSessionDialog';
import EditInvestigationNameDialog from './EditInvestigationNameDialog';
import {DuplicateIcon, EditIcon, DownIcon, AddIcon} from '@/components/ui/icons';
import {Session} from '@/api/sessions/sessions.types';
import useClasses from './SessionOptionsBreadcrumb.styles';
import {useTranslation} from 'react-i18next';
import {useViewportSize} from '@/components/ui/Grid';

interface InvestigationBreadcrumbProps {
    sessionId: string;
}

const MAX_CRUMB_NAME_LENGTH = 80;
const SM_MAX_CRUMB_NAME_LENGTH = 20;

function ExistingSession(props: InvestigationBreadcrumbProps) {
    const classes = useClasses();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {sm, md} = useViewportSize();
    const [openEditNameDialog, setOpenEditNameDialog] = useState(false);
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);

    const handleOpenEditNameDialog = () => {
        setOpenEditNameDialog(true);
    };

    const handleCloseEditNameDialog = () => {
        setOpenEditNameDialog(false);
    };
    const {data, isSuccess} = useGetSession({sessionId: props.sessionId});
    const {isUserSessionOwner} = useIsUserSessionOwner(props.sessionId);

    const useSmallCrumb = sm || md;
    const sessionNameLimit = useSmallCrumb ? SM_MAX_CRUMB_NAME_LENGTH : MAX_CRUMB_NAME_LENGTH;
    const sessionName = data?.name || '';

    // TODO: Temporary fix, handle this with flexbox styling.
    const name = useMemo(() => {
        return sessionName && sessionName.length > sessionNameLimit
            ? `${sessionName.substring(0, sessionNameLimit - 3)}...`
            : sessionName;
    }, [sessionName, sessionNameLimit]);

    return (
        <>
            <Menu positioning="below-end">
                <MenuTrigger disableButtonEnhancement>
                    <Button
                        appearance="subtle"
                        size="medium"
                        icon={<DownIcon />}
                        iconPosition="after"
                        className={classes.root}
                        data-testid="session-options-breadcrumb"
                    >
                        {name || t('BreadcrumbSessionButtonDefault') + props.sessionId}
                    </Button>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList role="menu">
                        <MenuItem
                            icon={<EditIcon />}
                            onClick={handleOpenEditNameDialog}
                            role="menuitem"
                            disabled={!isUserSessionOwner}
                        >
                            {t('BreadcrumbSessionEditName')}
                        </MenuItem>
                        {MedeinaFeatures.DuplicateSessionExperience && (
                            <MenuItem
                                data-testid="duplicate-button"
                                role="menuitem"
                                icon={<DuplicateIcon />}
                                onClick={(e: {
                                    preventDefault: () => void;
                                    stopPropagation: () => void;
                                }) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenDuplicateDialog(true);
                                }}
                            >
                                {t('BreadcrumbSessionDuplicate')}
                            </MenuItem>
                        )}
                        <MenuDivider />
                        <MenuItem
                            data-testid="new session"
                            icon={<AddIcon />}
                            onClick={() =>
                                MedeinaFeatures.NewSessionPage
                                    ? navigate('/sessions/new')
                                    : navigate('/')
                            }
                        >
                            {t('BreadcrumbSessionNewSession')}
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
            {isSuccess && (
                <>
                    <DuplicateSessionDialog
                        session={data as Session}
                        open={openDuplicateDialog}
                        onClose={() => {
                            setOpenDuplicateDialog(false);
                        }}
                        onSuccess={() => {
                            setOpenDuplicateDialog(false);
                        }}
                    />
                    <EditInvestigationNameDialog
                        open={openEditNameDialog}
                        onClose={handleCloseEditNameDialog}
                        onSuccess={handleCloseEditNameDialog}
                        session={data as Session}
                    />
                </>
            )}
        </>
    );
}

// Custom breadcrumb component for the sessionID segment of the breadcrumb
// TODO: lookup session information to retrieve the actual Summarized Title
function Breadcrumb(props: InvestigationBreadcrumbProps) {
    const classes = useClasses();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const isNewSession = props.sessionId === 'new';

    function NewSession() {
        return <Text className={classes.newSession}>{t('BreadcrumbSessionUntitledSession')}</Text>;
    }

    return (
        <>
            {MedeinaFeatures.NewSessionPage && isNewSession ? (
                <NewSession />
            ) : (
                <ExistingSession {...props} />
            )}
        </>
    );
}

export default Breadcrumb;
